import React, { Component } from 'react';
import { AddComboProps, AddComboState } from '../../../interfaces/menu'
import { connect } from 'react-redux'
import Footer from '../../../components/footer/main';
import Sidebar from '../../../components/sidebar';
import Topbar from '../../../components/topbar';
import jwt from 'jsonwebtoken'
import { secretKey } from '../../../secret'
import { addCombo, channelList, logoutUser, groupedItemsList, menusList, groupsList, storesListForMultiSelect } from '../../../redux'
import Select from "react-select";
import { Link, Redirect } from 'react-router-dom';
import CheckChanges from '../../../components/confirmOnLeave'
import moment from 'moment';
import { comboChoiceItemsByGroupId, combosList } from '../../../redux/actions/menuAction';
import { brandsList } from '../../../redux/actions/storeAction';
class AddCombo extends Component<AddComboProps, AddComboState> {
    orderModes: ({ value: string; label: string })[];
    constructor(props: any) {
        super(props);
        this.orderModes = [
            { value: 'online', label: 'Online' },
            { value: 'mobile', label: 'Mobile' },
            { value: 'callcenter', label: 'Call Center' }
        ];
        this.state = {
            menu: "",
            group: "",
            state: "",
            comboname: "",
            erpid: null,
            poscode: null,
            combodesc: "",
            channel: "",
            priority: "",
            combocost: "",
            combosale: "",
            combomrp: "",
            images: null,
            hero_image: null,
            taxstatus: false,
            settime: 'alltime',
            combostart: "",
            comboclose: "",
            errtaxdef: false,
            tax_percent: "",
            hero_item: false,
            topDeal: false,
            is_lsm: '0',
            mode: [],
            storesJson: [],
            comboUnique: "",
            metaTitle: "",
            metaDesc: "",
            altTag: "",
            brand_id:null,
            combojson: [
                {
                    id: "",
                    itemName: "",
                    poscode: "",
                    choice_group_name: "",
                    groupname: "",
                    groupposcode: "",
                    size: "",
                    quantity: "",
                    limit: "",
                    itemsize: []
                }
            ],
            comboChoices: [
                {
                    group_id: "",
                    group_erp_id: "",
                    group_name: "",
                    menu_item_id: "",
                    choicesItemOptions: [],
                    size: "",
                    itemsize: []
                }
            ],
        }
        this.handleSaveBtnClick = this.handleSaveBtnClick.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleItemsByMenuId = this.handleItemsByMenuId.bind(this);
    }
    componentDidMount() {
        // this.props.combosList();
        // this.props.groupsList();
        // this.props.menusList();
        this.props.brandsList();
        this.props.channelList();
        // this.props.storesList();
        document.title = "SimplexCMS | Combos"
    }
    UNSAFE_componentWillReceiveProps(nextProps: any) {
        const list: any[] = this.state.comboChoices;
        if (nextProps.choiceIndex !== "" && nextProps.comboChoiceItems.length > 0) {
            let index = nextProps.choiceIndex;
            list[index]["choicesItemOptions"] = nextProps.comboChoiceItems;
            this.setState({ comboChoices: list });
        }
    }
    handleInputChange(event: { target: { name: any; value: any; }; }) {
        if (event.target.value == ' ') {
            event.target.value = event.target.value.replace(/\s/g, "");
        }
        this.setState({
            [event.target.name]: event.target.value,
        });
    }
    // handle Remove event of the Combo Ingredient button
    handleRemoveClick = (index: any) => {
        const list = this.state.combojson;
        list.splice(index, 1);
        this.setState({ combojson: list });
    };
    // handle add event of the Combo Ingredient button
    handleAddClick = (i: any) => {
        const list: any = this.state.combojson;
        if (list[i]["choice_group_name"] == "" || list[i]["itemName"] == "" || list[i]["size"] == "" || list[i]["quantity"] == "" || list[i]["limit"] == "") {
            alert("Please fill in current item")
        } else if (i == '9') {
            alert("Sorry you can select only 10 items")
        } else {
            const newlist: any = { id: "", itemName: "", poscode: "", choice_group_name: "", size: "", quantity: "", limit: "", itemsize: [] }
            const combo: any = this.state.combojson.concat(newlist)
            this.setState({ combojson: combo });
        }
    };
    fileSelectedHandler = (e: any) => {
        if (e.target.files.length > 0) {
            this.setState({ images: e.target.files })
        }
    }
    handleHeroItem = (event: { target: { name: any; value: any; }; }) => {
        const { hero_item } = this.state;
        this.setState({ hero_item: !hero_item })
    }
    handleTopDeal = (event: { target: { name: any; value: any; }; }) => {
        const { topDeal } = this.state;
        const { combos } = this.props;
        let topDealsCount: any = 0;
        combos.forEach((element: any) => {
            if (element.topDeal == "1") {
                topDealsCount += 1;
            }
        });
        if (topDealsCount == 4) {
            if (topDeal == false) {
                alert("You have already 4 Top Deals!")
            } else {
                this.setState({ topDeal: !topDeal })
            }
        } else {
            this.setState({ topDeal: !topDeal })
        }
    }
    heroItemImageSelectedHandler = (e: any) => {
        if (e.target.files.length > 0) {
            this.setState({ hero_image: e.target.files })
        }
    }
    handleItemsInputChange = (e: any, index: any) => {
        if (e.target) {
            const { name, value } = e.target;
            const list: any = this.state.combojson;
            list[index][name] = value;
            this.setState({ combojson: list });
        } else {
            const list: any = this.state.combojson;
            list[index]["id"] = e.id;
            list[index]["itemName"] = e.value;
            list[index]["poscode"] = e.pos_code;
            list[index]["groupname"] = e.group_name;
            list[index]["groupposcode"] = e.group_poscode;
            list[index]["quantity"] = 1;
            list[index]["limit"] = "0";
            list[index]["itemsize"] = JSON.parse(e.item_size);
            this.setState({ combojson: list });
        }
    };
    handleItemsByMenuId(event: { target: { name: any; value: any; }; }) {
        let select: any = document.getElementById('menu');
        if (this.state.menu !== "") {
            if (window.confirm("You cannot change the menu. If you clicks OK, then menu items corresponding to present combo will lost.")) {
                this.setState({
                    combojson: [
                        {
                            id: "",
                            itemName: "",
                            poscode: "",
                            choice_group_name: "",
                            groupname: "",
                            groupposcode: "",
                            size: "",
                            quantity: "",
                            limit: "",
                            itemsize: []
                        }
                    ],
                    menu: event.target.value,
                    combocost: "",
                    combomrp: "",
                })
                this.props.groupedItemsList(event.target.value);
            } else {
                select.value = this.state.menu
            }
        } else {
            this.setState({
                "menu": event.target.value,
                // errtaxdef: false,
                // state: stateData ? `${stateData.state_name} , ${stateData.country_name}` : "",
                // tax_percent: stateData ? stateData.tax_percent : ""
            });
            this.props.groupedItemsList(event.target.value);
        }
    }
    handleChangeRad = (event: { target: { name: any; value: any; } }) => {
        this.setState({ [event.target.name]: event.target.value })
    }
    isComboReady = () => {
        const { comboname, menu, brand_id, combocost, group, comboUnique, mode, hero_item, images, hero_image, combomrp, settime, combostart, comboclose, is_lsm, storesJson } = this.state
        if (settime == 'customtime') {
            if (hero_item == false) {
                if (is_lsm == "0") {
                    return (comboname !== "" && brand_id !==null && brand_id !=="" && comboUnique !== "" && menu !== "" && images !== null && group !== "" && combocost !== "" && combomrp !== "" && combostart !== "" && comboclose !== "" && mode.length > 0);
                } else if (is_lsm == "1") {
                    return (comboname !== "" && brand_id !==null && brand_id !=="" && comboUnique !== "" && menu !== "" && images !== null && group !== "" && combocost !== "" && combomrp !== "" && combostart !== "" && comboclose !== "" && storesJson.length > 0 && mode.length > 0);
                }
            } else {
                if (is_lsm == "0") {
                    return (comboname !== "" && brand_id !==null && brand_id !=="" && comboUnique !== "" && menu !== "" && images !== null && hero_image !== null && group !== "" && combocost !== "" && combomrp !== "" && combostart !== "" && comboclose !== "" && mode.length > 0);
                } else if (is_lsm == "1") {
                    return (comboname !== "" && brand_id !==null && brand_id !=="" && comboUnique !== "" && menu !== "" && images !== null && hero_image !== null && group !== "" && combocost !== "" && combomrp !== "" && combostart !== "" && comboclose !== "" && storesJson.length > 0 && mode.length > 0);
                }
            }
        } else {
            if (hero_item == false) {
                if (is_lsm == "0") {
                    return (comboname !== "" && brand_id !==null && brand_id !=="" && comboUnique !== "" && menu !== "" && images !== null && group !== "" && combocost !== "" && combomrp !== "" && mode.length > 0);
                } else if (is_lsm == "1") {
                    return (comboname !== "" && brand_id !==null && brand_id !=="" && comboUnique !== "" && menu !== "" && images !== null && group !== "" && combocost !== "" && combomrp !== "" && storesJson.length > 0 && mode.length > 0);
                }
            } else {
                if (is_lsm == "0") {
                    return (comboname !== "" && brand_id !==null && brand_id !=="" && comboUnique !== "" && menu !== "" && images !== null && hero_image !== null && group !== "" && combocost !== "" && combomrp !== "" && mode.length > 0);
                } else if (is_lsm == "1") {
                    return (comboname !== "" && brand_id !==null && brand_id !=="" && comboUnique !== "" && menu !== "" && images !== null && hero_image !== null && group !== "" && combocost !== "" && combomrp !== "" && storesJson.length > 0 && mode.length > 0);
                }
            }
        }
    }
    blockInvalidChar = (e: any) =>
        ["e", "E", "+", "-"].includes(e.key) && e.preventDefault();

    handleOrderModesInputChange = (e: any, index: any) => {
        if (e && e.length > 0) {
            this.setState({ mode: e });
        } else {
            this.setState({ mode: [] });
        }
    };
    handleStoresInputChange = (e: any, index: any) => {
        if (e && e.length > 0) {
            this.setState({ storesJson: e });
        } else {
            this.setState({ storesJson: [] });
        }
    };
    handleComboChoiceInputChange = (e: any, index: any) => {
        if (e.target) {
            const { name, value } = e.target;
            if (name == "group_id") {
                this.props.comboChoiceItemsByGroupId(value, index)
            }
            const list: any = this.state.comboChoices;
            // if (name == "size") {
            //     let size = list[index]["itemsize"].find((obj: any) => {
            //         return value == obj.id;
            //     })
            //     if (size) {
            //         list[index][name] = JSON.stringify(size);
            //     } else {
            //         list[index][name] = "";
            //     }
            // } else {
            //     list[index][name] = value;
            // }
            list[index][name] = value;
            this.setState({ comboChoices: list });
        } else {
            const list: any = this.state.comboChoices;
            list[index]["menu_item_id"] = e.value;
            list[index]["itemsize"] = JSON.parse(e.item_size);
            list[index]["size"] = "";
            this.setState({ comboChoices: list });
        }
    };
    handleAddComboChoice = (i: any) => {
        const list: any = this.state.comboChoices;
        if (list[i]["group_erp_id"] == "" || list[i]["group_id"] == "" || list[i]["group_name"] == "" || list[i]["menu_item_id"] == "" || list[i]["size"] == "") {
            alert("Please fill in current item")
        } else {
            const newlist: any = {
                group_id: "",
                group_erp_id: "",
                group_name: "",
                menu_item_id: "",
                choicesItemOptions: [],
                size: "",
                itemsize: []
            }
            const comboChoice: any = this.state.comboChoices.concat(newlist)
            this.setState({ comboChoices: comboChoice });
        }
    };
    handleRemoveComboChoice = (index: any) => {
        const list = this.state.comboChoices;
        list.splice(index, 1);
        this.setState({ comboChoices: list });
    };
    handleSaveBtnClick = (event: any) => {
        let { comboname, erpid, poscode, brand_id, combodesc, priority, comboUnique, mode, channel, combocost, topDeal, hero_item, hero_image, combomrp, group, settime, combostart, comboclose, combojson, comboChoices, menu, is_lsm, storesJson, metaTitle, metaDesc, altTag } = this.state;
        const data: any = new FormData()
        if (this.state.images) {
            for (var x = 0; x < this.state.images.length; x++) {
                data.append('files', this.state.images[x])
            }
        }
        if (hero_image) {
            var file = hero_image[0];
            var newFileName = file.name.split(".")[0] + "_hero_image." + file.name.split(".")[1];
            data.append('files', file, newFileName);
        }
        data.append('combo_name', comboname)
        if (erpid !== null) {
            data.append('erp_id', erpid)
        }
        if (poscode !== null) {
            data.append('pos_code', poscode)
        }
        if (hero_item === true) {
            data.append('hero_item', 1)
        }
        if (topDeal === true) {
            data.append('topDeal', 1)
        }
        if (is_lsm == '0') {
            data.append('is_lsm', 0)
        } else if (is_lsm == '1') {
            data.append('is_lsm', 1)
            data.append('stores_json', JSON.stringify(storesJson))
        }
        data.append('combo_description', combodesc)
        data.append('combo_channel_id', channel)
        data.append('combo_cost_price', combocost)
        data.append('comboUnique', comboUnique)
        data.append('item_mode', JSON.stringify(mode))
        data.append('priority', priority)
        data.append('brand_id',brand_id)
        data.append('meta_title', metaTitle)
        data.append('meta_description', metaDesc)
        data.append('alt_text', altTag)
        // data.append('combo_sales_price', combosale)
        // data.append('combo_tax_configuration', taxstatus == false ? '0' : '1')
        data.append('combo_mrp_price', combomrp)
        data.append('menu_id', menu)
        data.append('group_id', group)
        if (settime === 'customtime') {
            data.append('combo_start_time', moment(combostart).utc(false))
            data.append('combo_close_time', moment(comboclose).utc(false))
        }
        // for (var i = 0, len = combojson.length; i < len; i++) {
        //     delete combojson[i].itemsize;   //delete is used to remove itemSize Array from combo_ingredients json 
        // }   
        //But this is not used because itemSize array is required to display mutiple items in Edit section

        // if (!combojson[1]) {
        //     alert("Please select at least 2 items")
        // } else
        if (combojson[combojson.length - 1]["choice_group_name"] !== "" && combojson[combojson.length - 1]["itemName"] !== "" && combojson[combojson.length - 1]["size"] !== "" && combojson[combojson.length - 1]["quantity"] !== "" && combojson[combojson.length - 1]["limit"] !== "") {
            data.append('combo_ingredients', JSON.stringify(combojson))
            data.append('combo_choices', JSON.stringify(comboChoices))
            this.props.addCombo(data);
        } else {
            alert("Please fill in all fields of combo items")
        }
    }
    handleBrand = (e: any, index: any) => {
        if (e && e.value > 0) {
            this.setState({ topDeal: false, brand_id:e.value })
            this.props.groupsList(e.value);
            // this.props.combosList(e.value);
            this.props.storesList(e.value);
            this.props.menusList(e.value);
        }
    }
    render() {
        if (sessionStorage.token) {
            jwt.verify(sessionStorage.token, secretKey, (err: any, decoded: any) => {
                if (err) {
                    this.props.logoutUser();
                }
            });
        } else {
            return <Redirect to="/" />
        }
        let msg;
        let messcolor;
        const { isInserted, message, menus, channels, combooptions, groups } = this.props;
        const { combojson, comboChoices, hero_item, topDeal, settime, combomrp, combocost, is_lsm, storesJson } = this.state
        if (!isInserted) {
            msg = message;
            messcolor = 'red'
        }
        const groupedOptions: any = [];
        let obj: any = ""
        for (const property in combooptions) {
            obj = {
                label: property,
                options: combooptions[property]
            }
            groupedOptions.push(obj)
        }
        let groupsByMenuID: any[] = groups.filter((obj: any) => {
            return obj.menu_id == this.state.menu;
        })
        const formatGroupLabel = (data: any) => (
            <div className="d-flex justify-content-between">
                <span>{data.label}</span>
                <span className="badge badge-secondary p-1">{data.options.length}</span>
            </div>
        );
        let tokendata: any = jwt.decode(sessionStorage.token)
        let roleId = tokendata.role_id;
        let brandId = tokendata.brand_id;
        return (
            <div className="page">
                <CheckChanges path="/add-combo" />
                <Topbar />
                <div className="page-content d-flex align-items-stretch">
                    <Sidebar />
                    <div className="content-inner">
                        {/*  Page Header */}
                        <header className="page-header py-0">
                            <div className="container-fluid">
                                <div className="d-flex justify-content-between py-3">
                                    <h4 className="mt-2">Combo Management</h4>
                                </div>
                            </div>
                        </header>
                        {/* Breadcrumb */}
                        <div className="breadcrumb-holder container-fluid">
                            <ul className="breadcrumb">
                                <li className="breadcrumb-item"><Link to="/combos" className="text-primary">Combos</Link></li>
                                <li className="breadcrumb-item active">Add Combo</li>
                            </ul>
                        </div>
                        <section className="forms">
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="card">
                                            <div className="card-body">
                                                <strong><p className="text-center font-weight-light mb-4" style={{ color: messcolor }}>
                                                    {msg}
                                                </p></strong>
                                                <div className="row">
                                                    <div className="col-lg-6 col-12">
                                                        <div className="form-group">
                                                            <label className="form-control-label">Combo Name <span className="text-danger">*</span></label>
                                                            <input id="comboname" type="text" name="comboname" required data-msg="Please enter Combo Name" className="input-material" onChange={this.handleInputChange} />
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6 col-12">
                                                        <div className="form-group">
                                                            <label className="form-control-label">Brand <span className="text-danger">*</span></label>
                                                            <Select
                                                                name="brand"
                                                                isSearchable
                                                                options={roleId == 7 ? this.props.brands : this.props.brands.filter(o1 => brandId === o1.brand_id)}
                                                                className="text-capitalize select mt-2"
                                                                classNamePrefix="select"
                                                                onChange={(e, i) => this.handleBrand(e, i)} />
                                                        </div>
                                                    </div>
                                                    
                                                </div>
                                                <div className='row'>
                                                <div className="col-lg-6 col-6">
                                                        <div className="form-group">
                                                            <label className="form-control-label">ERP Id</label>
                                                            <input id="erpid" type="text" name="erpid" className="input-material" onChange={this.handleInputChange} />
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6 col-6">
                                                        <div className="form-group">
                                                            <label className="form-control-label">POS Code</label>
                                                            <input id="poscode" type="text" name="poscode" className="input-material" onChange={this.handleInputChange} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-6">
                                                        <div className="form-group">
                                                            <label className="form-control-label">Meta title </label>
                                                            <input id="metaTitle" type="text" name="metaTitle" required data-msg="Please enter Item Title" className="input-material" onChange={this.handleInputChange} />
                                                        </div>
                                                    </div>
                                                    <div className="col-6">
                                                        <div className="form-group">
                                                            <label className="form-control-label">Meta description</label>
                                                            <input id="metaDesc" type="text" name="metaDesc" required data-msg="Please enter Item Description" className="input-material" onChange={this.handleInputChange} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-lg-4 col-12">
                                                        <div className="form-group">
                                                            <label className="form-control-label">Combo Unique Id <span className="text-danger">*</span></label>
                                                            <input id="comboUnique" type="text" name="comboUnique" className="input-material" onChange={this.handleInputChange} />
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-4 col-6">
                                                        <div className="form-group">
                                                            <label className="form-control-label">Menu <span className="text-danger">*</span></label>
                                                            <select id="menu" name="menu" className="form-control text-capitalize mt-2" required data-msg="Please select Menu" onChange={this.handleItemsByMenuId}>
                                                                <option value="">Select Menu</option>
                                                                {menus &&
                                                                    menus.map((menu, index) => (
                                                                        <option key={index} value={menu.menu_id}>{menu.brand_name} - {menu.store_type_name}</option>
                                                                    ))
                                                                }
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-4 col-6">
                                                        <div className="form-group">
                                                            <label className="form-control-label">Group <span className="text-danger">*</span></label>
                                                            <select name="group" className="form-control text-capitalize mt-2" required data-msg="Please select Group" onChange={this.handleInputChange}>
                                                                <option value="">Select Group</option>
                                                                {groupsByMenuID &&
                                                                    groupsByMenuID.map((group, index) => (
                                                                        group &&
                                                                        <option key={index} value={group.group_id}>{group.group_name}</option>
                                                                    ))
                                                                }
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col">
                                                        <h4 className="py-2">Default Items For Combo</h4>
                                                        <div className="form-group">
                                                            {combojson.map((x: any, i: any) => {
                                                                return (
                                                                    <div key={i} className="row">
                                                                        <div className="col-lg-2 col-md-4 col-6">
                                                                            <div className="form-group">
                                                                                <label className="form-control-label">Group Name <span className="text-danger">*</span> <i title="Group name in which default item exist" id={i} className="fa fa-question-circle-o"></i></label>
                                                                                <input
                                                                                    name="choice_group_name"
                                                                                    type="text"
                                                                                    value={x.choice_group_name}
                                                                                    data-msg="Please enter group name"
                                                                                    className="input-material"
                                                                                    onChange={e => this.handleItemsInputChange(e, i)}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-lg-3 col-md-4 col-6">
                                                                            <div className="form-group">
                                                                                <label className="form-control-label">Menu Item {i + 1}  <span className="text-danger">*</span></label>
                                                                                <Select
                                                                                    className="basic-multi-select text-capitalize py-2"
                                                                                    options={groupedOptions}
                                                                                    formatGroupLabel={formatGroupLabel}
                                                                                    onChange={e => this.handleItemsInputChange(e, i)} />
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-lg-3 col-md-4 col-6">
                                                                            <div className="form-group">
                                                                                <label className="form-control-label">Size <span className="text-danger">*</span></label>
                                                                                <select name="size" className="form-control text-capitalize mt-2" required data-msg="Please select Channel" onChange={e => this.handleItemsInputChange(e, i)}>
                                                                                    <option value="">Select Size</option>
                                                                                    {x.itemsize.length > 0 &&
                                                                                        x.itemsize.map((item: any, index: any) => (
                                                                                            <option key={index} value={item.size}>{item.size}</option>
                                                                                        ))
                                                                                    }
                                                                                </select>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-lg-2 col-md-4 col-6">
                                                                            <div className="form-group">
                                                                                <label className="form-control-label">Quantity <span className="text-danger">*</span></label>
                                                                                <input
                                                                                    name="quantity"
                                                                                    type="number"
                                                                                    value={x.quantity}
                                                                                    min="1"
                                                                                    onKeyDown={this.blockInvalidChar}
                                                                                    data-msg="Please enter qunatity"
                                                                                    className="input-material"
                                                                                    onChange={e => this.handleItemsInputChange(e, i)}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-lg-2 col-md-4 col-6">
                                                                            <div className="form-group">
                                                                                <label className="form-control-label">Limit <span className="text-danger">*</span></label>
                                                                                <input
                                                                                    name="limit"
                                                                                    type="number"
                                                                                    defaultValue={x.limit}
                                                                                    min="1"
                                                                                    onKeyDown={this.blockInvalidChar}
                                                                                    data-msg="Please enter limit"
                                                                                    className="input-material"
                                                                                    onChange={e => this.handleItemsInputChange(e, i)}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-lg-12 col-md-4 col-6 text-right">
                                                                            {combojson.length !== 1 &&
                                                                                <button className="btn btn-sm btn-outline-danger"
                                                                                    onClick={() => this.handleRemoveClick(i)}><i className="fa fa-trash"></i></button>}
                                                                            {combojson.length - 1 === i && <button className="btn btn-sm btn-primary ml-2" onClick={() => this.handleAddClick(i)}><i className="fa fa-plus"></i></button>}
                                                                        </div>
                                                                    </div>
                                                                );
                                                            })}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col">
                                                        <h4>Select Items For Combo Options</h4>
                                                        <div className="form-group">
                                                            {comboChoices.map((x: any, i: any) => {
                                                                return (
                                                                    <div key={i} className="row">
                                                                        <div className="col-12 col-6">
                                                                            <div className="form-group">
                                                                                <label className="form-control-label">Group ERP ID <span className="text-danger">*</span></label>
                                                                                <input
                                                                                    name="group_erp_id"
                                                                                    type="number"
                                                                                    value={x.group_erp_id}
                                                                                    min="1"
                                                                                    onKeyDown={this.blockInvalidChar}
                                                                                    data-msg="Please enter group_erp_id"
                                                                                    className="input-material"
                                                                                    onChange={e => this.handleComboChoiceInputChange(e, i)}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-lg-3 col-6">
                                                                            <div className="form-group">
                                                                                <label className="form-control-label">Groups <span className="text-danger">*</span></label>
                                                                                <select name="group_id" className="form-control text-capitalize mt-2" required data-msg="Please select Group" onChange={e => this.handleComboChoiceInputChange(e, i)}>
                                                                                    <option value="">Select Group</option>
                                                                                    {groups &&
                                                                                        groups.map((group, index) => (
                                                                                            <option key={index} value={group.group_id}>{group.group_name}</option>
                                                                                        ))
                                                                                    }
                                                                                </select>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-lg-3 col-6">
                                                                            <div className="form-group">
                                                                                <label className="form-control-label">Group Name <span className="text-danger">*</span></label>
                                                                                <input
                                                                                    name="group_name"
                                                                                    type="text"
                                                                                    value={x.group_name}
                                                                                    data-msg="Please enter group name"
                                                                                    className="input-material"
                                                                                    onChange={e => this.handleComboChoiceInputChange(e, i)}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-lg-3 col-6">
                                                                            <div className="form-group">
                                                                                <label className="form-control-label">Menu Items <span className="text-danger">*</span></label>
                                                                                <Select
                                                                                    className="basic-single text-capitalize py-2"
                                                                                    options={x.choicesItemOptions}
                                                                                    onChange={e => this.handleComboChoiceInputChange(e, i)} />
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-lg-3 col-6">
                                                                            <div className="form-group">
                                                                                <label className="form-control-label">Size <span className="text-danger">*</span></label>
                                                                                <select name="size" className="form-control text-capitalize mt-2" required data-msg="Please select Size" onChange={e => this.handleComboChoiceInputChange(e, i)}>
                                                                                    <option value="">Select Size</option>
                                                                                    {x.itemsize.length > 0 &&
                                                                                        x.itemsize.map((item: any, index: any) => (
                                                                                            <option key={index} value={item.id}>{item.size}</option>
                                                                                        ))
                                                                                    }
                                                                                </select>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-12 text-right">
                                                                            {comboChoices.length !== 1 &&
                                                                                <button className="btn btn-sm btn-outline-danger"
                                                                                    onClick={() => this.handleRemoveComboChoice(i)}><i className="fa fa-trash"></i></button>}
                                                                            {comboChoices.length - 1 === i && <button className="btn btn-sm btn-primary ml-2" onClick={() => this.handleAddComboChoice(i)}><i className="fa fa-plus"></i></button>}
                                                                        </div>
                                                                    </div>
                                                                );
                                                            })}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-md-8">
                                                        <div className="form-group">
                                                            <label className="form-control-label">Description</label>
                                                            <input id="combodesc" type="text" name="combodesc" className="input-material" onChange={this.handleInputChange} />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-4" >
                                                        <div className="form-group">
                                                            <label className="form-control-label">Ordering Mode <span className="text-danger">*</span></label>
                                                            <Select
                                                                isMulti
                                                                name="mode"
                                                                options={this.orderModes}
                                                                className="text-capitalize basic-multi-select mt-2"
                                                                classNamePrefix="select"
                                                                onChange={(e, i) => this.handleOrderModesInputChange(e, i)}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-lg-4 col-6">
                                                        <div className="form-group">
                                                            <label className="form-control-label">Channel <span className="text-danger">*</span></label>
                                                            <select name="channel" className="form-control text-capitalize mt-2" required data-msg="Please select Channel" onChange={this.handleInputChange}>
                                                                <option>Select Channel</option>
                                                                {channels &&
                                                                    channels.map((channel, index) => (
                                                                        <option key={index} value={channel.sales_channel_id}>{channel.channel_name}</option>
                                                                    ))
                                                                }
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-3 col-6">
                                                        <div className="form-group">
                                                            <label className="form-control-label mb-3">Combo Image <span className="text-danger">*</span></label>
                                                            <input id="files" type="file" name="files" className="form-control-file" onChange={this.fileSelectedHandler} multiple />
                                                            <small className="form-text">You can also choose multiple images.</small>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-3 col-6">
                                                        <div className="form-group">
                                                            <label className="form-control-label">Alt Tag</label>
                                                            <input id="altTag" type="text" name="altTag" className="input-material" onChange={this.handleInputChange} />
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-2 col-6 mt-5">
                                                        <div className="">
                                                            <input id="checkboxCustom2" type="checkbox" name="topDeal" checked={topDeal} onChange={this.handleTopDeal} className="checkbox-template" />
                                                            <label htmlFor="checkboxCustom2">Top Deal</label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col">
                                                        <div className="form-group">
                                                            <label className="form-control-label">Cost Price <span className="text-danger">*</span></label>
                                                            <input id="combocost" type="number" min="1" onKeyDown={this.blockInvalidChar} name="combocost" value={combocost} required data-msg="Please enter Combo Cost" className="input-material" onChange={this.handleInputChange} />
                                                        </div>
                                                    </div>
                                                    <div className="col">
                                                        <div className="form-group">
                                                            <label className="form-control-label">Max Retail Price <small className="text-danger">*</small></label>
                                                            <input
                                                                name="combomrp"
                                                                type="number"
                                                                value={combomrp}
                                                                min="1"
                                                                onKeyDown={this.blockInvalidChar}
                                                                data-msg="Please enter mrp"
                                                                className="input-material"
                                                                onChange={this.handleInputChange}
                                                            />
                                                            {/* <small className="form-text">Tax Price ({tax_percent}%)</small> */}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-lg-3 col-sm-6 col-6 mt-5" >
                                                        <div className="">
                                                            <input id="radioCustom1" type="radio" name="settime" value="alltime" checked={settime === 'alltime'} onChange={this.handleChangeRad} className="radio-template" />
                                                            <label htmlFor="radioCustom1">Available for all time</label>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-3 col-sm-6 col-6 mt-5" >
                                                        <div className="">
                                                            <input id="radioCustom2" type="radio" name="settime" value="customtime" checked={settime === 'customtime'} onChange={this.handleChangeRad} className="radio-template" />
                                                            <label htmlFor="radioCustom2">Available for specific time</label>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-2 col-sm-6 col-6 mt-5" >
                                                        <div className="">
                                                            <input id="checkboxCustom1" type="checkbox" name="taxstatus" checked={hero_item} onChange={this.handleHeroItem} className="checkbox-template" />
                                                            <label htmlFor="checkboxCustom1">Hero Item</label>
                                                        </div>
                                                    </div>
                                                    {hero_item &&
                                                        <div className="col-lg-4 col-sm-6 col-6 mt-2">
                                                            <div className="form-group">
                                                                <label className="form-control-label mb-3">Hero Item Image <span className="text-danger">*</span></label>
                                                                <input id="files" type="file" name="files" className="form-control-file" onChange={this.heroItemImageSelectedHandler} />
                                                            </div>
                                                        </div>
                                                    }
                                                </div>
                                                {
                                                    settime === 'customtime' &&
                                                    <div>
                                                        <div className="row mt-3">
                                                            <div className="col">
                                                                <div className="form-group">
                                                                    <label className="form-control-lable">Combo Timing</label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-6">
                                                                <div className="form-group">
                                                                    <input id="combostart" type="datetime-local" name="combostart" required data-msg="Please enter starting time" className="input-material" onChange={this.handleInputChange} />
                                                                </div>
                                                            </div>
                                                            <div className="col-6">
                                                                <div className="form-group">
                                                                    <input id="comboclose" type="datetime-local" name="comboclose" required data-msg="Please enter closing time" className="input-material" onChange={this.handleInputChange} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                }
                                                <div className="row">
                                                    <div className="col-lg-4 col-sm-4 col-6 mt-5" >
                                                        <div className="">
                                                            <input id="radioCustom3" type="radio" name="is_lsm" value="0" checked={is_lsm == '0'} onChange={this.handleChangeRad} className="radio-template" />
                                                            <label htmlFor="radioCustom3">Available for all stores</label>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-4 col-sm-4 col-6 mt-5" >
                                                        <div className="">
                                                            <input id="radioCustom4" type="radio" name="is_lsm" value="1" checked={is_lsm == '1'} onChange={this.handleChangeRad} className="radio-template" />
                                                            <label htmlFor="radioCustom4">Available for specific stores</label>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-4 col-sm-4 col-12">
                                                        <div className="form-group">
                                                            <label className="form-control-label">Priority</label>
                                                            <input id="priority" type="text" name="priority" required data-msg="Please enter Priority" className="input-material" onChange={this.handleInputChange} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    {
                                                        is_lsm == '1' &&
                                                        <div className="col" >
                                                            <div className="form-group">
                                                                <label className="form-control-label">Select Store <span className="text-danger">*</span></label>
                                                                <Select
                                                                    isMulti
                                                                    defaultValue={storesJson}
                                                                    name="stores"
                                                                    options={this.props.stores}
                                                                    className="text-capitalize basic-multi-select mt-2"
                                                                    classNamePrefix="select"
                                                                    onChange={(e, i) => this.handleStoresInputChange(e, i)}
                                                                />
                                                            </div>
                                                        </div>
                                                    }
                                                </div>
                                                <div className="form-group d-flex justify-content-end mt-4">
                                                    <button className='btn btn-primary' disabled={!this.isComboReady()} onClick={this.handleSaveBtnClick}>Save</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <Footer />
                    </div>
                </div>
            </div >
        );
    }
}
const mapStateToProps = (state: any) => {
    return {
        menus: state.menu.menus,
        combos: state.menu.combos,
        channels: state.store.channels,
        combooptions: state.menu.combooptions,
        comboChoiceItems: state.menu.comboChoiceItems,
        choiceIndex: state.menu.choiceIndex,
        stores: state.menu.storesoptions,
        groups: state.menu.groups,
        message: state.menu.message,
        isInserted: state.menu.isInserted,
        brands: state.brand.brands
    }
}
const mapDispatchToProps = (dispatch: any) => {
    return {
        logoutUser: function () {
            dispatch(logoutUser());
        },
        combosList: function (brand:any) {
            dispatch(combosList(brand))
        },
        addCombo: function (data: any) {
            dispatch(addCombo(data));
        },
        channelList: function () {
            dispatch(channelList())
        },
        groupedItemsList: function (menu_id: any) {
            dispatch(groupedItemsList(menu_id))
        },
        storesList: (brand:any) => {
            dispatch(storesListForMultiSelect(brand))
        },
        menusList: function (brand:any) {
            dispatch(menusList(brand))
        },
        groupsList: function (brand:any) {
            dispatch(groupsList(brand))
        },
        comboChoiceItemsByGroupId: function (id: any, index: any) {
            dispatch(comboChoiceItemsByGroupId(id, index))
        },
        brandsList: () => {
            dispatch(brandsList())
        }
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(AddCombo);