import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import '../node_modules/react-bootstrap-table/dist/react-bootstrap-table-all.min.css'
import * as serviceWorker from './serviceWorker'
/* Bootstrap CSS */
// import 'bootstrap/dist/css/bootstrap.min.css'
// /* Font Awesome CSS */
// import "font-awesome/css/font-awesome.min.css"
ReactDOM.render(
  <App />,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register()
