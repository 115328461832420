import React, { Component } from 'react';
import { Redirect } from 'react-router-dom'
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import Topbar from '../../components/topbar'
import Sidebar from '../../components/sidebar'
import Footer from '../../components/footer/main'
import jwt from 'jsonwebtoken'
import { secretKey } from '../../secret'
import { connect } from 'react-redux'
import { logoutUser, emailList } from '../../redux'
import { NewsLetterEmailProps } from '../../interfaces/customers';
import moment from 'moment';
import Select from 'react-select';
import { brandsList } from '../../redux/actions/storeAction';

class NewsLetterEmail extends Component<NewsLetterEmailProps, {brand_id:any}> {
    constructor(readonly props: any) {
        super(props);
        this.state={
            brand_id: {
                value: 0,
                label: "All"
            }
        }
    }
    componentDidMount() {
        // this.props.emailList();
        document.title = "SimplexCMS | Mailing List"
        this.props.brandsList()
        if (sessionStorage.token) {
            jwt.verify(sessionStorage.token, secretKey, (err: any, decoded: any) => {
                if (err) {
                    this.props.logoutUser();
                } else {
                    this.props.emailList(decoded.role_id == 7 ? 0 : decoded.brand_id);
                }
            });
        }
    }
    handleBrandsInputChange = (e: any, index: any) => {
        this.setState({ brand_id: { label: e.label, value: e.value } });
        this.props.emailList(e.value);
    }
    render() {
        let roleId
        if (sessionStorage.token) {
            jwt.verify(sessionStorage.token, secretKey, (err: any, decoded: any) => {
                if (err) {
                    this.props.logoutUser();
                }else {
                    roleId = decoded.role_id
                }
            });
        } else {
            return <Redirect to="/" />
        }
        const options: any = {
            sizePerPage: 10, // which size per page you want to locate as default
            page: 1,
            pageStartIndex: 1, // where to start counting the pages
            paginationSize: 3,  // the pagination bar size.
            hideSizePerPage: true, //You can hide the dropdown for sizePerPage
            noDataText: 'Data Not Found'
        };
        return (
            <div className="page">
                <Topbar />
                <div className="page-content d-flex align-items-stretch">
                    <Sidebar />
                    <div className="content-inner">
                        {/* <!-- Page Header--> */}
                        <header className="page-header py-0">
                            <div className="container-fluid">
                                <div className="row py-2">
                                    <div className="d-flex col-lg-8 col-md-8 col-12 justify-content-between py-3">
                                        <h4 className="mt-2">Mailing List Management</h4>
                                    </div>
                                    {roleId == 7 &&
                                        <div className="col-lg-4 col-md-4 col-12">
                                            <div className="row">
                                                <div className="col-12">
                                                    <Select
                                                        name="brand_id"
                                                        options={this.props.brands}
                                                        value={this.state.brand_id}
                                                        className="text-capitalize select mt-2"
                                                        classNamePrefix="select"
                                                        onChange={(e, i) => this.handleBrandsInputChange(e, i)}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    }
                                </div>
                            </div>
                        </header>
                        <section className="tables">
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col">
                                        <div className="card">
                                            <div className="card-body">
                                                <BootstrapTable version='4' data={this.props.data} search={true} pagination={this.props.data.length > 10 && true} options={options} exportCSV={true} csvFileName='emails.csv' hover>
                                                    <TableHeaderColumn thStyle={{ fontSize: 15, whiteSpace: 'normal' }} tdStyle={{ fontSize: 15, whiteSpace: 'normal' }} dataField='id' csvHeader='#' width='70' dataSort={true} isKey>#</TableHeaderColumn>
                                                    <TableHeaderColumn thStyle={{ fontSize: 15, whiteSpace: 'normal' }} tdStyle={{ fontSize: 15, whiteSpace: 'normal' }} dataField='user_name' csvHeader='Name' width='100' columnTitle>Name</TableHeaderColumn>
                                                    <TableHeaderColumn thStyle={{ fontSize: 15, whiteSpace: 'normal' }} tdStyle={{ fontSize: 15, whiteSpace: 'normal' }} dataField='email_address' csvHeader='Email' width='150' columnTitle>Email</TableHeaderColumn>
                                                    <TableHeaderColumn thStyle={{ fontSize: 15, whiteSpace: 'normal' }} tdStyle={{ fontSize: 15, whiteSpace: 'normal' }} dataField='phone_number' csvHeader='Phone' width='120' columnTitle>Phone</TableHeaderColumn>
                                                    <TableHeaderColumn thStyle={{ fontSize: 15, whiteSpace: 'normal' }} tdStyle={{ fontSize: 15, whiteSpace: 'normal' }} dataField='dob' csvHeader='DOB' width='120' columnTitle>DOB</TableHeaderColumn>
                                                    <TableHeaderColumn thStyle={{ fontSize: 15, whiteSpace: 'normal' }} tdStyle={{ fontSize: 15, whiteSpace: 'normal' }} dataField='is_student' csvHeader='student' width='120' dataFormat={studentStatus} columnTitle>Student</TableHeaderColumn>
                                                    <TableHeaderColumn thStyle={{ fontSize: 15, whiteSpace: 'normal' }} tdStyle={{ fontSize: 15, whiteSpace: 'normal' }} dataField='is_confirm' csvHeader='Approval for marketing' width='120' dataFormat={confirmStatus} columnTitle>Approval for marketing</TableHeaderColumn>
                                                    {/* <TableHeaderColumn dataField='action' width='140' dataFormat={actionFormatter} formatExtraData={this.props} export={false}>Action</TableHeaderColumn> */}
                                                </BootstrapTable>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section >
                        <Footer />
                    </div>
                </div>
            </div>
        );
    }
}
;
function studentStatus(cell: any) {
    return (
        <div className="text-capitalize">
            <span {...(cell == "yes" && { className: "badge badge-success p-2" }) || (cell == "no" && { className: "badge badge-danger p-2" })}>{cell}</span>
        </div>
    )
}
function confirmStatus(cell: any) {
    return (
        <div className="text-capitalize">
            <span {...(cell == "confirmed" && { className: "badge badge-success p-2" }) || (cell == "no" && { className: "badge badge-danger p-2" })}>{cell}</span>
        </div>
    )
}
function timeFormatter(cell: any) {
    console.log("cell", cell)
    return (
        <div>
            <span {...((cell !== "" && cell !== null) && { title: moment(cell).format('YYYY-MM-DD HH:mm') })}> {(cell !== "" && cell !== null) && moment(cell).format('YYYY-MM-DD HH:mm')} </span>
        </div>
    )
}
function emailStatus(cell: any) {
    return (
        <div className="text-capitalize">
            <span {...(cell == 1 && { className: "badge badge-success p-2" }) || (cell == 0 && { className: "badge badge-danger p-2" })}>{cell == 0 ? "In active" : "Active"}</span>
        </div>
    )
}
const mapStateToProps = (state: any) => {
    return {
        data: state.customer.newsletterEmails,
        brands: state.brand.brands
    }
}
const mapDispatchToProps = (dispatch: any) => {
    return {
        logoutUser: function () {
            dispatch(logoutUser());
        },
        emailList: function (brand:any) {
            dispatch(emailList(brand))
        },
        brandsList: function () {
            dispatch(brandsList("GET_BY_BRAND"))
        },
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(NewsLetterEmail);