import { CHANNEL_MIX_REPORTS, RIDER_DETAILS_REPORT, ORDER_DETAIL_REPORT, STORES_REPORTS, RIDERS_REPORTS, SOS_REPORTS, STORES_LIST, COUPON_REDEMPTION_REPORTS, CUSTOMERS_REPORTS, DAILY_SALES_REPORTS, DISCOUNT_REPORTS, ERROR, PMIX_REPORTS, PROFIT_REPORTS, SALES_MIX_REPORTS, CANCEL_SMRY_REPORTS, CANCEL_DETAIL_REPORT, ACTIVITY_LOGS_REPORT, REPORTS_LOAD, TOTAL_SALES_REPORTS, LOYALTY_CUSTOMERS_REPORT } from '../actions/reportType';
const initialState: any = {
    dailyReports: [] || "",
    totalSalesReport:[]||"",
    sosReports: [] || "",
    stores: [] || "",
    customerReports: [] || "",
    pmixReports: [] || "",
    smixReports: [] || "",
    channelmixReports: [] || "",
    customerLoyaltyReports: [] || "",
    couponredemptionReports: [] || "",
    profitReports: [] || "",
    discountReports: [] || "",
    ridersReports: [] || "",
    storesReports: [] || "",
    activity_logs: [] || "",
    cancel_smryReports: [] || "",
    cancel_detail_report: [] || "",
    order_details_report: [] || "",
    rider_details_report: [] || "",
    msg: "",
    currentPage: 0,
    pageCount: 0,
    numOfRows: 0,
    isLoad: false
}

const reportReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case DAILY_SALES_REPORTS: return {
            ...state,
            dailyReports: action.payload,
            currentPage: action.currentPage,
            pageCount: action.pageCount,
            numOfRows: action.numOfRows,
            msg: action.msg
        }
        case TOTAL_SALES_REPORTS: return {
            ...state,
            totalSalesReport: action.payload,
            currentPage: action.currentPage,
            pageCount: action.pageCount,
            numOfRows: action.numOfRows,
            msg: action.msg
        }
        case SOS_REPORTS: return {
            ...state,
            sosReports: action.payload,
            currentPage: action.currentPage,
            pageCount: action.pageCount,
            numOfRows: action.numOfRows,
            msg: action.msg
        }
        case ACTIVITY_LOGS_REPORT: return {
            ...state,
            activity_logs: action.payload,
            currentPage: action.currentPage,
            pageCount: action.pageCount,
            numOfRows: action.numOfRows,
            msg: action.msg
        }
        case STORES_REPORTS: return {
            ...state,
            storesReports: action.payload,
            currentPage: action.currentPage,
            pageCount: action.pageCount,
            numOfRows: action.numOfRows,
            msg: action.msg
        }
        case CANCEL_SMRY_REPORTS: return {
            ...state,
            cancel_smryReports: action.payload,
            currentPage: action.currentPage,
            pageCount: action.pageCount,
            numOfRows: action.numOfRows,
            msg: action.msg
        }
        case CANCEL_DETAIL_REPORT: return {
            ...state,
            cancel_detail_report: action.payload,
            currentPage: action.currentPage,
            pageCount: action.pageCount,
            numOfRows: action.numOfRows,
            msg: action.msg
        }
        case ORDER_DETAIL_REPORT: return {
            ...state,
            order_details_report: action.payload,
            currentPage: action.currentPage,
            pageCount: action.pageCount,
            numOfRows: action.numOfRows,
            msg: action.msg
        }
        case RIDERS_REPORTS: return {
            ...state,
            ridersReports: action.payload,
            currentPage: action.currentPage,
            pageCount: action.pageCount,
            numOfRows: action.numOfRows,
            msg: action.msg
        }
        case RIDER_DETAILS_REPORT: return {
            ...state,
            rider_details_report: action.payload,
            currentPage: action.currentPage,
            pageCount: action.pageCount,
            numOfRows: action.numOfRows,
            msg: action.msg
        }
        case STORES_LIST: return {
            ...state,
            stores: action.payload
        }
        case CUSTOMERS_REPORTS: return {
            ...state,
            customerReports: action.payload,
            currentPage: action.currentPage,
            pageCount: action.pageCount,
            numOfRows: action.numOfRows,
            msg: action.msg
        }
        case PMIX_REPORTS: return {
            ...state,
            pmixReports: action.payload,
            currentPage: action.currentPage,
            pageCount: action.pageCount,
            numOfRows: action.numOfRows,
            msg: action.msg
        }
        case SALES_MIX_REPORTS: return {
            ...state,
            smixReports: action.payload,
            currentPage: action.currentPage,
            pageCount: action.pageCount,
            numOfRows: action.numOfRows,
            msg: action.msg
        }
        case CHANNEL_MIX_REPORTS: return {
            ...state,
            channelmixReports: action.payload,
            currentPage: action.currentPage,
            pageCount: action.pageCount,
            numOfRows: action.numOfRows,
            msg: action.msg
        }
        case LOYALTY_CUSTOMERS_REPORT: return {
            ...state,
            customerLoyaltyReports: action.payload,
            currentPage: action.currentPage,
            pageCount: action.pageCount,
            numOfRows: action.numOfRows,
            msg: action.msg
        }
        case COUPON_REDEMPTION_REPORTS: return {
            ...state,
            couponredemptionReports: action.payload,
            currentPage: action.currentPage,
            pageCount: action.pageCount,
            numOfRows: action.numOfRows,
            msg: action.msg
        }
        case PROFIT_REPORTS: return {
            ...state,
            profitReports: action.payload,
            currentPage: action.currentPage,
            pageCount: action.pageCount,
            numOfRows: action.numOfRows,
            msg: action.msg
        }
        case DISCOUNT_REPORTS: return {
            ...state,
            discountReports: action.payload,
            currentPage: action.currentPage,
            pageCount: action.pageCount,
            numOfRows: action.numOfRows,
            msg: action.msg
        }
        case ERROR: return {
            ...state,
            msg: action.msg
        }
        case REPORTS_LOAD: return {
            ...state,
            isLoad: action.isLoad
        }
        default: return state;
    }
}
export default reportReducer;