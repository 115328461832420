import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Sidebar from '../../components/sidebar';
import Topbar from '../../components/topbar'
import Footer from '../../components/footer/main'
import { segmentationProps, segmentationStates } from '../../interfaces/marketing';
import { itemsListOfActiveMenu } from '../../redux/actions/menuAction';
import { logoutUser } from '../../redux';
import { connect } from 'react-redux';
import Select from 'react-select';
import { toast } from 'react-toastify';
import jwt from 'jsonwebtoken'
import { secretKey } from '../../secret'
import { createAudienceList, customerFilteration, resetCustomerFilteration } from '../../redux/actions/marketing';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import { Redirect } from 'react-router-dom';
import Loader from "react-loader-spinner";
import { currency } from '../../client-config'
import _ from 'lodash';
import { brandsList } from '../../redux/actions/storeAction';


// const createSliderWithTooltip = Slider.createSliderWithTooltip;
// const Range = createSliderWithTooltip(Slider.Range);
class Segmentation extends Component<segmentationProps, segmentationStates> {
    constructor(props: any) {
        super(props);
        this.state = {
            chequeFlag: false,
            dataType: '',
            customDateFlag: false,
            days: 0,
            validTimeFlagFrom: false,
            validTimeFlagTo: false,
            customerDetailFlag: false,
            countValue: 0,
            audience_name: "",
            audience_desc: "",
            itemId: '',
            comboId: '',
            ticketFrom: "",
            ticketTo: "",
            startDate: "",
            endDate: "",
            limit: "",
            brand_id: {
                value: 0,
                label: "All"
            }
        }

    }

    handleBrandsInputChange = (e: any, index: any) => {
        this.props.resetCustomerFilteration()
        this.setState({brand_id:{label:e.label,value:e.value,new:false}});
    }

    componentDidMount() {
        this.props.brandsList()
        this.props.itemsListOfActiveMenu();
    }



    onValueChange = (e: any) => {
        console.log("marking value of checkbox", e.target.value)
    }

    filterDataReady = () => {
        let { customDateFlag, dataType,chequeFlag, days, startDate, endDate,countValue,itemId,comboId ,ticketFrom, ticketTo, limit } = this.state;
        if (dataType == "ticket") {
            if(customDateFlag){
                if(chequeFlag){
                    return (startDate !== "" && endDate !== '' && ticketFrom !== "" && ticketTo !== "" && limit !== "");
                }
                else {
                    return (startDate !== "" && endDate !== '' && limit !== "");
                }
            }
            else{
                if(chequeFlag) {
                    return (days !== '' && ticketFrom !== "" && ticketTo !== "" && limit !== "");
                }
                else {
                    return (days !== '' && limit !== "");
                }
            }
        }
        else if (dataType == "ticketCount") {
            if(customDateFlag){
                return (startDate !== "" && endDate !== '' && countValue !== '' && limit !== "");   
            }
            else {
                return (days !== '' && countValue !== '' && limit !== "");   
            }
        } 
        else if (dataType == 'item') {
            if(customDateFlag){
                return (startDate !== "" && endDate !== '' && (itemId !== '' || comboId !== '') && limit !== "");   
            }
            else {
                return (days !== '' && (itemId !== '' || comboId !== '') && limit !== "");   
            }
        } else if(dataType == 'complaints') {
            return (days != '' && limit !='')
        } else if(dataType == 'newsLetter') {
            return (days != '' && limit !='')
        }
        // else if (type == "group" || type == "item") {
        //     return (startDate !== "" && endDate !== '' && ticketFrom !== "" && ticketTo !== "" && limit !== "");
        // }
    }

    isAudienceReady = () => {
        const {audience_name, audience_desc} = this.state;
        return (audience_name !== '' && audience_desc !== '')
    }


    handleInputChange = (event: { target: { name: any; value: any; }; }) => {
        if (event.target.value === 'custom') {
            this.setState({ customDateFlag: true });
        }
        else {
            this.setState({
                [event.target.name]: event.target.value,
                customDateFlag:false
            });
        }
    }

    handleItemsInputChange = (e: any, index: any) => {
        console.log(e)
        if (!_.isEmpty(e)) {
            if (e.menu_item_id) {
                this.setState({ itemId: e.menu_item_id, comboId: '' });

            }
            else {
                this.setState({ comboId: e.combo_id, itemId: '' })
            }
        }
        else {
            this.setState({ itemId: '' })
            this.setState({ comboId: '' })
        }
    }

    handleValidTime = (event: { target: { name: any; value: any } }) => {
        let selectedDateTime = new Date(event.target.value)
        let validMinDateTime = new Date();
        if (selectedDateTime <= validMinDateTime) {
            this.setState({ [event.target.name]: event.target.value });
            if (event.target.name == 'startDate') {
                this.setState({ validTimeFlagFrom: true })
            }
            else {
                this.setState({ validTimeFlagTo: true })
            }
        } else {
            toast.error("Invalid date and time selected");
            if (event.target.name == 'startDate') {
                this.setState({ validTimeFlagFrom: false })
            }
            else {
                this.setState({ validTimeFlagTo: false })
            }
        }
    }

    submitData = () => {
        let { dataType,brand_id } = this.state;
        
        if (dataType == 'ticket') {
            this.ticketFilter(brand_id)
        }
        if (dataType === 'ticketCount') {
            this.countFilter(brand_id)
        }
        else if (dataType === 'item') {
            this.itemFilter(brand_id)
        } else if(dataType === 'complaints') {
            this.complaintFilter(brand_id)
        } else if(dataType === 'newsLetter') {
            this.newsLetterFilter(brand_id)
        }
    }
    ticketFilter = (brand:any) => {
        let { customDateFlag, chequeFlag, days, startDate, endDate, ticketFrom, ticketTo, limit } = this.state;
        let obj: any = {
            dataType: this.state.dataType,
            limit: limit,
        }
        if (customDateFlag) {
            obj.dateFlag = customDateFlag
            obj.start = startDate;
            obj.end = endDate
        }
        else {
            obj.days = days
        }
        if (chequeFlag) {
            obj.ticketFlag = chequeFlag;
            obj.ticketFrom = ticketFrom;
            obj.ticketTo = ticketTo;
        }
        console.log("payload before sending to server", obj)
        this.props.customerFilteration(obj,brand)
    }

    countFilter = (brand:any) => {
        let { customDateFlag, chequeFlag, days, countValue, startDate, endDate, ticketFrom, ticketTo, limit } = this.state;
        let obj: any = {
            dataType: this.state.dataType,
            limit: limit,
            countValue: countValue
        }
        if (customDateFlag) {
            obj.dateFlag = customDateFlag
            obj.start = startDate;
            obj.end = endDate
        }
        else {
            obj.days = days
        }
        console.log("payload before sending to server", obj)
        this.props.customerFilteration(obj,brand)
    }

    complaintFilter = (brand:any) => {
        let { customDateFlag, chequeFlag, days, countValue, startDate, endDate, ticketFrom, ticketTo, limit } = this.state;
        let obj: any = {
            dataType: this.state.dataType,
            limit: limit,
            days
        }
        if (customDateFlag) {
            obj.dateFlag = customDateFlag
            obj.start = startDate;
            obj.end = endDate
        }
        console.log("payload before sending to server", obj)
        this.props.customerFilteration(obj,brand)
    }

    newsLetterFilter = (brand:any) => {
        let { customDateFlag, chequeFlag, days, countValue, startDate, endDate, ticketFrom, ticketTo, limit } = this.state;
        let obj: any = {
            dataType: this.state.dataType,
            limit: limit,
            days
        }
        if (customDateFlag) {
            obj.dateFlag = customDateFlag
            obj.start = startDate;
            obj.end = endDate
        }
        console.log("payload before sending to server", obj)
        this.props.customerFilteration(obj,brand)
    }
    


    itemFilter = (brand:any) => {
        let { customDateFlag, chequeFlag, days, itemId, comboId, countValue, startDate, endDate, ticketFrom, ticketTo, limit } = this.state;
        let obj: any = {
            dataType: this.state.dataType,
            limit: limit,
        }
        if (itemId !== '') {
            obj.itemId = itemId;
        }
        else {
            obj.comboId = comboId;
        }
        if (customDateFlag) {
            obj.dateFlag = customDateFlag
            obj.start = startDate;
            obj.end = endDate
        }
        else {
            obj.days = days
        }
        console.log("payload before sending to server", obj)
        this.props.customerFilteration(obj,brand)
    }

    createAudience = () => {
        const { audienceList } = this.props;
        const { audience_name, audience_desc,brand_id } = this.state;
        let objData = {
            name: audience_name,
            desc: audience_desc,
            customerArr: audienceList,
            brand_id:brand_id.value
        }
        this.props.createAudienceList(objData);
    }

    render() {
        const { data } = this.props;
        let columns: any = []
        let roleId;
        if (data.length > 0) {
            columns = Object.keys(data[0])
        }
        if (sessionStorage.token) {
            jwt.verify(sessionStorage.token, secretKey, (err: any, decoded: any) => {
                if (err) {
                    this.props.logoutUser();
                }else {
                    roleId = decoded.role_id
                }
            });
        } else {
            return <Redirect to="/" />
        }
        const options: any = {
            // sizePerPageList: [5,10,15],
            sizePerPage: 10, // which size per page you want to locate as default
            page: 1,
            pageStartIndex: 1, // where to start counting the pages
            paginationSize: 3,  // the pagination bar size.
            hideSizePerPage: true, //You can hide the dropdown for sizePerPage
            insertModal: () => { return <Redirect to="/add-menu" /> },
            noDataText: 'Customer Not Found'
        };
        return (
            <div className="page">
                <Topbar />
                <div className="page-content d-flex align-items-stretch">
                    <Sidebar />
                    <div className="content-inner">
                        <div className="container-fluid mt-3">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="card">
                                        <div className="card-body">
                                            <h4>360-Degree Customer View</h4>
                                            <div className="row">
                                                <div className="col-6">
                                                    <div className="form-group d-flex flex-column ">
                                                        <label className="form-control-label">
                                                            Data Period  <span className="text-danger">*</span>
                                                        </label>
                                                        <select onChange={this.handleInputChange} name="days" className="form-control text-capitalize">
                                                            <option>
                                                                Select Date Range
                                                            </option>
                                                            <option value="7">
                                                                Last 7 days
                                                            </option>
                                                            <option value="15">
                                                                Last 15 days
                                                            </option>
                                                            <option value="30">
                                                                Last 30 days
                                                            </option>
                                                            <option value="60">
                                                                Last 60 days
                                                            </option>
                                                            <option value="90">
                                                                Last 90 days
                                                            </option>
                                                            <option value="custom">
                                                                Custom Date
                                                            </option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className="col-6">
                                                    <div className="form-group d-flex flex-column ">
                                                        <label className="form-control-label">
                                                            Data filter by  <span className="text-danger">*</span>
                                                        </label>
                                                        <select onChange={this.handleInputChange} name="dataType" className="form-control text-capitalize" required data-msg="Please select filter type">
                                                            <option value="">
                                                                Select Type
                                                            </option>
                                                            <option value="ticket">
                                                                Ticket Value
                                                            </option>
                                                            <option value="ticketCount">
                                                                Ticket Count 
                                                            </option>
                                                            <option value="item">
                                                                Item Purchased
                                                            </option>
                                                            <option value="complaints">
                                                                Complaints
                                                            </option>
                                                            <option value="newsLetter">
                                                                News Letter Subscription
                                                            </option>
                                                        </select>
                                                    </div>
                                                </div>
                                                {/* <div className="col-4">
                                                    <div className="form-group d-flex flex-column ">
                                                        <label className="form-control-label">
                                                            Data filter by City <span className="text-danger">*</span>
                                                        </label>
                                                        <select name="dataType" className="form-control text-capitalize" required data-msg="Please select filter type">
                                                            <option value="">
                                                                Select City
                                                            </option>
                                                            <option value='0'>
                                                                All
                                                            </option>
                                                            {this.props.cities && this.props.cities.map((city:any)=>(
                                                                <option value={city.value}>{city.name}</option>
                                                            ))}
                                                        </select>
                                                    </div>
                                                </div> */}
                                                {this.state.dataType === 'ticket' &&
                                                    <div className="col-2">
                                                        <div className="form-group d-flex flex-column ">
                                                            <label className="form-control-label">
                                                                Ticket Range 
                                                            </label>
                                                            <div className="radio-row d-flex align-items-center">
                                                                <label>
                                                                    <input
                                                                        type="checkbox"
                                                                        value="1"
                                                                        name="chequeFlag"
                                                                        // checked={this.state.selectedOption === "Male"}
                                                                        onChange={() => { this.setState({ chequeFlag: !this.state.chequeFlag }) }}
                                                                    />
                                                                    Enable
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                }
                                   {/*advance customer filteration */}
                                                {/* {this.state.dataType === 'ticket' &&
                                                    <div className="col-2">
                                                        <div className="form-group d-flex flex-column ">
                                                            <label className="form-control-label">
                                                                Advance customer filtering
                                                            </label>
                                                            <div className="radio-row d-flex align-items-center">
                                                                <label>
                                                                    <input
                                                                        type="checkbox"
                                                                        value="1"
                                                                        name="chequeFlag"
                                                                        // checked={this.state.selectedOption === "Male"}
                                                                        onChange={() => { this.setState({ customerDetailFlag: !this.state.customerDetailFlag }) }}
                                                                    />
                                                                    Enable
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                } */}
                                                {this.state.dataType === 'ticketCount' &&
                                                    <div className="col-4">
                                                        <div className="form-group d-flex flex-column ">
                                                            <label className="form-control-label">
                                                                Ticket Count Greater Than <span className="text-danger">*</span>
                                                            </label>
                                                            <input id="percent" type="number" name="countValue" min="1" required data-msg="Please enter Discount Percentage" className="input-material" onChange={this.handleInputChange} />
                                                        </div>
                                                    </div>
                                                }
                                                {(this.state.chequeFlag && this.state.dataType === 'ticket') &&
                                                    <>
                                                        <div className="col-4">
                                                            <div className="form-group d-flex flex-column ">
                                                                <label className="form-control-label">
                                                                    Ticket Range From  <span className="text-danger">*</span>
                                                                </label>
                                                                <input id="percent" type="text" name="ticketFrom" min="1" required data-msg="Please enter Discount Percentage" className="input-material" onChange={this.handleInputChange} />
                                                            </div>
                                                        </div>
                                                        <div className="col-4">
                                                            <div className="form-group d-flex flex-column ">
                                                                <label className="form-control-label">
                                                                    Ticket Range To  <span className="text-danger">*</span>
                                                                </label>
                                                                <input id="percent" type="text" name="ticketTo" min="1" required data-msg="Please enter Discount Percentage" className="input-material" onChange={this.handleInputChange} />
                                                            </div>
                                                        </div>
                                                    </>
                                                }
                                            </div>
                                            {this.state.customDateFlag &&
                                                <div className="row">
                                                    <div className="col-4">
                                                        <div className="form-group d-flex flex-column ">
                                                            <label className="form-control-label">
                                                                Date From  <span className="text-danger">*</span>
                                                            </label>
                                                            <input
                                                                id="start"
                                                                type="date"
                                                                name="startDate"
                                                                // max={maxDate}
                                                                required
                                                                data-msg="Please enter Start Date"
                                                                className="input-material"
                                                                onChange={this.handleValidTime}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-4">
                                                        <div className="form-group d-flex flex-column ">
                                                            <label className="form-control-label">
                                                                Date To  <span className="text-danger">*</span>
                                                            </label> 
                                                            <input
                                                                id="end"
                                                                type="date"
                                                                name="endDate"
                                                                // max={maxDate}
                                                                required
                                                                data-msg="Please enter End Date"
                                                                className="input-material"
                                                                onChange={this.handleValidTime}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            }
                                            {this.state.dataType === 'item' &&
                                                <div className="row">
                                                    <div className="col-4">
                                                        <div className="form-group">
                                                            <label className="form-control-label">
                                                                Item <span className="text-danger">*</span>
                                                            </label> 
                                                            <Select
                                                                isMulti={false}
                                                                name="items"
                                                                options={this.props.items}
                                                                className="text-capitalize basic-multi-select mt-2"
                                                                classNamePrefix="select"
                                                                onChange={(e, i) => this.handleItemsInputChange(e, i)}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            }
                                            <div className="row">
                                                <div className="col-6">
                                                    <div className="form-group">
                                                        <label className="form-control-label">
                                                            Limit Data <span className="text-danger">*</span>
                                                        </label>
                                                        <select name="limit" onChange={this.handleInputChange} className="form-control text-capitalize">
                                                            <option value="">
                                                                none
                                                            </option>
                                                            <option value="1">
                                                                upto 1
                                                            </option>
                                                            <option value="5">
                                                                upto 5
                                                            </option>
                                                            <option value="100">
                                                                upto 100
                                                            </option>
                                                            <option value="1000">
                                                                upto 1000
                                                            </option>
                                                            <option value="10000">
                                                                upto 10000
                                                            </option>
                                                            <option value="50000">
                                                                upto 50000
                                                            </option>
                                                        </select>
                                                    </div>
                                                </div>
                                                {roleId == 7 && 
                                                <div className="col-6">
                                                    <div className="form-group">
                                                        <label className="form-control-label">
                                                            Brands <span className="text-danger">*</span>
                                                        </label>
                                                        <Select
                                                            name="brand_id"
                                                            options={this.props.brands}
                                                            value={this.state.brand_id}
                                                            className="text-capitalize select"
                                                            classNamePrefix="select"
                                                            onChange={(e, i) => this.handleBrandsInputChange(e, i)}
                                                        />
                                                    </div>
                                                </div>
                                             }
                                            </div>
                                            <div className="form-group d-flex justify-content-between mt-4">
                                                {this.props.data.length >= 0 &&
                                                    <div className="row">
                                                        <div className="col">
                                                            <button data-toggle="modal" disabled={this.props.data.length==0} className='btn btn-dark' data-target={`#createAudienceModal`} >Create Audience</button>
                                                        </div>
                                                    </div>
                                                }
                                                <button disabled={!this.filterDataReady()} onClick={this.submitData} className='btn btn-primary'>Submit</button>
                                            </div>


                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {this.props.loading ?
                            <Loader style={{ display: "block", position: "fixed", top: "60%", left: "60%", transform: "translate(-50%, -50%)" }} type="ThreeDots" color="#555555" height={70} width={70} />
                            :
                            <section className="tables">
                                <div className="container-fluid">
                                    <div className="row">
                                        <div className="col">
                                            <div className="card">
                                                <div className="card-body">
                                                    <BootstrapTable version='4' data={this.props.data} search={true} pagination={this.props.data.length > 10 && true} options={options} exportCSV={true}  csvFileName='segmentation.csv' hover>
                                                        <TableHeaderColumn thStyle={{ fontSize: 15, whiteSpace: 'normal' }} tdStyle={{ fontSize: 15, whiteSpace: 'normal' }} dataField="customer_id" csvHeader='#' width='100' dataSort={true} isKey>#</TableHeaderColumn>
                                                        <TableHeaderColumn thStyle={{ fontSize: 15, whiteSpace: 'normal' }} tdStyle={{ fontSize: 15, whiteSpace: 'normal' }} dataField='login_name' csvHeader='Name' width='100' columnTitle>Name</TableHeaderColumn>
                                                        <TableHeaderColumn thStyle={{ fontSize: 15, whiteSpace: 'normal' }} tdStyle={{ fontSize: 15, whiteSpace: 'normal' }} dataField='email_address' csvHeader='Email' width='100' columnTitle>Email</TableHeaderColumn>
                                                        <TableHeaderColumn thStyle={{ fontSize: 15, whiteSpace: 'normal' }} tdStyle={{ fontSize: 15, whiteSpace: 'normal' }} dataField='phone_number' csvHeader='Phone' width='100' columnTitle>Phone Number</TableHeaderColumn>
                                                        <TableHeaderColumn dataField='order_grossprice' csvHeader='Order Amount' dataFormat={priceFormatter} width='100' columnTitle>Order Price</TableHeaderColumn>
                                                        <TableHeaderColumn dataField="name" csvHeader='Item Name' width='100' columnTitle>Item Name</TableHeaderColumn>
                                                        <TableHeaderColumn dataField='count' csvHeader='Purchases' width='100' columnTitle>Purchases</TableHeaderColumn>
                                                        {/* <TableHeaderColumn thStyle={{ fontSize: 15, whiteSpace: 'normal' }} tdStyle={{ fontSize: 15, whiteSpace: 'normal' }} dataField='is_publish' width='100' dataFormat={statusFormatter} export={false}>Status</TableHeaderColumn>
                                                    <TableHeaderColumn thStyle={{ fontSize: 15, whiteSpace: 'normal' }} tdStyle={{ fontSize: 15, whiteSpace: 'normal' }} dataField='action' width='230' dataFormat={actionFormatter} formatExtraData={this.props} export={false}>Action</TableHeaderColumn> */}
                                                    </BootstrapTable>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>

                        }
                        <section>
                            <div id={`createAudienceModal`} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" className="modal fade text-left">
                                <div role="document" className="modal-dialog">
                                    <div className="modal-content">
                                        <div className="modal-header">
                                            <h4 id="exampleModalLabel" className="modal-title">Create Audience List</h4>
                                            <button type="button" data-dismiss="modal" aria-label="Close" className="close"><span aria-hidden="true">×</span></button>
                                        </div>
                                        <div className="modal-body">
                                            {/* <div className="row">
                                                <div className="col-12">
                                                    <span>Are you sure,you want to this store?</span>
                                                </div>
                                            </div> */}
                                            <div className="row">
                                                <div className="col-12">
                                                    <div className="form-group">
                                                        <label className="form-control-label">
                                                            Audience Name <span className="text-danger">*</span>
                                                        </label>
                                                        <input id="percent" type="text" name="audience_name" required data-msg="Please enter audience name" className="input-material" onChange={this.handleInputChange} />
                                                    </div>
                                                </div>
                                                <div className="col-12">
                                                    <div className="form-group">
                                                        <label className="form-control-label">
                                                            Audience Description <span className="text-danger">*</span>
                                                        </label>
                                                        <input id="percent" type="text" name="audience_desc" required data-msg="Please enter audience description" className="input-material" onChange={this.handleInputChange} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="modal-footer">
                                            <button type="button" data-dismiss="modal" className="btn btn-secondary">Close</button>
                                            <button disabled={!this.isAudienceReady()} onClick={this.createAudience} className="btn btn-primary">Confirm</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <Footer />
                    </div>
                </div>
            </div>
        );
    }
}

function priceFormatter(cell: any, row: any) {
    if (row.order_grossprice) {
        return `${currency}.${cell}`
    }
    else {
        return 'N/A'
    }
}

function columnFormatter(cell: any, row: any) {
    console.log(row)
    // if (row.item_name) {
    //     return 'item_name';
    // } else {
    //     return 'combo_name';
    // }
}

const mapStateToProps = (state: any) => {
    return {
        items: state.menu.allItemsOfActiveMenu,
        data: state.marketing.data,
        audienceList: state.marketing.audienceList,
        loading: state.marketing.loading,
        brands: state.brand.brands
    }
}
const mapDispatchToProps = (dispatch: any) => {
    return {
        itemsListOfActiveMenu: function () {
            dispatch(itemsListOfActiveMenu(0))
        },
        customerFilteration: function (data: any,brand:any) {
            dispatch(customerFilteration(data,brand))
        },
        resetCustomerFilteration:()=>{
           dispatch(resetCustomerFilteration())
        },
        createAudienceList: function (data: any) {
            dispatch(createAudienceList(data))
        },
        brandsList: function () {
            dispatch(brandsList("GET_BY_BRAND"))
        },
        logoutUser: function () {
            dispatch(logoutUser());
        },
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(Segmentation);