import React, { Component } from 'react';
import { Redirect } from 'react-router-dom'
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import Topbar from '../../components/topbar'
import Sidebar from '../../components/sidebar'
import Footer from '../../components/footer/main'
import jwt from 'jsonwebtoken'
import { secretKey } from '../../secret'
import { connect } from 'react-redux'
import BeautyStars from 'beauty-stars';
import Invoice from './invoice';
import { cancelOrdersReasons, logoutUser, luanchTimer, ordersList, orderStatusList, showorderItems, statesList, stopTimer, storesList, updatefutureOrder, updateOrder } from '../../redux'
import { OrderProps } from '../../interfaces/order';
import moment from 'moment';
import Select from 'react-select';
import { filterOrdersList, searchOrder } from '../../redux/actions/orderAction';
import { toast } from "react-toastify";
import { currency } from '../../client-config'
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import "react-toastify/dist/ReactToastify.css";
import { ReactTimer } from './timer/timer'
import { brandsList } from '../../redux/actions/storeAction';
toast.configure();

class DriverEditor extends React.Component<{ data: any, row: any }, { [x: number]: any, deliveryboy: any }> {
    constructor(props: any) {
        super(props);
        this.state = {
            deliveryboy: ""
        }
        this.handleInputChange = this.handleInputChange.bind(this);
    }
    componentDidMount() {
        const { row } = this.props
        this.setState({ deliveryboy: row.delivery_boy })
    }
    handleSave = () => {
        const { row } = this.props
        const { deliveryboy } = this.state;
        const data = {
            delivery_boy: deliveryboy ? deliveryboy : "",
        }
        if (deliveryboy !== "" && deliveryboy > 0) {
            this.props.data.updateOrder(row.order_id, data, this.props.data.history)
        } else {
            this.setState({ deliveryboy: row.delivery_boy })
            toast.error("Please select the driver", { position: toast.POSITION.TOP_RIGHT, hideProgressBar: true, autoClose: 3000 });
        }

    }
    handleInputChange(event: { target: { name: any; value: any; }; }) {
        this.setState({
            [event.target.name]: event.target.value
        });
    }
    render() {
        const { data, row } = this.props
        const { deliveryboy } = this.state;
        return (
            <div>
                {(row.order_status_code === 4 || row.order_status_code === 5 || row.order_status_code === 6) ? <p>{row.user_name}</p> :
                    (row.order_status_code !== 1 && row.order_status_code !== 2 && row.order_status_code !== 7) &&
                    (
                        <div>
                            <select
                                name="deliveryboy"
                                // {...row.order_status_code == 3 && { disabled: true }}
                                {...(row.order_status_code == 3 || row.aggregator_delivery == 1) && { disabled: true }}
                                value={deliveryboy || ''}
                                onChange={this.handleInputChange}
                                className="text-capitalize">
                                <option value="">Select Driver</option>
                                {data.drivers &&
                                    data.drivers.map((driver: any, index: any) => (
                                        // To lock local rider after one order dispatched
                                        // ((driver.store_id && row.store_id == driver.store_id.store_id) && driver.clockIn == 1 && (driver.goPanda == "true" || ((driver.goPanda == "false" && !driver.order_id) || (driver.order_id && driver.order_id == row.order_id)))) ?
                                        // ((driver.store_id && row.store_id == driver.store_id.store_id) && driver.clockIn == 1) ?
                                        (((driver.store_id && row.store_id == driver.store_id.store_id) && driver.clockIn == 1) || driver.store_id == null) ?
                                            <option key={index} value={driver.id}>{driver.user_name.length > 15 ? `${driver.user_name.substring(0, 15)}...` : driver.user_name}</option>
                                            : ""
                                    ))
                                }
                            </select>
                            <br />
                            {/* {(row.order_status_code != 3 && row.aggregator_delivery == null) && <span className="badge badge-pill badge-info" style={{ cursor: 'pointer' }} onClick={this.handleSave}>save</span>} */}
                            {(row.order_status_code != 3 && row.aggregator_delivery == null) && <span className="badge badge-pill badge-info" style={{ cursor: 'pointer' }} onClick={this.handleSave}>save</span>}
                        </div>
                    )
                }
            </div>
        );
    }
}
class StatusEditor extends React.Component<{ data: any, row: any }, { [x: number]: any, cancelReason: any, orderstatus: any, otherReason: any }> {
    openModal: any;
    closeModal: any;
    constructor(props: any) {
        super(props);
        this.state = {
            orderstatus: "",
            cancelReason: "",
            otherReason: ""
        }
        this.handleInputChange = this.handleInputChange.bind(this);
        this.cancelOrder = this.cancelOrder.bind(this);
    }
    componentDidMount() {
        const { row } = this.props
        this.setState({ orderstatus: row.order_status_code })
    }
    handleSave = () => {
        const { row } = this.props
        const { orderstatus } = this.state;
        // let orderDate = moment(row.date_created).local().format('DD/MM/YYYY HH:mm:ss')
        // let currentDate = moment().format('DD/MM/YYYY HH:mm:ss');
        // let orderDateforComp = moment(row.date_created).local().format('YYYY-MM-DD');
        // let currentDateforComp = moment().format('YYYY-MM-DD');
        // var createdTime = moment(row.date_created).local().format('HH:mm');
        // var currentTime = moment().local().format('HH:mm');
        // var startTime = moment(createdTime, "HH:mm");
        // var endTime = moment(currentTime, "HH:mm");
        // var duration: any = moment.duration(endTime.diff(startTime));
        // var minutes = parseInt(duration.asMinutes());
        // var minutesduration = 0;
        // if (moment(currentDateforComp).isAfter(orderDateforComp)) {
        //     var ms = moment(currentDate, "DD/MM/YYYY HH:mm:ss").diff(moment(orderDate, "DD/MM/YYYY HH:mm:ss"));
        //     var d = moment.duration(ms);
        //     minutesduration = Math.floor(d.asMinutes());
        // } else {
        //     minutesduration = minutes;
        // }
        // let deliverytime: any = row.delivery_time_json != null ? JSON.parse(row.delivery_time_json) : { pending: 0, kitchen: 0, ready: 0, dispatched: 0 }
        if (row.delivery_status == "Delivery") {
            if (orderstatus == row.order_status_code) {
                toast.error("Please change the status", { position: toast.POSITION.TOP_RIGHT, hideProgressBar: true, autoClose: 3000 });
            } else {
                if (orderstatus == 3) {
                    if (row.delivery_boy !== "" && row.delivery_boy !== null) {
                        // deliverytime["ready"] = minutesduration - (deliverytime.pending + deliverytime.kitchen);
                        const data = {
                            order_status_code: orderstatus,
                            delivery_status: row.delivery_status,
                            orderData: row
                        }
                        this.props.data.updateOrder(row.order_id, data, this.props.data.history)
                    } else {
                        alert('Please Assign the order to driver first')
                    }
                } else if (orderstatus == 6) {
                    this.openModal.click();
                } else {
                    // if (orderstatus == 2) {
                    //     deliverytime["pending"] = minutesduration;
                    // }
                    // if (orderstatus == 4) {
                    //     deliverytime["dispatched"] = minutesduration - (deliverytime.pending + deliverytime.kitchen);
                    // }
                    // if (orderstatus == 8) {
                    //     deliverytime["kitchen"] = minutesduration - deliverytime.pending;
                    // }
                    const data: any = {
                        order_status_code: orderstatus,
                        delivery_status: row.delivery_status,
                        orderData: row
                    }
                    this.props.data.updateOrder(row.order_id, data, this.props.data.history)
                }
            }
        } else {
            const data: any = {
                order_status_code: orderstatus,
                delivery_status: row.delivery_status,
            }
            if (orderstatus == row.order_status_code) {
                toast.error("Please change the status", { position: toast.POSITION.TOP_RIGHT, hideProgressBar: true, autoClose: 3000 });
            } else {
                if (orderstatus == 6) {
                    this.openModal.click();
                } else {
                    this.props.data.updateOrder(row.order_id, data, this.props.data.history)
                }
            }
        }
    }
    isCancelOrderReady = () => {
        const { cancelReason } = this.state;
        return (cancelReason !== "");
    }
    cancelOrder(order_id: any) {
        const data: any = {
            order_status_code: 6,
            cancel_reason: this.state.cancelReason
        }
        if (this.state.otherReason !== "") {
            data.cancel_reason_description = this.state.otherReason;
        }
        this.props.data.updateOrder(order_id, data, this.props.data.history);
        this.closeModal.click();
    }
    handleInputChange(event: { target: { name: any; value: any; }; }) {
        this.setState({
            [event.target.name]: event.target.value
        });
    }
    render() {
        const { data, row } = this.props;
        const { orderstatus } = this.state;
        return (
            <div>
                {(row.order_status_code === 4 || row.order_status_code === 5 || row.order_status_code === 6) ? <span {...(((row.order_status_code === 4 || row.order_status_code === 5) && { className: "badge badge-success text-capitalize p-1" }) || (row.order_status_code === 6 && { className: "badge badge-danger text-capitalize p-1" }))}>{row.order_status_description}</span> :
                    <div>
                        <select
                            name="orderstatus"
                            value={orderstatus}
                            onChange={this.handleInputChange}
                            className="text-capitalize">
                            {data.statusList &&
                                data.statusList.map((status: any, index: any) => (
                                    (row.future_status == 1) ?
                                        (row.future_status == 1 && row.orderStatus == "future" && row.order_status_code == 1) ?
                                            (status.order_status_code != 2 && status.order_status_code != 3 && status.order_status_code != 4 && status.order_status_code != 5 && status.order_status_code != 8) && <option key={index} value={status.order_status_code}>{status.order_status_description}</option>
                                            : (row.future_status == 1 && row.orderStatus == "future" && row.order_status_code == 7) ?
                                                (status.order_status_code != 1 && status.order_status_code != 2 && status.order_status_code != 3 && status.order_status_code != 4 && status.order_status_code != 5 && status.order_status_code != 8) && <option key={index} value={status.order_status_code}>{status.order_status_description}</option>
                                                : (row.future_status == 1 && row.orderStatus == "now" && row.order_status_code == 1) ?
                                                    (status.order_status_code != 3 && status.order_status_code != 4 && status.order_status_code != 5 && status.order_status_code != 8) && <option key={index} value={status.order_status_code}>{status.order_status_description}</option> :
                                                    (row.future_status == 1 && row.orderStatus == "now" && row.order_status_code == 7) ?
                                                        (status.order_status_code != 1 && status.order_status_code != 3 && status.order_status_code != 4 && status.order_status_code != 5 && status.order_status_code != 8) && <option key={index} value={status.order_status_code}>{status.order_status_description}</option>
                                                        : (row.future_status == 1 && row.orderStatus == "now" && row.order_status_code == 2) ?
                                                            (row.delivery_status == "Delivery")
                                                                //if order is delivery,so Delivered option will be show instead of picked
                                                                ?
                                                                (status.order_status_code !== 1 && status.order_status_code != 3 && status.order_status_code != 4 && status.order_status_code != 5 && status.order_status_code != 7) && <option key={index} value={status.order_status_code}>{status.order_status_description}</option>
                                                                //else if order is pick up,so Picked option will be show instead of picked
                                                                :
                                                                (status.order_status_code !== 1 && status.order_status_code != 3 && status.order_status_code !== 4 && status.order_status_code !== 5 && status.order_status_code != 7) && <option key={index} value={status.order_status_code}>{status.order_status_code == 8 ? "Ready To Pickup" : status.order_status_description}</option> :
                                                            (row.future_status == 1 && row.orderStatus == "now" && row.order_status_code == 8) ?
                                                                //then 2.3
                                                                (row.delivery_status == "Delivery")
                                                                    ?
                                                                    (status.order_status_code !== 1 && status.order_status_code !== 2 && status.order_status_code !== 4 && status.order_status_code !== 5 && status.order_status_code != 7) && <option key={index} value={status.order_status_code}>{status.order_status_description}</option>
                                                                    :
                                                                    //else 2.3
                                                                    (status.order_status_code !== 1 && status.order_status_code !== 2 && status.order_status_code !== 3 && status.order_status_code !== 4 && status.order_status_code != 7) && <option key={index} value={status.order_status_code}>{status.order_status_code == 8 ? "Ready To Pickup" : status.order_status_description}</option>
                                                                :
                                                                (row.future_status == 1 && row.orderStatus == "now" && row.order_status_code == 3) &&
                                                                    (row.delivery_status == "Delivery")
                                                                    ?
                                                                    (status.order_status_code !== 1 && status.order_status_code !== 2 && status.order_status_code !== 5 && status.order_status_code !== 6 && status.order_status_code != 7 && status.order_status_code !== 8) && <option key={index} value={status.order_status_code}>{status.order_status_code == 3 ? "Dispatched" : status.order_status_description}</option>
                                                                    :
                                                                    //else 2.3
                                                                    (status.order_status_code !== 1 && status.order_status_code !== 2 && status.order_status_code !== 4 && status.order_status_code !== 6 && status.order_status_code != 7 && status.order_status_code !== 8) && <option key={index} value={status.order_status_code}>{status.order_status_code == 3 ? "Ready For Pickup" : status.order_status_description}</option>
                                        :
                                        row.order_status_code == 1 ?
                                            //if order status is 1 so then
                                            (status.order_status_code != 3 && status.order_status_code != 4 && status.order_status_code != 5 && status.order_status_code != 7 && status.order_status_code != 8) && <option key={index} value={status.order_status_code}>{status.order_status_description}</option>
                                            //if status is not 1 so then
                                            :
                                            //if status is 2
                                            (row.order_status_code == 2) ?
                                                (row.delivery_status == "Delivery")
                                                    //if order is delivery,so Delivered option will be show instead of picked
                                                    ?
                                                    (status.order_status_code !== 1 && status.order_status_code != 3 && status.order_status_code != 4 && status.order_status_code != 5 && status.order_status_code != 7) && <option key={index} value={status.order_status_code}>{status.order_status_description}</option>
                                                    //else if order is pick up,so Picked option will be show instead of picked
                                                    :
                                                    (status.order_status_code !== 1 && status.order_status_code != 3 && status.order_status_code !== 4 && status.order_status_code !== 5 && status.order_status_code != 7) && <option key={index} value={status.order_status_code}>{status.order_status_code == 8 ? "Ready To Pickup" : status.order_status_description}</option>
                                                //Nested else if order status is 3
                                                : (row.order_status_code == 8) ?
                                                    //then 2.3
                                                    (row.delivery_status == "Delivery")
                                                        ?
                                                        (status.order_status_code !== 1 && status.order_status_code !== 2 && status.order_status_code !== 4 && status.order_status_code !== 5 && status.order_status_code != 7) && <option key={index} value={status.order_status_code}>{status.order_status_description}</option>
                                                        :
                                                        //else 2.3
                                                        (status.order_status_code !== 1 && status.order_status_code !== 2 && status.order_status_code !== 3 && status.order_status_code !== 4 && status.order_status_code != 7) && <option key={index} value={status.order_status_code}>{status.order_status_code == 8 ? "Ready To Pickup" : status.order_status_description}</option>
                                                    : (row.order_status_code == 3) &&
                                                        //then 2.3
                                                        (row.delivery_status == "Delivery")
                                                        ?
                                                        (status.order_status_code !== 1 && status.order_status_code !== 2 && status.order_status_code !== 5 && status.order_status_code !== 6 && status.order_status_code != 7 && status.order_status_code !== 8) && <option key={index} value={status.order_status_code}>{status.order_status_description}</option>
                                                        :
                                                        //else 2.3
                                                        (status.order_status_code !== 1 && status.order_status_code !== 2 && status.order_status_code !== 4 && status.order_status_code !== 6 && status.order_status_code != 7 && status.order_status_code !== 8) && <option key={index} value={status.order_status_code}>{status.order_status_code == 3 ? "Ready To Pickup" : status.order_status_description}</option>
                                ))
                            }
                        </select>
                        <br />
                        {data.buttonDisable == false && <span className="badge badge-pill badge-info text-capitalize" style={{ cursor: 'pointer' }} onClick={() => this.handleSave()} >save</span>}
                    </div>
                }
                <div style={{ display: "none" }}>
                    <button ref={el => this.openModal = el} data-toggle="modal" data-target={`#viewcancel${row.order_id}`} className="btn btn-outline-info ml-2"><i className="fa fa-file-pdf-o"></i></button>
                </div>
                <div id={`viewcancel${row.order_id}`} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" className="modal fade text-left">
                    <div role="document" className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 id="exampleModalLabel" className="modal-title">Cancel Order</h4>
                                <button type="button" data-dismiss="modal" aria-label="Close" className="close"><span aria-hidden="true">×</span></button>
                            </div>
                            <div className="modal-body">
                                <div className="row">
                                    <div className="col-12">
                                        <h6>Are you sure,you want to cancel the order?</h6>
                                    </div>
                                    <div className="col-12">
                                        <div className="form-group">
                                            <select
                                                name="cancelReason"
                                                onChange={this.handleInputChange}
                                                className="form-control mt-2">
                                                <option value="">Select Reason</option>
                                                {data.cancelReasons &&
                                                    data.cancelReasons.map((reason: any, index: any) => (
                                                        <option key={index} value={reason.id}>{reason.reason}</option>
                                                    ))
                                                }
                                            </select>

                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="form-group">
                                            <input
                                                id="otherReason"
                                                style={{ fontSize: 15 }}
                                                type="text"
                                                name="otherReason"
                                                placeholder="Description"
                                                className="input-material"
                                                onChange={this.handleInputChange}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group d-flex justify-content-end mt-4">
                                    <button className='btn btn-primary' disabled={!this.isCancelOrderReady()} onClick={() => this.cancelOrder(row.order_id)}>Save</button>
                                </div>
                            </div>
                            <div style={{ display: "none" }}>
                                <button ref={el => this.closeModal = el} type="button" data-dismiss="modal" className="btn btn-info" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
class ActionFormatter extends Component<{ row: any }, { selectedOrderId: any, checkTimer: any }> {
    constructor(readonly props: any) {
        super(props);
        this.state = {
            selectedOrderId: "",
            checkTimer: false
        }
    }
    showorderItems = (id: any) => {
        this.props.data.stopTimer();
        if (this.props.data.timerStatus == true) {
            this.setState({ checkTimer: true })
        }

        let storeId: any = "";
        let stateId: any = "";
        this.props.data.showorderItems(id, this.props.data.orders);
        storeId = this.props.data.orders.find((obj: any) => {
            return obj.order_id == id;
        }).store_id;
        // if (storeId && storeId !== "") {
        //     stateId = this.props.data.stores && this.props.data.stores.find((obj: any) => {
        //         return obj.store_id == storeId;
        //     }).state_id;
        // }
        // if (stateId !== "") {
        //     tax = this.props.data.states.find((obj: any) => {
        //         return obj.state_id == stateId;
        //     }).tax_percent;
        // }
        this.setState({ selectedOrderId: id });
    }
    handleTimer = () => {
        let tokendata: any = jwt.decode(sessionStorage.token);
        let roleId = tokendata.role_id;
        if (this.state.checkTimer == true) {
            this.props.data.luanchTimer(roleId == 7 ? 0 : tokendata.brand_id,this.props.data.history);
        }
    }
    render() {
        const { row, data } = this.props;
        return (
            <div>
                {/* <button title="View Order Items" data-toggle="modal" data-target={`#viewitems${row.order_id}`} className="btn btn-outline-info" onClick={() => this.showorderItems(row.order_id)}><i className="fa fa-list"></i></button> */}
                <button title="View Invoice" data-toggle="modal" data-target={`#viewinvoice${row.order_id}`} className="btn btn-outline-info ml-2" onClick={() => this.showorderItems(row.order_id)}><i className="fa fa-file-pdf-o"></i></button>
                {/* {!row.aggregator_orderId && <button title="View Invoice" data-toggle="modal" data-target={`#viewinvoice${row.order_id}`} className="btn btn-outline-info ml-2" onClick={() => this.showorderItems(row.order_id)}><i className="fa fa-file-pdf-o"></i></button>} */}
                {/* {(row.fbr_invoice_number != "") ?
                    <button title="View Invoice" data-toggle="modal" data-target={`#viewinvoice${row.order_id}`} className="btn btn-outline-info ml-2" onClick={() => this.showorderItems(row.order_id)}><i className="fa fa-file-pdf-o"></i></button>
                    : <button title="View Invoice" data-toggle="modal" className="btn btn-outline-danger ml-2"><i className="fa fa-file-o"></i></button>
                } */}
                {/* <!-- Modal--> */}
                <div id={`viewitems${row.order_id}`} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" className="modal fade text-left">
                    <div role="document" className="modal-dialog modal-lg">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 id="exampleModalLabel" className="modal-title">Order Items</h4>
                                <button type="button" data-dismiss="modal" aria-label="Close" className="close"><span aria-hidden="true">×</span></button>
                            </div>
                            <div className="row" style={{ margin: '0px', padding: '15px' }}>
                                <div className="col-6 to">
                                    <h3>Customer Information</h3>
                                    <span className="d-block font-weight-light">Customer Name: </span>
                                    <span className="d-block font-weight-light">Phone Number: </span>
                                    <span className="d-block font-weight-light">Email Address: </span>
                                    {data.Items.length > 0 ? data.Items[0].address_line_1 && <span className="d-block font-weight-light">Address 1: </span> : ""}
                                </div>
                                <div className="col-6 to">
                                    <h3><br /></h3>
                                    <span className="d-block font-weight-light">{data.Items.length > 0 && data.Items[0].first_name} {data.Items.length > 0 && data.Items[0].last_name}</span>
                                    <span className="d-block font-weight-light">{data.Items.length > 0 && data.Items[0].phone_number}</span>
                                    <span className="d-block font-weight-light">{data.Items.length > 0 && data.Items[0].email_address}</span>
                                    <span className="d-block font-weight-light">{data.Items.length > 0 && data.Items[0].address_line_1}</span>
                                </div>
                            </div>
                            <div className="modal-body">
                                <div className="row">
                                    <div className="col-12">
                                        <div className="form-group">
                                            {(data.Items.length > 0) ?
                                                <BootstrapTable data={data.Items} hover>
                                                    <TableHeaderColumn dataField="order_item_id" width="50" columnTitle isKey>#</TableHeaderColumn>
                                                    <TableHeaderColumn dataField="order_item_quantity" width="50" columnTitle>Quantity</TableHeaderColumn>
                                                    <TableHeaderColumn dataField="item_name" width="150" columnTitle>Item Name</TableHeaderColumn>
                                                    <TableHeaderColumn dataField="combo_name" width="150" columnTitle>Combo Name</TableHeaderColumn>
                                                    <TableHeaderColumn dataField="order_item_grossprice" width="100" columnTitle>Amount</TableHeaderColumn>
                                                </BootstrapTable> :
                                                <p className="text-center">Order Items Not Found</p>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" data-dismiss="modal" className="btn btn-danger">Close</button>
                            </div>
                        </div>
                    </div>
                </div>
                {/*Invoice Modal */}
                <div id={`viewinvoice${row.order_id}`} data-backdrop='static' role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" className="modal fade text-left">
                    <div role="document" className="modal-dialog modal-lg" style={{ width: '500px' }}>
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 id="exampleModalLabel" className="modal-title">Invoice</h4>
                                <button type="button" onClick={() => this.handleTimer()} data-dismiss="modal" aria-label="Close" className="close"><span aria-hidden="true">×</span></button>
                            </div>
                            <div className="modal-body">
                                <Invoice data={data} orderId={this.state.selectedOrderId} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
function actionFormatter(cell: any, row: any, props: any) {
    return (
        <ActionFormatter row={row} data={props} />
    );
}
const selectDriverEditor = (cell: any, row: any, props: any) => {
    return (
        row.delivery_status == "Delivery" ?
            <DriverEditor row={row} data={props} /> : ""
    )
}
const selectStatusEditor = (cell: any, row: any, props: any) => {
    return (
        <StatusEditor row={row} data={props} />
    )
}
class Orders extends Component<OrderProps, { startDate: any, endDate: any, validTimeFlag: any, [x: number]: any, days: any, orderId: any,brand_id:any }> {
    closeModal: any;
    constructor(readonly props: any) {
        super(props);
        this.state = {
            days: 0,
            startDate: "",
            endDate: "",
            validTimeFlag: "",
            orderId: "",
            brand_id: {
                value: 0,
                label: "All"
            }
        }
        this.rowClassNameFormat = this.rowClassNameFormat.bind(this);
        this.handleDaysFilter = this.handleDaysFilter.bind(this);
        this.handleTimer = this.handleTimer.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleValidTime = this.handleValidTime.bind(this);
        this.handleInput = this.handleInput.bind(this);
    }
    rowClassNameFormat(row: any, rowIdx: any) {
        // row is whole row object
        // rowIdx is index of row
        if (row.aggregator_orderId) {
            return 'td-column-function-aggregator-example';
        } else {
            return row.isNew == true ? 'td-column-function-even-example' : row.future_status == 1 ? 'td-column-function-odd-example' : "";
        }
    }
    componentDidMount() {
        this.props.brandsList()
        document.title = "SimplexCMS | Orders"
        if (sessionStorage.token) {
            jwt.verify(sessionStorage.token, secretKey, (err: any, decoded: any) => {
                if (err) {
                    this.props.logoutUser();
                } else {
                    let obj = { days: this.state.days,brand_id:decoded.role_id == 7 ? 0 : decoded.brand_id };
                    this.props.ordersList(obj);
                    this.props.orderStatusList();
                    this.props.statesList(decoded.role_id == 7 ? 0 : decoded.brand_id);
                    this.props.storesList();
                    this.props.cancelOrdersReasons();
                    this.props.luanchTimer(decoded.role_id == 7 ? 0 : decoded.brand_id,this.props.history);
                }
            });
        }
    }
    handleDaysFilter(event: { target: { name: any; value: any; }; }) {
        let obj = { days: event.target.value };
        this.props.ordersList(obj);
    }
    handleInput(event: { target: { name: any; value: any; }; }) {
        this.setState({ orderId: event.target.value })
    };
    FilterbyStatus(status_code: any) {
        let { orders } = this.props;
        let filteredArr: any = [];
        if (status_code === 4) {
            filteredArr = orders.filter((order: any) => order.order_status_code == status_code || order.order_status_code == 5)
        } else if (status_code === 3) {
            filteredArr = orders.filter((order: any) => order.order_status_code == status_code || order.order_status_code == 8)
        }
        else {
            filteredArr = orders.filter((order: any) => order.order_status_code == status_code)
        }
        this.props.filterOrdersList(filteredArr)
    }
    handleValidTime(event: { target: { name: any; value: any; }; }) {
        let futureDatetime = new Date(event.target.value)
        let validMinDateTime = new Date();
        if (futureDatetime <= validMinDateTime) {
            this.setState({ [event.target.name]: event.target.value, validTimeFlag: true });
        } else {
            this.setState({ validTimeFlag: false })
            toast.error("Invalid date and time selected");
        }
    }
    isOrderDateReady = () => {
        let { startDate, endDate, validTimeFlag } = this.state;
        return (startDate !== "" && endDate !== "" && validTimeFlag == true);
    }
    handleSubmit() {
        let { startDate, endDate } = this.state;
        let startDateUtc = moment(startDate).utc(false).format('YYYY-MM-DD HH:mm')
        let endDateUtc = moment(endDate).utc(false).format('YYYY-MM-DD HH:mm')
        let data: any = {
            start: startDateUtc,
            end: endDateUtc,
            brand_id:this.state.brand_id.value
        }
        // this.setState({ startDate: "", endDate: "" })
        this.props.ordersList(data, true);
    }
    handleSearchOrder = (event: any) => {
        event.preventDefault();
        let { orderId } = this.state;
        this.props.searchOrder(orderId)
    }
    handleTimer() {
        let tokendata: any = jwt.decode(sessionStorage.token);
        let roleId = tokendata.role_id;
        if (this.props.timerStatus == false) {
            this.props.luanchTimer(roleId == 7 ? 0 : tokendata.brand_id,0);
        } else if (this.props.timerStatus == true) {
            this.props.stopTimer();
        }
        this.closeModal.click();
    }
    handleBrandsInputChange = (e: any, index: any) => {
        this.setState({brand_id:{label:e.label,value:e.value}});
        let obj = { days: this.state.days,brand_id:e.value };
        this.props.ordersList(obj);
        this.props.statesList(e.value);
        this.props.storesList(e.value);
    }
    render() {
        let roleId
        if (sessionStorage.token) {
            jwt.verify(sessionStorage.token, secretKey, (err: any, decoded: any) => {
                if (err) {
                    this.props.logoutUser();
                }else {
                    roleId = decoded.role_id
                }
            });
        } else {
            return <Redirect to="/" />
        }
        let maxDate = moment().format('YYYY-MM-DD[T]HH:mm:ss.SSS')
        let { orders, filteredOrders, emptyFilteredOrder, avg, dayVal, kitchenCount, recievedOrderCount, readyOrderCount, completedOrderCount } = this.props;
        const options: any = {
            sizePerPage: 10, // which size per page you want to locate as default
            page: 1,
            pageStartIndex: 1, // where to start counting the pages
            paginationSize: 3,  // the pagination bar size.
            hideSizePerPage: true, //You can hide the dropdown for sizePerPage
            noDataText: 'Orders Not Found',
        };
        return (
            <div className="page">
                <Topbar />
                <div className="page-content d-flex align-items-stretch">
                    <Sidebar />
                    <div className="content-inner">
                        {/* <!-- Page Header--> */}
                        <header className="page-header py-0">
                            <div className="container-fluid">
                                {/* <div className="d-flex bd-highlight py-3">
                                    <div className="mr-auto bd-highlight"><h4 className="mt-2">Orders Management</h4></div>
                                    <div className="bd-highlight"><button title={this.props.timerStatus == false ? "Enable Refresh" : "Disable Refresh"} data-toggle="modal" data-target={`#enableTimer`} className={this.props.timerStatus == false ? "btn btn-outline-success mr-2" : "btn btn-outline-danger mr-2"}><i className={"fa fa-refresh"}></i></button></div>
                                    <div className="p-2 bd-highlight">
                                        <select name="days" id="days" onChange={this.handleDaysFilter} style={{ padding: '0 15px' }}>
                                            <option value="0" {...dayVal.days == 0 && { selected: true }}>Today</option>
                                            <option value="1" {...dayVal.days == 1 && { selected: true }}>Yesterday</option>
                                            <option value="7" {...dayVal.days == 7 && { selected: true }}>7 Days</option>
                                            <option value="15" {...dayVal.days == 15 && { selected: true }}>15 Days</option>
                                            <option value="30" {...dayVal.days == 30 && { selected: true }}>30 Days</option>
                                            <option value="60" {...dayVal.days == 60 && { selected: true }}>60 Days</option>
                                            <option value="90" {...dayVal.days == 90 && { selected: true }}>90 Days</option>
                                        </select>
                                    </div> */}
                                <div className="row py-2">
                                    <div className="col-lg-6 col-md-6 col-12" style={{ display: "flex", flexDirection: "row", alignItems: 'center' }}>
                                        <h4 className="mt-2">Orders Management<button title={this.props.timerStatus == false ? "Enable Refresh" : "Disable Refresh"} data-toggle="modal" data-target={`#enableTimer`} className={this.props.timerStatus == false ? "btn btn-outline-success ml-2" : "btn btn-outline-danger ml-2"}><i className={"fa fa-refresh"}></i></button></h4>
                                        <div className="ml-4 ">
                                            {
                                                this.props.timerStatus == true &&
                                                !this.props.updateTimer == true &&
                                                <ReactTimer />
                                            }
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-md-6 col-12">
                                        <div className="row">
                                            <div className="col-lg-6 col-md-6 col-12">
                                                <div className="form-group">
                                                    <input
                                                        id="start"
                                                        type="datetime-local"
                                                        name="startDate"
                                                        max={maxDate}
                                                        value={this.state.startDate}
                                                        className="input-material"
                                                        onChange={this.handleValidTime}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-md-6 col-12">
                                                <div className="form-group mb-1">
                                                    <input
                                                        id="end"
                                                        type="datetime-local"
                                                        name="endDate"
                                                        max={maxDate}
                                                        value={this.state.endDate}
                                                        className="input-material"
                                                        onChange={this.handleValidTime}
                                                    />
                                                </div>
                                                <button disabled={!this.isOrderDateReady()} className="badge badge-pill btn-info text-capitalize" style={{ cursor: 'pointer', float: 'right' }} onClick={() => { this.handleSubmit() }}>get</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </header>
                        {/* Counters Section */}
                        <section className="dashboard-counts py-2">
                            <div className="container-fluid">
                                <div className="row bg-white has-shadow p-0">
                                    {/* <!-- Item --> */}
                                    <div className="col-xl-2 col-sm-6">
                                        <div onClick={() => { this.FilterbyStatus(1) }} className="item d-flex align-items-center" style={{ border: "none", cursor: 'pointer' }}>
                                            <div className="icon bg-green"><i className="fa fa-money"></i></div>
                                            <div className="title"><span>Pending</span><br />
                                                <strong className="text-bold text-dark">{recievedOrderCount}</strong>
                                                <div className="progress">
                                                    <div role="progressbar" style={{ width: `${recievedOrderCount / 100}%`, height: "4px" }} className="progress-bar bg-green"></div>  {/*ariaValuenow="40" ariaValuemin="0" ariaValuemax="100"*/}
                                                </div>
                                            </div>
                                            {/* <div className="number"><strong>{recievedOrderCount}</strong></div> */}
                                        </div>
                                    </div>

                                    {/* <!-- Item --> */}
                                    <div className="col-xl-2 col-sm-6">
                                        <div onClick={() => { this.FilterbyStatus(2) }} className="item d-flex align-items-center" style={{ border: "none", cursor: 'pointer' }}>
                                            <div className="icon bg-blue"><i className="fa fa-cutlery"></i></div>
                                            <div className="title"><span>Kitchen</span> <br />
                                                <strong className="text-bold text-dark">{kitchenCount}</strong>
                                                <div className="progress">
                                                    <div role="progressbar" style={{ width: `${kitchenCount / 100}%`, height: "4px" }} className="progress-bar bg-blue"></div>  {/*ariaValuenow="25" ariaValuemin="0" ariaValuemax="100"*/}
                                                </div>
                                            </div>
                                            {/* <div className="number"><strong>{kitchenCount}</strong></div> */}
                                        </div>
                                    </div>
                                    {/* <!-- Item --> */}
                                    <div className="col-xl-2 col-sm-6">
                                        <div onClick={() => { this.FilterbyStatus(3) }} className="item d-flex align-items-center" style={{ border: "none", cursor: 'pointer' }}>
                                            <div className="icon bg-orange"><i className="fa fa-truck"></i></div>
                                            <div className="title"><span>Ready</span><br />
                                                <strong className="text-bold text-dark">{readyOrderCount}</strong>
                                                <div className="progress">
                                                    <div role="progressbar" style={{ width: `${readyOrderCount / 100}%`, height: "4px" }} className="progress-bar bg-orange"></div>  {/*ariaValuenow="40" ariaValuemin="0" ariaValuemax="100"*/}
                                                </div>
                                            </div>
                                            {/* <div className="number"><strong>{readyOrderCount}</strong></div> */}
                                        </div>
                                    </div>
                                    {/* <!-- Item --> */}
                                    <div className="col-xl-3 col-sm-6" style={{ maxWidth: "23%" }}>
                                        <div onClick={() => { this.FilterbyStatus(4) }} className="item d-flex align-items-center" style={{ border: "none", cursor: 'pointer' }}>
                                            <div className="icon bg-violet"><i className="fa fa-handshake-o"></i></div>
                                            <div className="title"><span>Completed</span> <br />
                                                <strong className="text-bold text-dark">{completedOrderCount}</strong>
                                                <div className="progress">
                                                    <div role="progressbar" style={{ width: `${completedOrderCount / 100}%`, height: "4px" }} className="progress-bar bg-violet"></div> {/*ariaValuenow="50" ariaValuemin="0" ariaValuemax="100"*/}
                                                </div>
                                            </div>
                                            {/* <div><strong>{completedOrderCount}</strong></div> */}
                                        </div>
                                    </div>
                                    {/* Item */}
                                    <div className="col-xl-3 col-sm-6">
                                        <div className="item d-flex align-items-center" style={{ border: "none" }}>
                                            <div className="icon bg-red"><i className="fa fa-clock-o"></i></div>
                                            <div className="title"><span>Avg Delivery<br />Time <strong className="text-bold text-dark">{avg}</strong></span>
                                                <div className="progress">
                                                    <div role="progressbar" style={{ width: `${avg / 100}%`, height: "4px" }} className="progress-bar bg-red"></div> {/*ariaValuenow="50" ariaValuemin="0" ariaValuemax="100"*/}
                                                </div>
                                            </div>
                                            {/* <div><strong>{avg}</strong></div> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        {/* End Counters Section */}
                        <section className="tables py-2">
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col">
                                        <div className="card">
                                            <div className="card-body">
                                                <div className="row">
                                                    <div className="col-lg-10 col-md-8 col-12">
                                                        <div className="form-group">
                                                            <input id="orderId" type="number" value={this.state.orderId} name="orderId" required placeholder="Search by order id" className="input-material" onChange={this.handleInput} />
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-2 col-md-4 col-12 float-right mt-1">
                                                        <div className="form-group">
                                                            <button onClick={this.handleSearchOrder} disabled={this.state.orderId == ""} className="btn btn-primary btn-block">Search Order</button>
                                                        </div>
                                                    </div>
                                                </div>
                                                {this.props.applyLoaderonDate ?
                                                    <div className="d-flex justify-content-center">
                                                        <Loader
                                                            type="TailSpin"
                                                            color="#007bff"
                                                            height={50}
                                                            width={50}
                                                        />
                                                    </div> :
                                                    <>
                                                        {roleId == 7 &&
                                                            <>
                                                                <label
                                                                    className="col-md-3 col-sm-3 form-control-label font-weight-bold"
                                                                    style={{ paddingTop: "1em" }}
                                                                >
                                                                    Select Brand
                                                                    <span className="text-danger">*</span>
                                                                </label>
                                                                <div className="col-12">
                                                                    <Select
                                                                        name="brand_id"
                                                                        options={this.props.brands}
                                                                        value={this.state.brand_id}
                                                                        className="text-capitalize select mt-2"
                                                                        classNamePrefix="select"
                                                                        onChange={(e, i) => this.handleBrandsInputChange(e, i)}
                                                                    />
                                                                </div></>}
                                                        <br />
                                                    <BootstrapTable version='4' data={filteredOrders.length > 0 || emptyFilteredOrder ? filteredOrders : orders} search={true} trClassName={this.rowClassNameFormat} pagination={orders.length > 10 && true} options={options} exportCSV={true} csvFileName='orders.csv' hover>
                                                        <TableHeaderColumn thStyle={{ fontSize: 10, fontWeight: 'bold' }} width='80' tdStyle={{ fontSize: 10 }} dataField='order_id' csvHeader='Order Id' dataSort={true} isKey>Order Id</TableHeaderColumn>
                                                        <TableHeaderColumn thStyle={{ fontSize: 10, fontWeight: 'bold' }} width='80' tdStyle={{ fontSize: 10 }} dataField='action' dataFormat={actionFormatter} formatExtraData={this.props} export={false}>Details</TableHeaderColumn>
                                                        <TableHeaderColumn thStyle={{ fontSize: 10, fontWeight: 'bold', whiteSpace: 'normal' }} width='120' tdStyle={{ fontSize: 10, whiteSpace: 'normal' }} dataField='date_created' csvHeader='Order Recieved' dataFormat={dateFormatter} columnTitle>Order Recieved</TableHeaderColumn>
                                                        <TableHeaderColumn thStyle={{ fontSize: 10, fontWeight: 'bold', whiteSpace: 'normal' }} width='80' tdStyle={{ fontSize: 10, whiteSpace: 'normal' }} dataField='store_name' csvHeader='Store' columnTitle>Store</TableHeaderColumn>
                                                        <TableHeaderColumn thStyle={{ fontSize: 10, fontWeight: 'bold', whiteSpace: 'normal' }} width='80' tdStyle={{ fontSize: 10, whiteSpace: 'normal' }} dataField='delivery_status' csvHeader='Order Type' columnTitle>Order Type</TableHeaderColumn>
                                                        <TableHeaderColumn thStyle={{ fontSize: 10, fontWeight: 'bold', whiteSpace: 'normal' }} width='80' tdStyle={{ fontSize: 10, whiteSpace: 'normal', textTransform: 'capitalize' }} dataField='order_channel' columnTitle>Order Channel</TableHeaderColumn>
                                                        <TableHeaderColumn thStyle={{ fontSize: 10, fontWeight: 'bold', whiteSpace: 'normal' }} width='80' tdStyle={{ fontSize: 10, whiteSpace: 'normal' }} dataField='delivery_time' dataFormat={calculateDeliveryTime} csvHeader='Delivery time'>Delivery Time</TableHeaderColumn>
                                                        <TableHeaderColumn thStyle={{ fontSize: 10, fontWeight: 'bold', whiteSpace: 'normal' }} width='150' tdStyle={{ fontSize: 10 }} dataField='order_status_description' dataFormat={selectStatusEditor} formatExtraData={this.props} >Status</TableHeaderColumn>
                                                        <TableHeaderColumn thStyle={{ fontSize: 10, fontWeight: 'bold', whiteSpace: 'normal' }} width='180' tdStyle={{ fontSize: 10 }} dataField='user_name' dataFormat={selectDriverEditor} formatExtraData={this.props} csvHeader='Delivery Driver'>Delivery Driver</TableHeaderColumn>
                                                        <TableHeaderColumn thStyle={{ fontSize: 10, fontWeight: 'bold', whiteSpace: 'normal' }} width='80' tdStyle={{ fontSize: 10, whiteSpace: 'normal' }} dataField='login_name' csvHeader='Customer Name' columnTitle>Name</TableHeaderColumn>
                                                        <TableHeaderColumn thStyle={{ fontSize: 10, fontWeight: 'bold', whiteSpace: 'normal' }} width='100' tdStyle={{ fontSize: 10, whiteSpace: 'normal' }} dataField='phone_number' csvHeader='Phone' columnTitle>Phone</TableHeaderColumn>
                                                        <TableHeaderColumn thStyle={{ fontSize: 10, fontWeight: 'bold', whiteSpace: 'normal' }} width='150' tdStyle={{ fontSize: 10, whiteSpace: 'normal' }} dataField='delivery_address' csvHeader='Address' columnTitle>Address</TableHeaderColumn>
                                                        <TableHeaderColumn thStyle={{ fontSize: 10, fontWeight: 'bold', whiteSpace: 'normal' }} width='80' tdStyle={{ fontSize: 10, whiteSpace: 'normal' }} dataField='order_grossprice' dataFormat={priceFormatter} csvHeader='Price'>Order Price</TableHeaderColumn>
                                                        <TableHeaderColumn thStyle={{ fontSize: 10, fontWeight: 'bold', whiteSpace: 'normal' }} width='80' tdStyle={{ fontSize: 10, whiteSpace: 'normal' }} dataField='discount' dataFormat={priceFormatter} csvHeader='Discount'>Discount</TableHeaderColumn>
                                                        <TableHeaderColumn thStyle={{ fontSize: 10, fontWeight: 'bold', whiteSpace: 'normal' }} width='80' tdStyle={{ fontSize: 10, whiteSpace: 'normal' }} dataField='payment_method' csvHeader='Payment Method' columnTitle>Payment Method</TableHeaderColumn>
                                                        <TableHeaderColumn thStyle={{ fontSize: 10, fontWeight: 'bold', whiteSpace: 'normal' }} width='120' tdStyle={{ fontSize: 10, whiteSpace: 'normal' }} dataField='reason' csvHeader='Cancel Reason' columnTitle>Cancel Reason</TableHeaderColumn>
                                                        <TableHeaderColumn thStyle={{ fontSize: 10, fontWeight: 'bold', whiteSpace: 'normal' }} width='180' tdStyle={{ fontSize: 10, whiteSpace: 'normal' }} dataField='cancel_reason_description' csvHeader='Cancel Reason Description' columnTitle>Cancel Reason Description</TableHeaderColumn>
                                                        {/* <TableHeaderColumn dataField='feedback' csvHeader='Feedback' width='150' columnTitle>Feedback</TableHeaderColumn>
                                                    <TableHeaderColumn dataField='ratings' width='200' csvHeader='Rating' dataFormat={ratingsFormatter} columnTitle>Rating</TableHeaderColumn> */}
                                                        {/* <TableHeaderColumn thStyle={{ fontSize: 10, fontWeight: 'bold', whiteSpace: 'normal' }} width='100' tdStyle={{ fontSize: 10, whiteSpace: 'normal' }} dataField='fbr_invoice_number' csvHeader='FBR Invoice Number' columnTitle>FBR Invoice Number</TableHeaderColumn> */}
                                                        <TableHeaderColumn thStyle={{ fontSize: 10, fontWeight: 'bold', whiteSpace: 'normal' }} width='180' tdStyle={{ fontSize: 10, whiteSpace: 'normal' }} dataField='external_orderId' csvHeader='GrubCenter OrderId' columnTitle>GrubCenter OrderId</TableHeaderColumn>
                                                        {/* <TableHeaderColumn thStyle={{ fontSize: 10, fontWeight: 'bold', whiteSpace: 'normal' }} width='100' tdStyle={{ fontSize: 10, whiteSpace: 'normal' }} dataField='aggregator_orderId' csvHeader='AggregatorOrderId' columnTitle>Aggregator OrderId</TableHeaderColumn> */}
                                                    </BootstrapTable>
                                                    </>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <Footer />
                    </div>
                </div>
                <div>
                    <div id="enableTimer" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" className="modal fade text-left">
                        <div role="document" className="modal-dialog">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h4 id="exampleModalLabel" className="modal-title">Auto Refresh</h4>
                                    <button type="button" data-dismiss="modal" aria-label="Close" className="close"><span aria-hidden="true">×</span></button>
                                </div>
                                <div className="modal-body">
                                    <p>Are you sure,you want to {this.props.timerStatus == false ? "enable" : "disable"} auto refresh?</p>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" ref={el => this.closeModal = el} data-dismiss="modal" className="btn btn-secondary">Close</button>
                                    <button onClick={this.handleTimer} className="btn btn-primary">Confirm</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
};

function priceFormatter(cell: any, row: any) {
    if (row.aggregator_orderId) {
        return `${currency} ${cell}`;
    } else {
        return `${currency} ${Math.round(cell)}`;
    }
}
function calculateDeliveryTime(cell: any, row: any) {
    // let DeliveryTime: any = row.delivery_time_json && JSON.parse(row.delivery_time_json);
    // let ActualDeliveryTime = row.delivery_time_json ? DeliveryTime.pending + DeliveryTime.kitchen + DeliveryTime.ready + DeliveryTime.dispatched : 0
    return (
        <div>
            <span {...((row.order_status_code == 4 && cell > 0) && { title: `${cell} min` })}> {(row.order_status_code == 4 && cell > 0) && `${cell} min`} </span>
        </div>
    )
}
function dateFormatter(cell: any) {
    var DateCreated: any = moment(cell).local().format('YYYY-MM-DD HH:mm').split(' ');
    DateCreated = DateCreated[1] + " , " + DateCreated[0];
    return (
        <div>
            <span {...((cell !== "" && cell !== null) && { title: DateCreated })}> {(cell !== "" && cell !== null) && DateCreated} </span>
        </div>
    )
}
function ratingsFormatter(cell: any, row: any) {
    return (
        <BeautyStars
            value={cell}
            size="16"
            inactiveColor="#b7b7b7"
        />
    )
}

const mapStateToProps = (state: any) => {
    return {
        orders: state.order.data,
        filteredOrders: state.order.filteredOrders,
        emptyFilteredOrder: state.order.emptyFilterOrder,
        Items: state.order.orderItems,
        orderDetail: state.order.order,
        statusList: state.order.orderStatus,
        drivers: state.order.deliveryBoys,
        states: state.setting.states,
        stores: state.store.data,
        buttonDisable: state.order.buttonDisable,
        avg: state.order.avg,
        cancelReasons: state.order.cancelReasons,
        kitchenCount: state.order.kitchenCount,
        recievedOrderCount: state.order.recievedOrderCount,
        readyOrderCount: state.order.readyOrderCount,
        completedOrderCount: state.order.completedOrderCount,
        dayVal: state.order.dayVal,
        timerStatus: state.order.timerStatus,
        applyLoaderonDate: state.order.isLoading,
        updateTimer: state.order.isTimerUpdate,
        brands: state.brand.brands
    }
}
const mapDispatchToProps = (dispatch: any) => {
    return {
        logoutUser: function () {
            dispatch(logoutUser());
        },
        ordersList: (obj: any, datetime: any) => {
            dispatch(ordersList(obj, datetime))
        },
        cancelOrdersReasons: () => {
            dispatch(cancelOrdersReasons())
        },
        showorderItems: function (id: any, orders: any) {
            dispatch(showorderItems(id, orders))
        },
        orderStatusList: function () {
            dispatch(orderStatusList())
        },
        updateOrder: function (id: any, data: any, history: any) {
            dispatch(updateOrder(id, data, history))
        },
        updatefutureOrder: function (id: any, data: any, history: any) {
            dispatch(updatefutureOrder(id, data, history))
        },
        statesList: function (brand_id:any) {
            dispatch(statesList(brand_id))
        },
        storesList: function () {
            dispatch(storesList())
        },
        luanchTimer: (brand_id:number,history: any,) => {
            dispatch(luanchTimer(brand_id,history,))
        },
        stopTimer: () => {
            dispatch(stopTimer())
        },
        filterOrdersList: (filteredArr: any) => {
            dispatch(filterOrdersList(filteredArr))
        },
        searchOrder: (orderId: any) => {
            dispatch(searchOrder(orderId))
        },
        brandsList: function () {
            dispatch(brandsList("GET_BY_BRAND"))
        },
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(Orders);