export const MENUS_LIST = "MENUS_LIST"
export const ADD_MENU = "ADD_MENU"
export const EDIT_MENU = "EDIT_MENU"
export const GET_MENU = "GET_MENU"
export const GROUPS_LIST = "GROUPS_LIST"
export const GROUP_OPTIONS="GROUP_OPTIONS"
export const ADD_GROUP = "ADD_GROUP"
export const EDIT_GROUP = "EDIT_GROUP"
export const GET_GROUP = "GET_GROUP"
export const ITEMS_LIST = "ITEMS_LIST"
export const ACTIVE_ITEMS_OPTIONS="ACTIVE_ITEMS_OPTIONS"
export const ADD_ITEMS = "ADD_ITEMS"
export const EDIT_ITEM = "EDIT_ITEM"
export const GET_ITEM = "GET_ITEM"
export const COMBOS_LIST = "COMBOS_LIST"
export const ADD_COMBO = "ADD_COMBO"
export const EDIT_COMBO = "EDIT_COMBO"
export const GET_COMBO = "GET_COMBO"
export const COMBO_OPTIONS = "COMBO_OPTIONS"
export const MOD_GROUP_LIST = "MOD_GROUP_LIST"
export const ADD_MOD_GROUP = "ADD_MOD_GROUP"
export const EDIT_MOD_GROUP = "EDIT_MOD_GROUP"
export const GET_MOD_GROUP = "GET_MOD_GROUP"
export const MOD_LIST = "MOD_LIST"
export const ADD_MOD = "ADD_MOD"
export const EDIT_MOD = "EDIT_MOD"
export const GET_MOD = "GET_MOD"
export const ING_LIST = "ING_LIST"
export const GET_ING = "GET_ING"
export const ING_CAT_LIST = "ING_CAT_LIST"
export const ING_UOM_LIST = "ING_UOM_LIST"
export const MEALS_LIST = "MEALS_LIST"
export const GET_MEAL = "GET_MEAL"
export const STORES_OPTIONS="STORES_OPTIONS"
export const CHECK_DUP_POS="CHECK_DUP_POS"
export const CHECK_DUP_ERP="CHECK_DUP_ERP"
export const COMBO_CHOICE_ITEMS="COMBO_CHOICE_ITEMS"
export const GET_BRANCH_MENU="GET_BRANCH_MENU"
export const STATUS_CHANGE_REASONS="STATUS_CHANGE_REASONS"
export const SEARCH_GROUPS="SEARCH_GROUPS"
export const SEARCH_MENU_ITEM="SEARCH_MENU_ITEM"
export const SEARCH_MODIFIERS="SEARCH_MODIFIERS"
export const SEARCH_COMBOS="SEARCH_COMBOS"
export const ACTIVE_MENUS_LIST="ACTIVE_MENUS_LIST"
export const ACTIVE_GROUPS_LIST="ACTIVE_GROUPS_LIST"
export const ITEMS_FORACTIVE_MENU="ITEMS_FORACTIVE_MENU"