import { BIRTHDAY_OFFERS, GET_BIRTHDAY_OFFER, GET_LOYALTY_COUNT, GET_LOYALTY_TIER, LOYALTY_TIERS } from '../actions/loyaltyType';
import { PAYMENTS_LIST } from '../actions/paymentType'
const initialState: any = {
    loyaltyTiers: [] || "",
    birthdayOffers: [] || "",
    loyaltyData: {},
    birthdayOfferData: {},
    loyaltyCountData: {}
}

const LoyaltyReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case LOYALTY_TIERS: return {
            ...state,
            loyaltyTiers: action.payload
        }
        case GET_LOYALTY_TIER: return {
            ...state,
            loyaltyData: action.payload
        }
        case BIRTHDAY_OFFERS: return {
            ...state,
            birthdayOffers: action.payload
        }
        case GET_BIRTHDAY_OFFER: return {
            ...state,
            birthdayOfferData: action.payload
        }
        case GET_LOYALTY_COUNT: return {
            ...state,
            loyaltyCountData: action.payload
        }
        default: return state;
    }
}
export default LoyaltyReducer;