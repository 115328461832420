export const DAILY_SALES_REPORTS = "DAILY_SALES_REPORTS";
export const TOTAL_SALES_REPORTS = "TOTAL_SALES_REPORTS"
export const CUSTOMERS_REPORTS = "CUSTOMERS_REPORTS";
export const PMIX_REPORTS = "PMIX_REPORTS";
export const SALES_MIX_REPORTS = "SALES_MIX_REPORTS";
export const CHANNEL_MIX_REPORTS = "CHANNEL_MIX_REPORTS";
export const LOYALTY_CUSTOMERS_REPORT = "LOYALTY_CUSTOMERS_REPORT";
export const COUPON_REDEMPTION_REPORTS = "COUPON_REDEMPTION_REPORTS";
export const PROFIT_REPORTS = "PROFIT_REPORTS";
export const DISCOUNT_REPORTS = "DISCOUNT_REPORTS"
export const SOS_REPORTS = "SOS_REPORTS"
export const STORES_LIST = "STORES_LIST";
export const RIDERS_REPORTS = "RIDERS_REPORTS";
export const RIDER_DETAILS_REPORT = "RIDER_DETAILS_REPORT";
export const STORES_REPORTS = "STORES_REPORTS";
export const CANCEL_SMRY_REPORTS = "CANCEL_SMRY_REPORTS";
export const CANCEL_DETAIL_REPORT = "CANCEL_DETAIL_REPORT";
export const ORDER_DETAIL_REPORT = "ORDER_DETAIL_REPORT";
export const ACTIVITY_LOGS_REPORT = "ACTIVITY_LOGS_REPORT";
export const ERROR = "ERROR"
export const REPORTS_LOAD = "REPORTS_LOAD"