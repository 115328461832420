import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Sidebar from '../../components/sidebar';
import Topbar from '../../components/topbar'
import Footer from '../../components/footer/main'
import { segmentationProps, segmentationStates } from '../../interfaces/marketing';
import { itemsListForMultiSelect } from '../../redux/actions/menuAction';
import { logoutUser } from '../../redux';
import { connect } from 'react-redux';
import Select from 'react-select';
import jwt from 'jsonwebtoken'
import { secretKey } from '../../secret'
import { createAudienceList, customerFilteration, deleteAudience, getAudience } from '../../redux/actions/marketing';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import { Link, Redirect } from 'react-router-dom';
import { currency } from '../../client-config'
import _ from 'lodash';
import moment from 'moment';
import { brandsList } from '../../redux/actions/storeAction';


// const createSliderWithTooltip = Slider.createSliderWithTooltip;
// const Range = createSliderWithTooltip(Slider.Range);
class Audience extends Component<segmentationProps, segmentationStates> {
    constructor(props: any) {
        super(props);
        this.state = {
            chequeFlag: false,
            dataType: '',
            customDateFlag: false,
            days: 0,
            validTimeFlagFrom: false,
            validTimeFlagTo: false,
            customerDetailFlag: false,
            countValue: 0,
            audience_name: "",
            audience_desc: "",
            itemId: '',
            comboId: '',
            ticketFrom: "",
            ticketTo: "",
            startDate: "",
            endDate: "",
            limit: "",
            brand_id: {
                value: 0,
                label: "All"
            }
        }

    }

    componentWillMount() {
        this.props.getAudience(this.state.brand_id.value);
    }

    componentDidMount() {
        this.props.brandsList()
    }
    handleBrandsInputChange = (e: any, index: any) => {
        this.setState({ brand_id: { label: e.label, value: e.value } });
        this.props.getAudience(e.value);
    }


    onValueChange = (e: any) => {
        console.log("marking value of checkbox", e.target.value)
    }


    handleInputChange = (event: { target: { name: any; value: any; }; }) => {
        if (event.target.value === 'custom') {
            this.setState({ customDateFlag: true });
        }
        else {
            this.setState({
                [event.target.name]: event.target.value
            });
        }
    }



    createAudience = () => {
        const { audienceList } = this.props;
        const { audience_name, audience_desc } = this.state;
        let objData = {
            name: audience_name,
            desc: audience_desc,
            customerArr: audienceList
        }
        this.props.createAudienceList(objData);
    }

    render() {
        const { data } = this.props;
        let columns: any = []
        if (data.length > 0) {
            columns = Object.keys(data[0])
        }
        let roleId
        if (sessionStorage.token) {
            jwt.verify(sessionStorage.token, secretKey, (err: any, decoded: any) => {
                if (err) {
                    this.props.logoutUser();
                }else {
                    roleId = decoded.role_id
                }
            });
        } else {
            return <Redirect to="/" />
        }
        const options: any = {
            // sizePerPageList: [5,10,15],
            sizePerPage: 10, // which size per page you want to locate as default
            page: 1,
            pageStartIndex: 1, // where to start counting the pages
            paginationSize: 3,  // the pagination bar size.
            hideSizePerPage: true, //You can hide the dropdown for sizePerPage
            insertModal: () => { return <Redirect to="/add-menu" /> },
            noDataText: 'Audience Not Found'
        };
        return (
            <div className="page">
                <Topbar />
                <div className="page-content d-flex align-items-stretch">
                    <Sidebar />
                    <div className="content-inner">
                    <header className="page-header py-0">
                            <div className="container-fluid">
                            <div className="row py-2">
                                <div className="d-flex col-lg-8 col-md-8 col-12 justify-content-between py-3">
                                    <h4 className="mt-2">Audience Management</h4>
                                </div>
                                    {roleId == 7 &&
                                        <div className="col-lg-4 col-md-4 col-12">
                                            <div className="row">
                                                <div className="col-12">
                                                    <Select
                                                        name="brand_id"
                                                        options={this.props.brands}
                                                        value={this.state.brand_id}
                                                        className="text-capitalize select mt-2"
                                                        classNamePrefix="select"
                                                        onChange={(e, i) => this.handleBrandsInputChange(e, i)}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    }
                                </div>
                            </div>
                        </header>
                        <section className="tables">
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col">
                                        <div className="card">
                                            <div className="card-body">
                                                <BootstrapTable version='4' data={this.props.audienceType} search={true} pagination={this.props.audienceType.length > 10 && true} options={options} exportCSV={true} csvFileName='Audience.csv' hover>
                                                    <TableHeaderColumn thStyle={{ fontSize: 10, fontWeight: 'bold', whiteSpace: 'normal' }} tdStyle={{ fontSize: 10, whiteSpace: 'normal' }} dataField="id" csvHeader='#' width='50' dataSort={true} isKey>#</TableHeaderColumn>
                                                    <TableHeaderColumn thStyle={{ fontSize: 10, fontWeight: 'bold', whiteSpace: 'normal' }} tdStyle={{ fontSize: 10, whiteSpace: 'normal' }} dataField='audience_name' csvHeader='Audience' width='100' columnTitle>Audience Name</TableHeaderColumn>
                                                    <TableHeaderColumn thStyle={{ fontSize: 10, fontWeight: 'bold', whiteSpace: 'normal' }} tdStyle={{ fontSize: 10, whiteSpace: 'normal' }} dataField='description' csvHeader='Email' width='100' columnTitle>Description</TableHeaderColumn>
                                                    <TableHeaderColumn thStyle={{ fontSize: 10, fontWeight: 'bold', whiteSpace: 'normal' }} tdStyle={{ fontSize: 10, whiteSpace: 'normal' }} dataField='participants' csvHeader='Phone' width='70' columnTitle>Participants</TableHeaderColumn>
                                                    <TableHeaderColumn thStyle={{ fontSize: 10, fontWeight: 'bold', whiteSpace: 'normal' }} tdStyle={{ fontSize: 10, whiteSpace: 'normal' }} dataField='date_created' dataFormat={dateFormatter} csvHeader='Phone' width='100' columnTitle>Date Created</TableHeaderColumn>
                                                    <TableHeaderColumn thStyle={{ fontSize: 10, fontWeight: 'bold', whiteSpace: 'normal' }} tdStyle={{ fontSize: 10, whiteSpace: 'normal' }} dataField='action' width='150' dataFormat={actionFormatter} formatExtraData={this.props} export={false}>Action</TableHeaderColumn>
                                                </BootstrapTable>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <Footer />
                    </div>
                </div>
            </div>
        );
    }
}
class ActionFormatter extends Component<{ row: any, deleteAudience: (id: any) => {}, }, { [x: number]: any, statusReason: any, audience_name: any, audience_desc:any }> {
    constructor(readonly props: any) {
        super(props);
        this.state = {
            statusReason: "",
            audience_name: "",
            audience_desc: ""
        }
        this.handleInputChange = this.handleInputChange.bind(this);
    }
    componentDidMount () {
        this.setState({audience_name: this.props.row.audience_name, audience_desc: this.props.row.description})
    }
    deleteAudience = (id: any) => {
        //  sessionStorage.ge
        console.log(id)
        this.props.deleteAudience(id);
    };

    handleInputChange(event: { target: { name: any; value: any; }; }) {
        this.setState({
            [event.target.name]: event.target.value
        });
    }
    createAudience = () => {
        const { audienceList } = this.props;
        const { audience_name, audience_desc } = this.state;
        let objData = {
            name: audience_name,
            desc: audience_desc,
            customerArr: audienceList
        }
        this.props.createAudienceList(objData);
    }

    isAudienceReady = () => {
        const {audience_name, audience_desc} = this.state;
        return (audience_name !== '' && audience_desc !== '')
    }
    render() {
        const { row, itemStatusReasons, unresolvedStoreOrder } = this.props
        console.log(row.id)
        return (
            <div>
                <Link to="#" title={"Delete"} data-toggle="modal" data-target={`#blockunblock${row.id}`} className={"btn btn-outline-danger mx-2"}><i className={"fa fa-trash"}></i></Link>
                <Link title="Edit" to="#" data-toggle="modal" data-target={`#editAudienceModal${row.id}`} className="btn btn-outline-primary mx-2" ><i className="fa fa-edit"></i></Link>
                {/* <!-- B/U Modal--> */}
                <div id={`blockunblock${row.id}`} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" className="modal fade text-left">
                    <div role="document" className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 id="exampleModalLabel" className="modal-title">Delete Audience</h4>
                                <button type="button" data-dismiss="modal" aria-label="Close" className="close"><span aria-hidden="true">×</span></button>
                            </div>
                            <div className="modal-body">
                                <div className="row">
                                    <div className="col-12">
                                        <p>Are you sure,you want to delete this audience?</p>
                                    </div>
                                </div>
                            </div>

                            <div className="modal-footer">
                                <button type="button" data-dismiss="modal" className="btn btn-secondary">Close</button>
                                <button onClick={() => this.deleteAudience(row.id)} className="btn btn-primary">Confirm</button>
                            </div>
                        </div>
                    </div>
                </div>
                <section className='no-padding'>
                    <div id={`editAudienceModal${row.id}`} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" className="modal fade text-left">
                        <div role="document" className="modal-dialog">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h4 id="exampleModalLabel" className="modal-title">Create Audience List</h4>
                                    <button type="button" data-dismiss="modal" aria-label="Close" className="close"><span aria-hidden="true">×</span></button>
                                </div>
                                <div className="modal-body">
                                    {/* <div className="row">
                                                <div className="col-12">
                                                    <span>Are you sure,you want to this store?</span>
                                                </div>
                                            </div> */}
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="form-group">
                                                <label className="form-control-label">
                                                    Audience Name <span className="text-danger">*</span>
                                                </label>
                                                <input id="percent" type="text" value={this.state.audience_name} name="audience_name" required data-msg="Please enter audience name" className="input-material" onChange={this.handleInputChange} />
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="form-group">
                                                <label className="form-control-label">
                                                    Audience Description <span className="text-danger">*</span>
                                                </label>
                                                <input id="percent" type="text" value={this.state.audience_desc} name="audience_desc" required data-msg="Please enter audience description" className="input-material" onChange={this.handleInputChange} />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="modal-footer">
                                    <button type="button" data-dismiss="modal" className="btn btn-secondary">Close</button>
                                    <button disabled={!this.isAudienceReady()} onClick={this.createAudience} className="btn btn-primary">Confirm</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>


            </div>
        )
    }
}

function actionFormatter(cell: any, row: any, props: any) {
    return (
        <ActionFormatter row={row} deleteAudience={props.deleteAudience} />
    );
}
function dateFormatter(cell: any, row: any) {
    if (row.date_created) {
        return moment(row.date_created).local().format('YYYY-MM-DD HH:mm')
    }
    else {
        return 'N/A'
    }
}


const mapStateToProps = (state: any) => {
    return {
        items: state.menu.allActiveItems,
        data: state.marketing.data,
        audienceList: state.marketing.audienceList,
        audienceType: state.marketing.audienceType,
        brands: state.brand.brands
    }
}
const mapDispatchToProps = (dispatch: any) => {
    return {
        logoutUser: function () {
            dispatch(logoutUser());
        },
        createAudienceList: function (data: any) {
            dispatch(createAudienceList(data))
        },
        getAudience: function (brand_id:any) {
            dispatch(getAudience(brand_id))
        },
        deleteAudience: function (id: any) {
            dispatch(deleteAudience(id))
        },
        brandsList: function () {
            dispatch(brandsList("GET_BY_BRAND"))
        },

    }
}
export default connect(mapStateToProps, mapDispatchToProps)(Audience);