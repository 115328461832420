import React, { Component } from 'react';
import { EditPromoProps, EditPromoState } from '../../interfaces/promo'
import { connect } from 'react-redux'
import { editPromo, getPromo, logoutUser, getallActiveMenus } from '../../redux'
import { Link, Redirect } from 'react-router-dom';
import jwt from 'jsonwebtoken';
import { secretKey } from '../../secret';
import Topbar from '../../components/topbar'
import Sidebar from '../../components/sidebar'
import Footer from '../../components/footer/main'
import CheckChanges from '../../components/confirmOnLeave'
import Select from 'react-select';
import { brandsList } from '../../redux/actions/storeAction';
class EditPromo extends Component<EditPromoProps, EditPromoState> {
    selectInputRef:any
    constructor(props: any) {
        super(props);
        this.state = {
            promoname: "",
            promodesc: "",
            promodiscount: "",
            expiryDate: "",
            menu: "",
            image: "",
            error: "",
            brand_id:null,
            brand_name:''
        }
        this.selectInputRef = React.createRef()
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
    }
    componentDidMount() {
        // this.props.menusList();
        this.props.brandsList()
        let id = this.props.match.params.id;
        this.props.getPromo(id);
        document.title = "SimplexCMS | Promos"
    }
    UNSAFE_componentWillReceiveProps(nextProps: any, nextState: any) {
        if (this.state.brand_id ==null) {
        this.setState({
            promoname: nextProps.promoData.promo_name,
            promodesc: nextProps.promoData.promo_description,
            // promodiscount: nextProps.promoData.promo_discount_percentage,
            expiryDate: nextProps.promoData.expire_date,
            menu: nextProps.promoData.menu_id,
        })
        if(nextProps.promoData.brand_id) {
            this.setState({
                brand_id:nextProps.promoData.brand_id,
                brand_name:nextProps.promoData.brand_name
            })
        }
    }
    }
    handleInputChange(event: { target: { name: any; value: any; }; }) {
        this.setState({
            [event.target.name]: event.target.value
        });
        if (event.target.name == "promodesc" && event.target.value.length > 150) {
            this.setState({
                error: "Promo Description must be 150 characters"
            });
        } else {
            this.setState({
                error: ""
            });
        }
    }
    fileSelectedHandler = (e: any) => {
        this.setState({ image: e.target.files })
    }
    isPromoReady = () => {
        const { promoname, promodesc, menu, expiryDate } = this.state
        return (promoname !== "" && (promodesc && promodesc.length <= 150) && menu !== "" && expiryDate !== "");
    }
    handleSubmit = (event: any) => {
        let { promoname, promodesc, promodiscount, expiryDate, menu, image } = this.state;
        let id = this.props.match.params.id;
        const data = new FormData();
        if (image !== "") {
            data.append('file', image[0])
        }
        data.append('promo_name', promoname);
        data.append('promo_description', promodesc);
        data.append('expire_date', expiryDate);
        data.append('menu_id', menu);
        data.append('is_archive', '0');
        this.props.editPromo(id, data)
    }
    handleBrandsInputChange = (e: any, index: any) => {
        let doc = document as any
        let v
        doc.getElementById("menu") ? doc.getElementById("menu").value = "Select Menu" : v=''
        this.selectInputRef.current ? this.selectInputRef.current.select.clearValue() : v =''
        this.setState({brand_id:e.value,brand_name:e.label});
        this.props.menusList(e.value);
        
    }
    render() {
        let roleId
        if (sessionStorage.token) {
            jwt.verify(sessionStorage.token, secretKey, (err: any, decoded: any) => {
                if (err) {
                    this.props.logoutUser();
                }else {
                    roleId = decoded.role_id
                }
            });
        } else {
            return <Redirect to="/" />
        }
        const { promoData, menus } = this.props;
        return (
            <div className="page">
                <CheckChanges path="/edit-promo" />
                <Topbar />
                <div className="page-content d-flex align-items-stretch">
                    <Sidebar />
                    <div className="content-inner">
                        {/* <!-- Page Header--> */}
                        <header className="page-header pb-1">
                            <div className="container-fluid">
                                <div className="d-flex align-items-center justify-content-between">
                                    <h4>Compaigns Management</h4>
                                </div>
                            </div>
                        </header>
                        {/*  Breadcrumb */}
                        <div className="breadcrumb-holder container-fluid">
                            <ul className="breadcrumb">
                                <li className="breadcrumb-item"><Link to="/marketing/1" className="text-primary">Compaigns</Link></li>
                                <li className="breadcrumb-item active">Edit Promo</li>
                            </ul>
                        </div>
                        <section className="forms">
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="card">
                                            <div className="card-body">
                                                <div className="row">
                                                    <div className="col">
                                                        <div className="form-group">
                                                            <label className="form-control-label">Promo name <span className="text-danger">*</span></label>
                                                            <input id="promoname" type="text" defaultValue={promoData.promo_name} name="promoname" required data-msg="Please enter promo Name" className="input-material" onChange={this.handleInputChange} />
                                                        </div>
                                                    </div>
                                                    {roleId == 7 && <div className="col">
                                                        <label className="form-control-label">Brand <span className="text-danger">*</span></label>
                                                        <Select
                                                            name="brand_id"
                                                            placeholder='Select Brand'
                                                            options={this.props.brands}
                                                            value={{ label: this.state.brand_name, value: this.state.brand_id }}
                                                            className="text-capitalize select mt-2"
                                                            classNamePrefix="select"
                                                            onChange={(e, i) => this.handleBrandsInputChange(e, i)}
                                                        />
                                                    </div>}
                                                </div>
                                                <div className="row">
                                                    <div className="col">
                                                        <div className="form-group">
                                                            <label className="form-control-label">Description <span className="text-danger">*</span></label>
                                                            <input id="promodesc" type="text" name="promodesc" defaultValue={promoData.promo_description} required data-msg="Please enter Description" className="input-material" onChange={this.handleInputChange} />
                                                            <small className="form-text text-danger">{this.state.error}</small>
                                                        </div>
                                                    </div>
                                                    {/* <div className="col">
                                                        <div className="form-group">
                                                            <label className="form-control-label">Promo discount (%)</label>
                                                            <input id="promodiscount" type="text" name="promodiscount" defaultValue={promoData.promo_discount_percentage} required data-msg="Please enter promo discount" className="input-material" onChange={this.handleInputChange} />
                                                        </div>
                                                    </div> */}
                                                    <div className="col">
                                                        <div className="form-group">
                                                            <label className="form-control-label">Valid till <span className="text-danger">*</span></label>
                                                            <input id="expiryDate" type="date" name="expiryDate" defaultValue={promoData.expire_date} required data-msg="Please enter Expire Date" className="input-material" onChange={this.handleInputChange} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col">
                                                        <div className="form-group">
                                                            <label className="form-control-label">Menu <span className="text-danger">*</span></label>
                                                            <select name="menu" className="form-control text-capitalize mt-2" required data-msg="Please select type" onChange={this.handleInputChange}>
                                                                <option >Select Menu</option>
                                                                {menus &&
                                                                    menus.map((menu, index) => (
                                                                        <option key={index} value={menu.menu_id} {...promoData.menu_id === menu.menu_id && { selected: true }}>{menu.menu_name}</option>
                                                                    ))
                                                                }
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="col">
                                                        <div className="form-group">
                                                            <label className="form-control-label mb-3">Promo Image</label>
                                                            <input id="file" type="file" name="file" className="form-control-file" onChange={this.fileSelectedHandler} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="form-group float-right">
                                                    <button onClick={this.handleSubmit} disabled={!this.isPromoReady()} className="btn btn-primary">Update promo</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <Footer />
                    </div>
                </div>
            </div>
        );
    }
}
const mapStateToProps = (state: any) => {
    return {
        promoData: state.promo.promoData,
        menus: state.menu.allActiveMenus,
        brands: state.store.brands,
    }
}
const mapDispatchToProps = (dispatch: any) => {
    return {
        logoutUser: function () {
            dispatch(logoutUser());
        },
        editPromo: function (id: any, data: any) {
            dispatch(editPromo(id, data));
        },
        getPromo: function (id: number) {
            dispatch(getPromo(id));
        },
        menusList: function (brand:any) {
            dispatch(getallActiveMenus(brand))
        },
        brandsList: function () {
            dispatch(brandsList())
        },
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(EditPromo);