import React, { Component } from 'react';
import { Redirect } from 'react-router-dom'
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import Topbar from '../../components/topbar'
import Sidebar from '../../components/sidebar'
import Footer from '../../components/footer/main'
import jwt from 'jsonwebtoken'
import { secretKey } from '../../secret'
import { connect } from 'react-redux'
import { logoutUser, outboundContacts, updateContactStatus } from '../../redux'
import { OutBoundContactsProps } from '../../interfaces/order';
import moment from 'moment';
import { brandsList } from '../../redux/actions/storeAction';
import Select from 'react-select';
class StatusEditor extends React.Component<{ data: any, row: any }, { [x: number]: any, status: any }> {
    constructor(props: any) {
        super(props);
        this.state = {
            status: ""
        }
        this.handleInputChange = this.handleInputChange.bind(this);
    }
    componentDidMount() {
        const { row } = this.props
        this.setState({ status: row.status })
    }
    handleSave = () => {
        const { row } = this.props
        const { status } = this.state;
        const data = {
            status: status,
        }
        this.props.data.updateContactStatus(row.id, data, this.props.data.history)
    }
    handleInputChange(event: { target: { name: any; value: any; }; }) {
        this.setState({
            [event.target.name]: event.target.value
        });
    }
    render() {
        const { data, row } = this.props
        const { status } = this.state;
        return (
            <div>
                {(row.status === "contacted") ? <span {...((row.status === "contacted" && { className: "badge badge-success text-capitalize p-2" }))}>Contacted Customer</span> :
                    <div>
                        <select
                            name="status"
                            value={status}
                            onChange={this.handleInputChange}
                            className="text-capitalize">
                            <option value="received">Received</option>
                            <option value="contacted">Contacted Customer</option>
                        </select>
                        <br />
                        <span className="badge badge-pill badge-info text-capitalize" style={{ cursor: 'pointer' }} onClick={this.handleSave}>save</span>
                    </div>
                }
            </div>
        );
    }
}
const selectStatusEditor = (cell: any, row: any, props: any) => {
    return (
        <StatusEditor row={row} data={props} />
    )
}
class OutBoundContacts extends Component<OutBoundContactsProps, {brand_id:any}> {
    constructor(readonly props: any) {
        super(props);
        this.state={
            brand_id: {
                value: 0,
                label: "All"
            }
        }
    }
    componentDidMount() {
        this.props.brandsList()
        if (sessionStorage.token) {
            jwt.verify(sessionStorage.token, secretKey, (err: any, decoded: any) => {
                if (err) {
                    this.props.logoutUser();
                } else {
                    this.props.outboundContacts(decoded.role_id == 7 ? 0 : decoded.brand_id);
                }
            });
        }
        document.title = "SimplexCMS | OutBound Contacts"
    }
    handleBrandsInputChange = (e: any, index: any) => {
        this.setState({brand_id:{label:e.label,value:e.value}});
        this.props.outboundContacts(e.value);
    }
    render() {
        let roleId
        if (sessionStorage.token) {
            jwt.verify(sessionStorage.token, secretKey, (err: any, decoded: any) => {
                if (err) {
                    this.props.logoutUser();
                }else {
                    roleId = decoded.role_id
                }
            });
        } else {
            return <Redirect to="/" />
        }
        const options: any = {
            sizePerPage: 10, // which size per page you want to locate as default
            page: 1,
            pageStartIndex: 1, // where to start counting the pages
            paginationSize: 3,  // the pagination bar size.
            hideSizePerPage: true, //You can hide the dropdown for sizePerPage
            noDataText: 'Contacts Not Found'
        };
        return (
            <div className="page">
                <Topbar />
                <div className="page-content d-flex align-items-stretch">
                    <Sidebar />
                    <div className="content-inner">
                        {/* <!-- Page Header--> */}
                        <header className="page-header py-0">
                            <div className="container-fluid">
                            <div className="row py-2">
                                <div className="d-flex col-lg-8 col-md-8 col-12 justify-content-between py-3">
                                    <h4 className="mt-2">OutBound Contacts Management</h4>
                                </div>
                                {roleId == 7 &&
                                    <div className="col-lg-4 col-md-4 col-12">
                                        <div className="row">
                                            <div className="col-12">
                                                <Select
                                                    name="brand_id"
                                                    options={this.props.brands}
                                                    value={this.state.brand_id}
                                                    className="text-capitalize select mt-2"
                                                    classNamePrefix="select"
                                                    onChange={(e, i) => this.handleBrandsInputChange(e, i)}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                }
                                </div>
                            </div>
                        </header>
                        <section className="tables">
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col">
                                        <div className="card">
                                            <div className="card-body">
                                                <BootstrapTable version='4' data={this.props.data} search={true} pagination={this.props.data.length > 10 && true} options={options} exportCSV={true} csvFileName='emails.csv' hover>
                                                    <TableHeaderColumn thStyle={{ fontSize: 15, whiteSpace: 'normal' }} tdStyle={{ fontSize: 15, whiteSpace: 'normal' }} dataField='id' csvHeader='#' width='70' dataSort={true} isKey>#</TableHeaderColumn>
                                                    <TableHeaderColumn thStyle={{ fontSize: 15, whiteSpace: 'normal' }} tdStyle={{ fontSize: 15, whiteSpace: 'normal' }} dataField='contact_number' csvHeader='contact' width='100' columnTitle>Contact</TableHeaderColumn>
                                                    <TableHeaderColumn thStyle={{ fontSize: 15, whiteSpace: 'normal' }} tdStyle={{ fontSize: 15, whiteSpace: 'normal' }} dataField='address' csvHeader='Address' width='140' columnTitle>Address</TableHeaderColumn>
                                                    <TableHeaderColumn thStyle={{ fontSize: 15, whiteSpace: 'normal' }} tdStyle={{ fontSize: 15, whiteSpace: 'normal' }} dataField='date_created' csvHeader='contact Received' dataFormat={timeFormatter} width='100' columnTitle>Contact Received</TableHeaderColumn>
                                                    <TableHeaderColumn thStyle={{ fontSize: 15, whiteSpace: 'normal' }} tdStyle={{ fontSize: 15, whiteSpace: 'normal' }} dataField='action' width='180' dataFormat={selectStatusEditor} formatExtraData={this.props}>Status</TableHeaderColumn>
                                                </BootstrapTable>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section >
                        <Footer />
                    </div>
                </div>
            </div>
        );
    }
}
;
function contactStatus(cell: any) {
    return (
        <div className="text-capitalize">
            <span {...(cell == 1 && { className: "badge badge-success p-2" }) || (cell == 0 && { className: "badge badge-danger p-2" })}>{cell == 0 ? "In active" : "Active"}</span>
        </div>
    )
}
function timeFormatter(cell: any) {
    return (
        <div>
            <span {...((cell !== "" && cell !== null) && { title: moment(cell).format('YYYY-MM-DD') })}> {(cell !== "" && cell !== null) && moment(cell).format('YYYY-MM-DD')} </span>
        </div>
    )
}
const mapStateToProps = (state: any) => {
    return {
        data: state.order.outboundContacts,
        brands: state.brand.brands
    }
}
const mapDispatchToProps = (dispatch: any) => {
    return {
        logoutUser: function () {
            dispatch(logoutUser());
        },
        outboundContacts: function (brand_id:any) {
            dispatch(outboundContacts(brand_id))
        },
        updateContactStatus: (id: any, data: any, history: any) => {
            dispatch(updateContactStatus(id, data, history))
        },
        brandsList: function () {
            dispatch(brandsList("GET_BY_BRAND"))
        },
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(OutBoundContacts);