import React, { Component } from 'react';
import { AddModGroupProps, AddModGroupState } from '../../../interfaces/menu'
import { connect } from 'react-redux'
import { addModGroup, menusList, logoutUser, groupsListForMultiSelect, itemsListForMultiSelect } from '../../../redux'
import { Link, Redirect } from 'react-router-dom'
import jwt from 'jsonwebtoken'
import { secretKey } from '../../../secret'
import Topbar from '../../../components/topbar'
import Sidebar from '../../../components/sidebar'
import Footer from '../../../components/footer/main'
import CheckChanges from '../../../components/confirmOnLeave'
import Select from 'react-select';
import { brandsList } from '../../../redux/actions/storeAction';
class AddModGroup extends Component<AddModGroupProps, AddModGroupState> {
    constructor(props: any) {
        super(props);
        this.state = {
            brand_id: null,
            groupJson: [],
            itemsJson: [],
            poscode: null,
            erpid: null,
            modgroupname: "",
            modgroupdesc: "",
            level: "group",
            type: "single",
            priority: ""
        }
        this.handleSaveBtnClick = this.handleSaveBtnClick.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleModifiersLevel = this.handleModifiersLevel.bind(this);
    }
    componentDidMount() {
        this.props.brandsList();
        this.props.groupsList();
        this.props.itemsListForMultiSelect();
        document.title = "SimplexCMS | Modifier Groups"
    }
    handleBrand = (e: any, index: any) => {
        if (e && e.value > 0) {
            this.setState({ brand_id: e.value })
            // this.props.menusList(e.value);
        }
    }
    handleInputChange(event: { target: { name: any; value: any; }; }) {
        this.setState({
            [event.target.name]: event.target.value
        });
    }
    handleModifiersLevel(event: { target: { name: any; value: any; }; }) {
        this.setState({
            [event.target.name]: event.target.value,
        });
    }
    handleGroupsInputChange = (e: any, index: any) => {
        if (e && e.length > 0) {
            this.setState({ itemsJson: [], groupJson: e });
        }
    };
    handleItemsInputChange = (e: any, index: any) => {
        if (e && e.length > 0) {
            this.setState({ groupJson: [], itemsJson: e });
        }
    }
    isModGroupReady = () => {
        const { modgroupname, groupJson, itemsJson, type, level, priority } = this.state
        return (modgroupname !== "" && (level == "group" ? groupJson.length > 0 : itemsJson.length > 0) && type != "" && level != "" && priority !== "");
    }
    handleSaveBtnClick = (event: any) => {
        let { groupJson, itemsJson, modgroupname, erpid, modgroupdesc,brand_id, poscode, type, level, priority } = this.state;
        let data: any = {
            mod_group_name: modgroupname,
            mod_group_description: modgroupdesc,
            type: level,
            modifiers_type: type,
            priority: priority,
            brand_id
        }
        if (level == 'group') {
            data["group_json"] = JSON.stringify(groupJson);
        } else if (level == 'item') {
            data["items_json"] = JSON.stringify(itemsJson);
        }
        if (poscode !== null) {
            data["pos_code"] = poscode;
        }
        if (erpid !== null) {
            data['erp_id'] = erpid;
        }
        this.props.addModGroup(data);
    }
    render() {
        if (sessionStorage.token) {
            jwt.verify(sessionStorage.token, secretKey, (err: any, decoded: any) => {
                if (err) {
                    this.props.logoutUser();
                }
            });
        } else {
            return <Redirect to="/" />
        }
        let msg;
        let messcolor;
        const { isInserted, message, groups, items } = this.props;
        if (!isInserted) {
            msg = message;
            messcolor = 'red'
        }
        let tokendata: any = jwt.decode(sessionStorage.token)
        let roleId = tokendata.role_id;
        let brandId = tokendata.brand_id;
        return (
            <div className="page">
                <CheckChanges path="/add-modgroup" />
                <Topbar />
                <div className="page-content d-flex align-items-stretch">
                    <Sidebar />
                    <div className="content-inner">
                        {/*  Page Header */}
                        <header className="page-header py-0">
                            <div className="container-fluid">
                                <div className="d-flex justify-content-between py-3">
                                    <h4 className="mt-2">Modifier Management</h4>
                                </div>
                            </div>
                        </header>
                        {/* Breadcrumb */}
                        <div className="breadcrumb-holder container-fluid">
                            <ul className="breadcrumb">
                                <li className="breadcrumb-item"><Link to="/mod-groups" className="text-primary">Modifier Groups</Link></li>
                                <li className="breadcrumb-item active">Add Modifier Group</li>
                            </ul>
                        </div>
                        <section className="forms">
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="card">
                                            <div className="card-body">
                                                <strong><p className="text-center font-weight-light mb-4" style={{ color: messcolor }}>
                                                    {msg}
                                                </p></strong>
                                                <div className="row">
                                                    <div className="col">
                                                        <div className="form-group">
                                                            <label className="form-control-label">Mod Group name <span className="text-danger">*</span></label>
                                                            <input id="modgroupname" type="text" name="modgroupname" required data-msg="Please enter Modifier Group Name" className="input-material" onChange={this.handleInputChange} />
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6 col-12">
                                                        <div className="form-group">
                                                            <label className="form-control-label">Brand <span className="text-danger">*</span></label>
                                                            <Select
                                                                name="brand"
                                                                isSearchable
                                                                options={roleId == 7 ? this.props.brands : this.props.brands.filter(o1 => brandId === o1.brand_id)}
                                                                className="text-capitalize select mt-2"
                                                                classNamePrefix="select"
                                                                onChange={(e, i) => this.handleBrand(e, i)} />
                                                        </div>
                                                    </div>
                                                    
                                                </div>
                                                <div className='row'>
                                                <div className="col">
                                                        <div className="form-group">
                                                            <label className="form-control-label">POS Code</label>
                                                            <input id="poscode" type="text" name="poscode" className="input-material" onChange={this.handleInputChange} />
                                                        </div>
                                                    </div>
                                                    <div className="col">
                                                        <div className="form-group">
                                                            <label className="form-control-label">ERP Id</label>
                                                            <input id="erpid" type="text" name="erpid" className="input-material" onChange={this.handleInputChange} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label className="form-control-label">Modifiers Level <span className="text-danger">*</span></label>
                                                            <select name="level" className="form-control text-capitalize mt-2" required data-msg="Please select Type" onChange={this.handleModifiersLevel}>
                                                                <option value='group'>Group</option>
                                                                <option value='item'>Menu Item</option>
                                                            </select>
                                                        </div>
                                                    </div>

                                                    {this.state.level === "group" &&
                                                        <div className="col-md-6">
                                                            <div className="form-group">
                                                                <label className="form-control-label">Group <span className="text-danger">*</span></label>
                                                                <Select
                                                                    isMulti
                                                                    name="groups"
                                                                    options={groups}
                                                                    className="text-capitalize basic-multi-select mt-2"
                                                                    classNamePrefix="select"
                                                                    onChange={(e, i) => this.handleGroupsInputChange(e, i)}
                                                                />
                                                            </div>
                                                        </div>
                                                    }
                                                    {this.state.level === "item" &&
                                                        <div className="col-md-6">
                                                            <div className="form-group">
                                                                <label className="form-control-label">Select Items <span className="text-danger">*</span></label>
                                                                <Select
                                                                    isMulti
                                                                    name="items"
                                                                    options={items}
                                                                    className="text-capitalize basic-multi-select mt-2"
                                                                    classNamePrefix="select"
                                                                    onChange={(e, i) => this.handleItemsInputChange(e, i)}
                                                                />
                                                            </div>
                                                        </div>
                                                    }
                                                </div>
                                                <div className="row">
                                                    <div className="col">
                                                        <div className="form-group">
                                                            <label className="form-control-label">Description</label>
                                                            <input id="modgroupdesc" type="text" name="modgroupdesc" required data-msg="Please enter Group Description" className="input-material" onChange={this.handleInputChange} />
                                                        </div>
                                                    </div>
                                                    {/* <div className="col">
                                                        <div className="form-group">
                                                            <label className="form-control-label">Group <span className="text-danger">*</span></label>
                                                            <Select
                                                                isMulti
                                                                name="groups"
                                                                options={groups}
                                                                className="text-capitalize basic-multi-select mt-2"
                                                                classNamePrefix="select"
                                                                onChange={(e, i) => this.handleGroupsInputChange(e, i)}
                                                            />
                                                        </div>
                                                    </div> */}
                                                </div>
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label className="form-control-label">Modifier Type <span className="text-danger">*</span></label>
                                                            <select name="type" className="form-control text-capitalize mt-2" required data-msg="Please select Type" onChange={this.handleInputChange}>
                                                                <option value='single'>Single Select</option>
                                                                <option value='multiple' >Multi Select</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label className="form-control-label">Priority<span className="text-danger">*</span></label>
                                                            <input id="priority" type="number" name="priority" required data-msg="Please enter Priority" className="input-material" onChange={this.handleInputChange} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="form-group d-flex justify-content-end mt-4">
                                                    <button className='btn btn-primary' disabled={!this.isModGroupReady()} onClick={this.handleSaveBtnClick}>Save</button>
                                                </div>
                                            </div>
                                        </div >
                                    </div>
                                </div>
                            </div>
                        </section>
                        <Footer />
                    </div>
                </div>
            </div>
        );
    }
}
const mapStateToProps = (state: any) => {
    return {
        menus: state.menu.menus,
        groups: state.menu.groupsptions,
        items: state.menu.allActiveItems,
        message: state.menu.message,
        isInserted: state.menu.isInserted,
        brands: state.brand.brands
    }
}
const mapDispatchToProps = (dispatch: any) => {
    return {
        logoutUser: function () {
            dispatch(logoutUser());
        },
        addModGroup: function (data: any) {
            dispatch(addModGroup(data));
        },
        menusList: function (brand_id: any) {
            dispatch(menusList(brand_id))
        },
        groupsList: function () {
            dispatch(groupsListForMultiSelect())
        },
        itemsListForMultiSelect: function () {
            dispatch(itemsListForMultiSelect())
        },
        brandsList: () => {
            dispatch(brandsList())
        }

    }
}
export default connect(mapStateToProps, mapDispatchToProps)(AddModGroup);