import { AUDIENCE, AUDIENCE_LIST, CAMPAIGN_LIST, FILTER_DATA, LOADING_STATE, SAVE_CAMPAIGN_DATA, TOP_CUSTOMERS_INSIGHTS } from '../actions/marketingType';
const initialState: any = {
    data: [] || "",
    topCustomerInsightData:[]||"",
    audienceList: [] || "",
    campaignList: [] || "",
    audienceType: [] || "",
    loading: false,
    notificationData: {},
    campaignData: {}
}

const marketingReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case FILTER_DATA: 
        return {
            ...state,
            data: action.payload
        }
        case TOP_CUSTOMERS_INSIGHTS: 
        return {
            ...state,
            topCustomerInsightData: action.payload
        }
        case AUDIENCE_LIST: 
        return {
            ...state,
            audienceList: action.payload
        }
        case AUDIENCE: 
        return {
            ...state,
            audienceType: action.payload
        }
        case LOADING_STATE: 
        return {
            ...state,
            loading: action.payload
        }
        case CAMPAIGN_LIST:
        return {
            ...state,
            campaignList: action.payload
        }
        case SAVE_CAMPAIGN_DATA: 
        return {
            ...state,
            campaignData: action.payload
        }
        default: return state;
    }
}
export default marketingReducer;