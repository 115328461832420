import React, { Component } from 'react';
import { EditStateProps, EditStates } from '../../../interfaces/settings'
import { connect } from 'react-redux'
import Topbar from '../../../components/topbar'
import Sidebar from '../../../components/sidebar'
import Footer from '../../../components/footer/main'
import { countryList, addStates, editStates, getState } from '../../../redux'
import { Link, Redirect } from 'react-router-dom';
import jwt from 'jsonwebtoken'
import Select from 'react-select';
import * as momentTimeZone from 'moment-timezone';
import { secretKey } from '../../../secret'
import CheckChanges from '../../../components/confirmOnLeave'
import { brandsList } from '../../../redux/actions/storeAction';
class AddState extends Component<EditStateProps, EditStates> {
    constructor(props: any) {
        super(props);
        this.state = {
            statename: "",
            country: "",
            tax_type: "0",
            payment: "cash",
            timezone: '',
            timeZones: [],
            brand: "",
            // tax: ""
        }
        //tax Type 0 for inclusive tax
        //tax Type 1 for exclusive tax
        this.handleSaveBtnClick = this.handleSaveBtnClick.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
    }
    componentDidMount() {
        document.title = "SimplexCMS | States"
        let id = this.props.match.params.id;
        let timeZones = momentTimeZone.tz.names();
        let array: any = [];
        timeZones.forEach((obj: any) => {
            let data = {
                label: obj,
                value: obj
            }
            array.push(data)
        })
        this.setState({ timeZones: array })
        this.props.getState(id);
        this.props.countryList();
        this.props.brandsList();
    }
    UNSAFE_componentWillReceiveProps(nextProps: any) {
        this.setState({
            country: nextProps.stateData.country_id,
            statename: nextProps.stateData.state_name,
            tax_type: nextProps.stateData.tax_type,
            payment: nextProps.stateData.payment_method,
            timezone: nextProps.stateData.time_zone,
            brand: nextProps.stateData.brand_id,
        })
    }
    handleInputChange(event: { target: { name: any; value: any; }; }) {
        this.setState({
            [event.target.name]: event.target.value
        });
    }
    isStateReady = () => {
        const { statename, country,brand, tax_type, payment, timezone } = this.state
        return (statename !== "" && country !== "" && brand !== "" && tax_type !== "" && payment !== "" && timezone !== "");
    }
    handleChangeRad = (event: { target: { name: any; value: any; } }) => {
        this.setState({ [event.target.name]: event.target.value })
    }
    handleTimeZone = (e: any, index: any) => {
        if (e && e.value !== "") {
            this.setState({ timezone: e.value });
        } else {
            this.setState({ timezone: "" });
        }
    };
    handleSaveBtnClick = (event: any) => {
        let { statename, country, tax_type, payment,brand, timezone } = this.state;
        let id = this.props.match.params.id;
        let data = {
            state_name: statename,
            country_id: country,
            tax_type: tax_type,
            payment_method: payment,
            time_zone: timezone,
            brand_id:brand
        }
        this.props.editStates(id, data);
    }
    render() {
        if (sessionStorage.token) {
            jwt.verify(sessionStorage.token, secretKey, (err: any, decoded: any) => {
                if (err) {
                    this.props.logoutUser();
                }
            });
        } else {
            return <Redirect to="/" />
        }
        let msg;
        let messcolor;
        const { isUpdated, message,stateData, brands, countries } = this.props;
        if (!isUpdated) {
            msg = message;
            messcolor = 'red'
        }
        let tokendata: any = jwt.decode(sessionStorage.token)
        let roleId = tokendata.role_id;
        return (
            <div className="page">
                <CheckChanges path="/add-state" />
                <Topbar />
                <div className="page-content d-flex align-items-stretch">
                    <Sidebar />
                    <div className="content-inner">
                        {/* <!-- Page Header--> */}
                        <header className="page-header pb-1">
                            <div className="container-fluid">
                                <div className="d-flex align-items-center justify-content-between">
                                    <h4>States Management</h4>
                                </div>
                            </div>
                        </header>
                        {/*  Breadcrumb */}
                        <div className="breadcrumb-holder container-fluid">
                            <ul className="breadcrumb">
                                <li className="breadcrumb-item"><Link to="/states" className="text-primary">States</Link></li>
                                <li className="breadcrumb-item active">Edit State</li>
                            </ul>
                        </div>
                        <section className="forms">
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="card">
                                            <div className="card-body">
                                                <strong><p className="text-center font-weight-light mb-4" style={{ color: messcolor }}>
                                                    {msg}
                                                </p></strong>
                                                <div className="row">
                                                    <div className="col-lg-6 col-sm-6 col-6 mb-3" >
                                                        <div className="">
                                                            <input id="radioCustom1" type="radio" name="tax_type" value="0" checked={this.state.tax_type == '0'} onChange={this.handleChangeRad} className="radio-template" />
                                                            <label htmlFor="radioCustom1">Inclusive tax menu prices</label>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6 col-sm-6 col-6 mb-3" >
                                                        <div className="">
                                                            <input id="radioCustom2" type="radio" name="tax_type" value="1" checked={this.state.tax_type == '1'} onChange={this.handleChangeRad} className="radio-template" />
                                                            <label htmlFor="radioCustom2">Exclusive tax menu prices</label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col">
                                                        <div className="form-group">
                                                            <label className="form-control-label">State name <span className="text-danger">*</span></label>
                                                            <input id="statename" type="text" name="statename" defaultValue={this.state.statename} required data-msg="Please enter State Name" className="input-material" onChange={this.handleInputChange} />
                                                            <small className="form-text">Please enter country name if you have same tax for all country.</small>
                                                        </div>
                                                    </div>
                                                    <div className="col">
                                                        <div className="form-group">
                                                            <label className="form-control-label">Country <span className="text-danger">*</span></label>
                                                            <select name="country" className="form-control mt-2" required data-msg="Please enter Country" onChange={this.handleInputChange}>
                                                                <option value=''>Select Country</option>
                                                                {countries &&
                                                                    countries.map((country: any, index: any) => (
                                                                        <option key={index} value={country.country_id} {...this.state.country == country.country_id && { selected: true }}>{country.country_name}</option>
                                                                    ))
                                                                }
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col">
                                                        <div className="form-group">
                                                            <label className="form-control-label">Time Zone <span className="text-danger">*</span></label>
                                                            <Select
                                                                name="timeZones"
                                                                isClearable
                                                                options={this.state.timeZones}
                                                                className="text-capitalize select mt-2"
                                                                classNamePrefix="select"
                                                                value={this.state.timeZones.map((element: any) => {
                                                                    if (element.value == this.state.timezone) {
                                                                        return { label: element.label, value: element.value }
                                                                    }
                                                                })}
                                                                onChange={(e, i) => this.handleTimeZone(e, i)}
                                                            />
                                                            {/* <select name="timezone" className="form-control mt-2" required data-msg="Please enter Country" onChange={this.handleInputChange}>
                                                                <option value='' selected={this.state.timezone ? false : true}>Select Timezone</option>
                                                                {this.state.timeZones.map((zone: any, index: any) => (
                                                                    <option key={index} value={zone} selected={this.state.timezone == zone ? true : false}>{zone}</option>
                                                                ))
                                                                }
                                                            </select> */}
                                                        </div>
                                                    </div>
                                                    <div className="col">
                                                        <div className="form-group">
                                                            <label className="form-control-label">Brand <span className="text-danger">*</span></label>
                                                            <select disabled={roleId == 7 ? false : true} name="brand" className="form-control text-capitalize mt-2" required data-msg="Please select brand" onChange={this.handleInputChange}>
                                                                <option value="">Select Brand</option>
                                                                {brands &&
                                                                    brands.map((brand, index) => (
                                                                        brand.is_active == 1 &&
                                                                        <option key={index} value={brand.brand_id} {...stateData.brand_id === brand.brand_id && { selected: true }}>{brand.brand_name}</option>
                                                                    ))
                                                                }
                                                            </select>
                                                        </div>
                                                    </div>

                                                </div>
                                                <div className='row'>
                                                    <div className="col-lg-6 col-md-6 col-sm-12">
                                                        <div className="form-group">
                                                            <label className="form-control-label">Payment Method <span className="text-danger">*</span></label>
                                                            <select name="payment" className="form-control mt-2" onChange={this.handleInputChange}>
                                                                <option value="">Select Payment Method</option>
                                                                <option value="cash" {...this.state.payment == "cash" && { selected: true }}>Cash</option>
                                                                <option value="card" {...this.state.payment == "card" && { selected: true }}>Card</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                                {/* <div className="row">
                                                    <div className="col">
                                                        <div className="form-group">
                                                            <label className="form-control-label">Tax value <span className="text-danger">*</span></label>
                                                            <input id="tax" type="text" name="tax" required data-msg="Please enter Tax" className="input-material" onChange={this.handleInputChange} />
                                                        </div>
                                                    </div>
                                                </div> */}
                                                <div className="form-group float-right mt-3">
                                                    <button className='btn btn-primary' disabled={!this.isStateReady()} onClick={this.handleSaveBtnClick}>Save</button>
                                                </div>
                                            </div>
                                        </div >
                                    </div>
                                </div>
                            </div>
                        </section>
                        <Footer />
                    </div>
                </div>
            </div>
        );
    }
}
const mapStateToProps = (state: any) => {
    return {
        countries: state.store.countries,
        stateData: state.setting.stateData,
        brands: state.store.brands,
        message: state.setting.message,
        isUpdated: state.setting.isUpdated
    }
}
const mapDispatchToProps = (dispatch: any) => {
    return {
        editStates: function (id: any, data: any) {
            dispatch(editStates(id, data));
        },
        getState: (id: any) => {
            dispatch(getState(id))
        },
        countryList: function () {
            dispatch(countryList())
        },
        brandsList: function () {
            dispatch(brandsList())
        },
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(AddState);