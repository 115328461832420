import React, { Component } from 'react';
import { connect } from 'react-redux'
import { AddTradeAreaProps } from '../../interfaces/tradeZone';
import { Link, Redirect } from 'react-router-dom'
import jwt from 'jsonwebtoken'
import { secretKey } from '../../secret'
import Topbar from '../../components/topbar'
import Sidebar from '../../components/sidebar'
import Footer from '../../components/footer/main'
import CheckChanges from '../../components/confirmOnLeave'
import { addTradeArea, addTradeZone, handleTradeAreaInput, logoutUser, storesList, tradeZoneList } from '../../redux';
class AddTradeArea extends Component<AddTradeAreaProps, {}> {
    constructor(props: any) {
        super(props);
        this.handleSaveBtnClick = this.handleSaveBtnClick.bind(this);
    }
    componentDidMount() {
        document.title = "SimplexYum | TradeAreas";
        this.props.tradeZoneList();
        this.props.storesList();
    }
    isTradeAreaReady = () => {
        const { areaname, tradezone, store } = this.props;
        return (areaname !== "" && store !== "" && tradezone !== "");
    }
    handleSaveBtnClick = () => {
        let { areaname, tradezone, store } = this.props;
        let data = {
            area_name: areaname,
            delivery_zone_id: tradezone,
            store_id: store,
            is_active: 1
        }
        this.props.addTradeArea(data);
    }
    render() {
        if (sessionStorage.token) {
            jwt.verify(sessionStorage.token, secretKey, (err: any, decoded: any) => {
                if (err) {
                    this.props.logoutUser();
                }
            });
        } else {
            return <Redirect to="/" />
        }
        let { tradezones, stores } = this.props;
        return (
            <div className="page">
                <CheckChanges path="/add-brand" />
                <Topbar />
                <div className="page-content d-flex align-items-stretch">
                    <Sidebar />
                    <div className="content-inner">
                        {/*  Page Header */}
                        <header className="page-header py-0">
                            <div className="container-fluid">
                                <div className="d-flex justify-content-between py-3">
                                    <h4 className="mt-2">Trade Areas Management</h4>
                                </div>
                            </div>
                        </header>
                        {/* Breadcrumb */}
                        <div className="breadcrumb-holder container-fluid">
                            <ul className="breadcrumb">
                                <li className="breadcrumb-item"><Link to="/tradearea" className="text-primary">Trade Areas</Link></li>
                                <li className="breadcrumb-item active">Add Trade Area</li>
                            </ul>
                        </div>
                        <section className="forms">
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="card">
                                            <div className="card-body">
                                                <div className="row">
                                                    <div className="col">
                                                        <div className="form-group">
                                                            <label className="form-control-label">TradeArea name <span className="text-danger">*</span></label>
                                                            <input id="areaname" type="text" name="areaname" required data-msg="Please enter TradeAreaName" className="input-material" onChange={this.props.handleTradeAreaInput} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col">
                                                        <div className="form-group">
                                                            <label className="form-control-label">TradeZone</label>
                                                            <select name="tradezone" className="form-control mt-2" required data-msg="Please Select TradeZone" onChange={this.props.handleTradeAreaInput}>
                                                                <option value="">Select TradeZone</option>
                                                                {tradezones &&
                                                                    tradezones.map((zone, index) => (
                                                                        <option key={index} value={zone.id}>{zone.delivery_zone_name}</option>
                                                                    ))
                                                                }
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="col">
                                                        <div className="form-group">
                                                            <label className="form-control-label">Store</label>
                                                            <select name="store" className="form-control mt-2" required data-msg="Please Select Store" onChange={this.props.handleTradeAreaInput}>
                                                                <option value="">Select Store</option>
                                                                {stores &&
                                                                    stores.map((store, index) => (
                                                                        <option key={index} value={store.store_id}>{store.store_name}</option>
                                                                    ))
                                                                }
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="form-group d-flex justify-content-end mt-4">
                                                    <button className='btn btn-primary' disabled={!this.isTradeAreaReady()} onClick={this.handleSaveBtnClick} >Save</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <Footer />
                    </div>
                </div>
            </div >
        );
    }
}
const mapStateToProps = (state: any) => {
    return {
        areaname: state.tradezone.areaname,
        tradezone: state.tradezone.tradezone,
        tradezones: state.tradezone.tradezones,
        stores: state.store.data,
        store: state.tradezone.store
    }
}
const mapDispatchToProps = (dispatch: any) => {
    return {
        handleTradeAreaInput: (event: any) => { dispatch(handleTradeAreaInput(event)) },
        logoutUser: () => { dispatch(logoutUser()); },
        tradeZoneList: () => { dispatch(tradeZoneList()) },
        addTradeArea: (data: any) => { dispatch(addTradeArea(data)); },
        storesList: () => { dispatch(storesList()) }
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(AddTradeArea);