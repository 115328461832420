import React, { Component } from 'react';
import { Redirect, Link } from 'react-router-dom'
import Topbar from '../../components/topbar'
import Sidebar from '../../components/sidebar'
import Footer from '../../components/footer/main'
import jwt from 'jsonwebtoken'
import { secretKey } from '../../secret'
import { connect } from 'react-redux'
import { getAllMenuList, logoutUser, statusChangeReasons, updateItemStatus, searchMenuItem, searchGroup, searchCombos, searchModifiers, getallActiveMenus } from '../../redux';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import Toggle from 'react-toggle'
import 'react-tabs/style/react-tabs.css';
import "react-toggle/style.css"
import './menuStyle.css'
import Select from 'react-select'
import { storesList } from '../../redux/actions/reportAction';
import { brandsList } from '../../redux/actions/storeAction';
interface MenuState {
    [x: number]: any,
    activeTab: any, status: any,
    statusReason: any,
    is_active: any,
    data: any,
    store: any,
    menuStoresOption: any
    brand_id: any
}
interface MenuProps {
    history: any
    groupsData: any,
    itemsData: any,
    combosData: any,
    modifiersData: any,
    searchItemData: any,
    searchGroupData: any,
    searchModData: any,
    searchComboData: any,
    itemStatusReasons: any[],
    stores: any[],
    menuData: any,
    searchMenuItem: (value: any, data: any) => {}
    searchGroup: (value: any, data: any) => {}
    searchCombo: (value: any, data: any) => {}
    searchModifiers: (value: any, data: any) => {}
    storesList: () => {},
    getAllMenuList: (store_id: any) => {},
    statusChange: () => {},
    updateItemStatus: (data: any, history: any) => {},
    menusList: () => {}
    brandsList: () => {}
}
class BranchMenu extends Component<MenuProps, MenuState> {
    openModal: any;
    closeModal: any;
    storesDropDown: any;
    constructor(readonly props: any) {
        super(props);
        this.state = {
            activeTab: 0,
            status: false,
            is_active: "",
            data: {},
            statusReason: "",
            store: "",
            menuStoresOption: [],
            brand_id: {
                value: 0,
                label: "All"
            }
        }
        this.handleToggleChange = this.handleToggleChange.bind(this);
        this.saveLogsForToggle = this.saveLogsForToggle.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
    }
    componentDidMount() {
        this.props.statusChange();
        if (sessionStorage.token) {
            jwt.verify(sessionStorage.token, secretKey, (err: any, decoded: any) => {
                if (err) {
                    this.props.logoutUser();
                } else {
                    if (decoded.role_id != 7) {
                        this.props.storesList(decoded.brand_id);
                        this.props.menusList(decoded.brand_id);
                    } else {
                        this.props.storesList(this.state.brand_id.value);
                        this.props.menusList(this.state.brand_id.value);
                    }
                }
            });
        }
        this.props.brandsList(0);
        document.title = "SimplexCMS | Menu"
    }
    handleStoresInputChange = (e: any, index: any) => {
        console.log("aslfkdjasfljasfkljaslf------------e", e)
        if (e && e.value > 0) {
            this.props.getAllMenuList(e.value);
            this.setState({ store: e.value });
        } else {
            this.setState({ store: " " });
        }
        //console.log("E ", e.value);
    };
    handleToggleChange(e: any, data: any) {
        this.openModal.click();
        this.setState({
            data: data,
            is_active: e.target.checked == false ? 0 : 1
        })
    }
    saveLogsForToggle() {
        let { data, is_active, statusReason, store } = this.state;
        let body: any = {
            is_active: is_active,
            reason: statusReason,
            status: is_active == 1 ? "UnBlock" : "Block",
            store_id: store
        }
        if (data.group_id) {
            body.group_id = data.group_id.group_id
        } else if (data.menu_item_id) {
            body.menu_item_id = data.menu_item_id.menu_item_id
        } else if (data.combo_id) {
            body.combo_id = data.combo_id.combo_id
        } else if (data.modifier_id) {
            body.modifier_id = data.modifier_id.modifier_id
        }
        this.props.updateItemStatus(body, this.props.history);
        this.closeModal.click();
    }
    handleInputChange(event: { target: { name: any; value: any; }; }) {
        this.setState({
            [event.target.name]: event.target.value
        });
        if (event.target.name == "menu_item") {
            this.props.searchMenuItem(event.target.value, this.props.itemsData)
        } else if (event.target.name == "group") {
            this.props.searchGroup(event.target.value, this.props.groupsData)
        } else if (event.target.name == "combo") {
            this.props.searchCombo(event.target.value, this.props.combosData)
        } else if (event.target.name == "modifier") {
            this.props.searchModifiers(event.target.value, this.props.modifiersData)
        }
    }
    selectMenuChange = (event: { target: { name: any; value: any; }; }) => {
        if (event.target.value !== "") {
            let stores = JSON.parse(event.target.value);
            let menuStoresOptions = [];
            if (stores.length > 0) {
                for (let i = 0; i < stores.length; i++) {
                    let obj: any = {};
                    obj.value = stores[i].store_id;
                    obj.label = stores[i].store_name;
                    menuStoresOptions.push(obj);
                }
                this.storesDropDown.select.clearValue();
                this.setState({ menuStoresOption: menuStoresOptions })
            } else {
                this.storesDropDown.select.clearValue();
                this.setState({ menuStoresOption: [] })
            }
        } else {
            this.storesDropDown.select.clearValue();
            this.setState({ menuStoresOption: [] })
        }
    }
    isSaveStatusReady = () => {
        const { statusReason, is_active } = this.state;
        if (is_active == 0) {
            return (statusReason !== "");
        } else {
            return true;
        }
    }
    changeTab = (index: any) => {
        this.setState({
            activeTab: index
        })
        this.props.getAllMenuList(this.state.store)
    }
    handleBrandsInputChange = (e: any, index: any) => {
        this.setState({ brand_id: { value: e.value, label: e.label } })
        if (e && e.value > 0) {
            this.props.menusList(e.value)
            this.storesDropDown.select.clearValue();
            this.setState({ menuStoresOption: [] })
        } else {
            this.props.menusList(0)
            this.storesDropDown.select.clearValue();
            this.setState({ menuStoresOption: [] })
        }
    };
    render() {
        if (sessionStorage.token) {
            jwt.verify(sessionStorage.token, secretKey, (err: any, decoded: any) => {
                if (err) {
                    this.props.logoutUser();
                }
            });
        } else {
            return <Redirect to="/" />
        }
        let tokendata: any = jwt.decode(sessionStorage.token)
        let roleId = tokendata.role_id;
        let { searchGroupData, searchItemData, searchModData, searchComboData, itemStatusReasons, menuData } = this.props;
        let { is_active } = this.state;
        return (
            <div className="page">
                <Topbar />
                <div className="page-content d-flex align-items-stretch">
                    <Sidebar />
                    <div className="content-inner">
                        {/* <!-- Page Header--> */}
                        <header className="page-header py-0">
                            <div className="container-fluid">
                                <div className="d-flex justify-content-between py-3">
                                    <h4 className="mt-2">Menu Management</h4>
                                </div>
                            </div>
                        </header>
                        <section className="tables pt-4">
                            <div className="container-fluid">
                                {roleId == 7 && <div className="row form-group">
                                    <label
                                        className="col-md-3 col-sm-3 form-control-label font-weight-bold"
                                        style={{ paddingTop: "0.5em" }}
                                    >
                                        Brands
                                    </label>
                                    <div className="col-md-9 col-sm-9">
                                        <Select
                                            name="brand_id"
                                            options={this.props.brands}
                                            value={this.state.brand_id}
                                            className="text-capitalize select mt-2"
                                            classNamePrefix="select"
                                            onChange={(e, i) => this.handleBrandsInputChange(e, i)}
                                        />
                                    </div>
                                </div>}
                                <div className="row form-group">
                                    <label
                                        className="col-md-3 col-sm-3 form-control-label font-weight-bold"
                                        style={{ paddingTop: "0.5em" }}
                                    >
                                        Menus
                                    </label>
                                    <div className="col-md-9 col-sm-9">
                                        <select onChange={this.selectMenuChange} name="emailType" className="form-control text-capitalize">
                                            <option value="">
                                                Select Breakfast
                                            </option>
                                            {menuData.map((menu: any, index: any) => (
                                                <option key={index} value={JSON.stringify(menu.stores)} >{menu.menu_name}</option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                                <div className="row form-group">
                                    <label
                                        className="col-md-3 col-sm-3 form-control-label font-weight-bold"
                                        style={{ paddingTop: "0.5em" }}
                                    >
                                        Stores
                                    </label>
                                    <div className="col-md-9 col-sm-9">
                                        <Select
                                            ref={el => this.storesDropDown = el}
                                            name="storeType"
                                            isClearable
                                            options={this.state.menuStoresOption}
                                            className="text-capitalize select mt-2"
                                            classNamePrefix="select"
                                            onChange={(e, i) => this.handleStoresInputChange(e, i)}
                                        />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col">
                                        <div className="card">

                                            <div className="card-body p-0 mb-5">
                                                <Tabs
                                                    selectedIndex={this.state.activeTab}
                                                    onSelect={(index) =>
                                                        this.changeTab(index)
                                                    }
                                                >
                                                    <TabList
                                                        style={{
                                                            background: "#20a5d6",
                                                            color: "#FFF",
                                                            padding: "10px",
                                                        }}
                                                    >
                                                        <Tab>Groups</Tab>
                                                        <Tab>Menu Items</Tab>
                                                        <Tab>Combos</Tab>
                                                        <Tab>Modifiers</Tab>
                                                    </TabList>

                                                    <TabPanel style={{ marginTop: '2em' }}>
                                                        <div className="container-fluid">
                                                            <div className="row">
                                                                <div className="col-lg-12 col-12">
                                                                    <div className="card-header">
                                                                        <div className="row">
                                                                            <div className="col-lg-6">
                                                                                <strong>All Groups</strong>
                                                                            </div>
                                                                            <div className="col-lg-6">
                                                                                <div className="search-box">
                                                                                    <label className="mb-0">
                                                                                        <i className="fa fa-search"></i>
                                                                                    </label>
                                                                                    <input id="group" type="text" name="group" style={{ border: 'none', marginLeft: '10px', width: '100%' }} required data-msg="Please enter group" onChange={this.handleInputChange} />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="card">
                                                                        <div className="card-body text-capitalize">
                                                                            <div className="row">
                                                                                {searchGroupData.length > 0 ? searchGroupData.map((element: any, index: any) => {
                                                                                    return (
                                                                                        <div className="col-lg-6 my-2">
                                                                                            <div className="d-flex justify-content-between">
                                                                                                <div>{element.group_id.group_name}</div>
                                                                                                <div>
                                                                                                    <Toggle
                                                                                                        className='custom-classname'
                                                                                                        checked={element.is_active == 0 ? false : true}
                                                                                                        icons={false}
                                                                                                        onChange={(e: any) => this.handleToggleChange(e, element)} />
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    )
                                                                                }) :
                                                                                    <div className="col-12 text-center">
                                                                                        <h5>Groups not found</h5>
                                                                                    </div>}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                        </div>
                                                    </TabPanel>
                                                    <TabPanel style={{ marginTop: '2em' }}>
                                                        <div className="container-fluid">

                                                            <div className="row">
                                                                <div className="col-lg-12 col-12">
                                                                    <div className="card-header">
                                                                        <div className="row">
                                                                            <div className="col-lg-6">
                                                                                <strong>All Items</strong>
                                                                            </div>
                                                                            <div className="col-lg-6">
                                                                                <div className="search-box">
                                                                                    <label className="mb-0">
                                                                                        <i className="fa fa-search"></i>
                                                                                    </label>
                                                                                    <input id="menu_item" type="text" name="menu_item" style={{ border: 'none', marginLeft: '10px', width: '100%' }} required data-msg="Please enter item" onChange={this.handleInputChange} />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="card">
                                                                        <div className="card-body">
                                                                            <div className="row">
                                                                                {searchItemData.length > 0 ? searchItemData.map((element: any, index: any) => {
                                                                                    return (
                                                                                        <div className="col-lg-6 my-2">
                                                                                            <div className="d-flex justify-content-between">
                                                                                                <div>{element.menu_item_id.item_name}</div>
                                                                                                <div>
                                                                                                    <Toggle
                                                                                                        className='custom-classname'
                                                                                                        checked={element.is_active == 0 ? false : true}
                                                                                                        icons={false}
                                                                                                        onChange={(e: any) => this.handleToggleChange(e, element)} />
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    )
                                                                                }) :
                                                                                    <div className="col-12 text-center">
                                                                                        <h5>Menu Items not found</h5>
                                                                                    </div>}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                        </div>
                                                    </TabPanel>
                                                    <TabPanel style={{ marginTop: '2em' }}>
                                                        <div className="container-fluid">
                                                            <div className="row">
                                                                <div className="col-lg-12 col-12">
                                                                    <div className="card-header">
                                                                        <div className="row">
                                                                            <div className="col-lg-6">
                                                                                <strong>All Combos</strong>
                                                                            </div>
                                                                            <div className="col-lg-6">
                                                                                <div className="search-box">
                                                                                    <label className="mb-0">
                                                                                        <i className="fa fa-search"></i>
                                                                                    </label>
                                                                                    <input id="combo" type="text" name="combo" style={{ border: 'none', marginLeft: '10px', width: '100%' }} required data-msg="Please enter combo" onChange={this.handleInputChange} />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="card">
                                                                        <div className="card-body">
                                                                            <div className="row">
                                                                                {searchComboData.length > 0 ? searchComboData.map((element: any, index: any) => {
                                                                                    return (
                                                                                        <div className="col-lg-6 my-2">
                                                                                            <div className="d-flex justify-content-between">
                                                                                                <div>{element.combo_id.combo_name}</div>
                                                                                                <div>
                                                                                                    <Toggle
                                                                                                        className='custom-classname'
                                                                                                        checked={element.is_active == 0 ? false : true}
                                                                                                        icons={false}
                                                                                                        onChange={(e: any) => this.handleToggleChange(e, element)} />
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    )
                                                                                }) :
                                                                                    <div className="col-12 text-center">
                                                                                        <h5 >Combos not found</h5>
                                                                                    </div>}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </TabPanel>
                                                    <TabPanel style={{ marginTop: '2em' }}>
                                                        <div className="container-fluid">

                                                            <div className="row">
                                                                <div className="col-lg-12 col-12">
                                                                    <div className="card-header">
                                                                        <div className="row">
                                                                            <div className="col-lg-6">
                                                                                <strong>All Modifiers</strong>
                                                                            </div>
                                                                            <div className="col-lg-6">
                                                                                <div className="search-box">
                                                                                    <label className="mb-0">
                                                                                        <i className="fa fa-search"></i>
                                                                                    </label>
                                                                                    <input id="modifier" type="text" name="modifier" style={{ border: 'none', marginLeft: '10px', width: '100%' }} required data-msg="Please enter modifier" onChange={this.handleInputChange} />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="card">
                                                                        <div className="card-body">
                                                                            <div className="row">
                                                                                {searchModData.length > 0 ? searchModData.map((element: any, index: any) => {
                                                                                    return (
                                                                                        <div className="col-lg-6 my-2">
                                                                                            <div className="d-flex justify-content-between">
                                                                                                <div>{element.modifier_id.modifier_name}</div>
                                                                                                <div>
                                                                                                    <Toggle
                                                                                                        className='custom-classname'
                                                                                                        checked={element.is_active == 0 ? false : true}
                                                                                                        icons={false}
                                                                                                        onChange={(e: any) => this.handleToggleChange(e, element)} />
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    )
                                                                                }) :
                                                                                    <div className="col-12 text-center">
                                                                                        <h5>Modifiers not found</h5>
                                                                                    </div>}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                        </div>
                                                    </TabPanel>

                                                </Tabs>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <Footer />
                    </div>
                </div>
                {/* End Print Preview */}
                <div style={{ display: "none" }}>
                    <button ref={el => this.openModal = el} data-toggle="modal" data-target={`#saveLogs`} className="btn btn-outline-info ml-2"><i className="fa fa-file-pdf-o"></i></button>
                </div>
                <div id={`saveLogs`} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" className="modal fade text-left">
                    <div role="document" className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 id="exampleModalLabel" className="modal-title">{is_active == 1 ? "UnBlock" : "Block"} Item</h4>
                                <button type="button" data-dismiss="modal" aria-label="Close" className="close"><span aria-hidden="true">×</span></button>
                            </div>
                            <div className="modal-body">
                                <div className="row">
                                    <div className="col-12">
                                        <h6>Are you sure,you want to {is_active == 1 ? "UnBlock" : "Block"} the Item?</h6>
                                    </div>
                                    {is_active == 0 &&
                                        <div className="col-12">
                                            <div className="form-group">
                                                <select
                                                    name="statusReason"
                                                    onChange={this.handleInputChange}
                                                    className="form-control mt-2">
                                                    <option value="">Select Reason</option>
                                                    {itemStatusReasons &&
                                                        itemStatusReasons.map((reason: any, index: any) => (
                                                            <option key={index} value={reason.id}>{reason.reason}</option>
                                                        ))
                                                    }
                                                </select>

                                            </div>
                                        </div>
                                    }
                                </div>
                                <div className="form-group d-flex justify-content-end mt-4">
                                    <button className='btn btn-primary' disabled={!this.isSaveStatusReady()} onClick={() => this.saveLogsForToggle()}>Save</button>
                                    {/*  */}
                                </div>
                            </div>
                            <div style={{ display: "none" }}>
                                <button ref={el => this.closeModal = el} type="button" data-dismiss="modal" className="btn btn-info" />
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        );
    }
}
;
const mapStateToProps = (state: any) => {
    return {
        groupsData: state.menu.groupsData,
        itemsData: state.menu.itemsData,
        combosData: state.menu.combosData,
        modifiersData: state.menu.modifiersData,
        searchItemData: state.menu.searchItemData,
        searchModData: state.menu.searchModData,
        searchGroupData: state.menu.searchGroupData,
        searchComboData: state.menu.searchComboData,
        itemStatusReasons: state.menu.statusChangeReasons,
        stores: state.report.stores,
        menuData: state.menu.allActiveMenus,
        brands: state.store.brands,
    }
}
const mapDispatchToProps = (dispatch: any) => {
    return {
        logoutUser: function () {
            dispatch(logoutUser());
        },
        getAllMenuList: (store_id: any) => {
            dispatch(getAllMenuList(store_id));
        },
        updateItemStatus: (data: any, history: any) => {
            dispatch(updateItemStatus(data, history))
        },
        statusChange: () => {
            dispatch(statusChangeReasons())
        },
        storesList: (brand: any) => {
            dispatch(storesList(brand))
        },
        searchGroup: (value: any, data: any) => {
            dispatch(searchGroup(value, data))
        },
        searchMenuItem: (value: any, data: any) => {
            dispatch(searchMenuItem(value, data))
        },
        searchCombo: (value: any, data: any) => {
            dispatch(searchCombos(value, data))
        },
        searchModifiers: (value: any, data: any) => {
            dispatch(searchModifiers(value, data))
        },
        menusList: function (brand: any) {
            dispatch(getallActiveMenus(brand))
        },
        brandsList: function () {
            dispatch(brandsList("GET_BY_BRAND"))
        },
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(BranchMenu);