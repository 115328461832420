export const CUSTOMERS_LIST = "CUSTOMERS_LIST"
export const ORDER_HISTORY = "ORDER_HISTORY"
export const FAV_MENU_ITEM = "FAV_MENU_ITEM"
export const GET_CUSTOMER = "GET_CUSTOMER"
export const EDIT_CUSTOMER = "EDIT_CUSTOMER"
export const FEEDBACK_LIST = "FEEDBACK_LIST"
export const FRANCHISE_LIST = "FRANCHISE_LIST"
export const NEWSLETTER_EMAILS = "NEWSLETTER_EMAILS"
export const CUSTOMER_COMPLAINTS = "CUSTOMER_COMPLAINTS"
export const BULK_UPLOAD = "BULK_UPLOAD"
export const DUP_DATA = "DUP_DATA"
export const UPDATE_LOADER = "UPDATE_LOADER"
export const SET_OFFSET = "SET_OFFSET"
export const SET_PAGE_COUNT = "SET_PAGE_COUNT"
export const SET_PERPAGE = "SET_PERPAGE"
export const SEARCH_CUSTOMERS ="SEARCH_CUSTOMERS"