import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { EditIngProps, EditIngState } from '../../../../interfaces/menu';
import { connect } from 'react-redux';
import jwt from 'jsonwebtoken';
import { secretKey } from '../../../../secret';
import { editGroup, logoutUser, getGroup, editMeal, getMeal, getIng, editIng, nutritionalCatList } from '../../../../redux';
import Topbar from '../../../../components/topbar';
import Sidebar from '../../../../components/sidebar';
import Footer from '../../../../components/footer/main';
import CheckChanges from '../../../../components/confirmOnLeave';
import Select from 'react-select';

class EditIng extends Component<EditIngProps, EditIngState> {
    constructor(props: any) {
        super(props);
        this.state = {
            itemName: "",
            itemNameAR: "",
            nutCatId: "",
            kcal: "",
            kj: "", fat: "",
            sat_fat: "", carbs: "",
            sugar: "", fibre: "",
            protein: "",
            salt: ""
        }
        this.handleSaveBtnClick = this.handleSaveBtnClick.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
    }
    componentDidMount() {
        let id = this.props.match.params.id;
        this.props.getIng(id);
        this.props.ingCatList();
        document.title = "SimplexCMS | Ingredients"
    }
    UNSAFE_componentWillReceiveProps(nextProps: any) {
        this.setState({
            itemName: nextProps.ingData.item_name,
            itemNameAR: nextProps.ingData.meal_name_ar && nextProps.ingData.meal_name_ar,
            nutCatId: nextProps.ingData.nutritional_cat_id ? nextProps.ingData.nutritional_cat_id : "",
            kcal: nextProps.ingData.Kcal,
            kj: nextProps.ingData.KJ,
            fat: nextProps.ingData.FAT,
            sat_fat: nextProps.ingData.SAT_FAT,
            sugar: nextProps.ingData.SUGARS,
            protein: nextProps.ingData.PROTEIN,
            fibre: nextProps.ingData.FIBRE,
            salt: nextProps.ingData.SALT,
            carbs: nextProps.ingData.CARBS,
        })
    }
    handleInputChange(event: { target: { name: any; value: any; }; }) {
        this.setState({
            [event.target.name]: event.target.value
        });
    }
    handleCategory = (e: any, index: any) => {
        if (e && e.value !== "") {
            this.setState({ nutCatId: e.value });
        } else {
            this.setState({ nutCatId: "" });
        }
    };
    isMealReady = () => {
        const { itemName, nutCatId, kcal, kj, fat, sat_fat, salt, fibre, carbs, sugar, protein } = this.state
        return (itemName !== "" && kcal !== "" && kj !== "" && fat !== "" && sat_fat !== "" && salt !== "" && fibre !== "" && carbs !== "" && sugar !== "" && protein !== "");
    }
    handleSaveBtnClick = (event: any) => {
        let { itemName, itemNameAR, nutCatId, kcal, kj, fat, sat_fat, salt, fibre, carbs, sugar, protein } = this.state
        let id = this.props.match.params.id;
        let data: any = {
            item_name: itemName,
            item_name_ar: itemNameAR !== "" ? itemNameAR : null,
            nutritional_cat_id: nutCatId,
            Kcal: kcal,
            KJ: kj, FAT: fat, SAT_FAT: sat_fat, CARBS: carbs,
            SALT: salt, FIBRE: fibre, PROTEIN: protein, SUGARS: sugar
        }
        this.props.editIng(id, data);
    }
    render() {
        if (sessionStorage.token) {
            jwt.verify(sessionStorage.token, secretKey, (err: any, decoded: any) => {
                if (err) {
                    this.props.logoutUser();
                }
            });
        } else {
            return <Redirect to="/" />
        }
        const { ingData } = this.props;
        return (
            <div className="page">
                <CheckChanges path="/edit-meal" />
                <Topbar />
                <div className="page-content d-flex align-items-stretch">
                    <Sidebar />
                    <div className="content-inner">
                        {/* <!-- Page Header--> */}
                        <header className="page-header pb-1">
                            <div className="container-fluid">
                                <div className="d-flex align-items-center justify-content-between">
                                    <h4>Ingredients Management</h4>
                                </div>
                            </div>
                        </header>
                        {/*  Breadcrumb */}
                        <div className="breadcrumb-holder container-fluid">
                            <ul className="breadcrumb">
                                <li className="breadcrumb-item"><Link to="/ingredients" className="text-primary">Ingredients</Link></li>
                                <li className="breadcrumb-item active">Edit Ingredients</li>
                            </ul>
                        </div>
                        <section className="forms">
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="card">
                                            <div className="card-body">
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label className="form-control-label">Item Name <span className="text-danger">*</span></label>
                                                            <input id="itemName" type="text" name="itemName" required data-msg="Please enter meal name" className="input-material" defaultValue={ingData.item_name} onChange={this.handleInputChange} />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label className="form-control-label">Item Name (Arabic)</label>
                                                            <input id="itemNameAR" type="text" name="itemNameAR" required data-msg="Please enter meal arabic name" className="input-material" defaultValue={ingData.item_name_ar} onChange={this.handleInputChange} />
                                                        </div>
                                                    </div>
                                                    <div className="col">
                                                        <div className="form-group">
                                                            <label className="form-control-label">Meal Categories <span className="text-danger">*</span></label>
                                                            <Select
                                                                name="IngCategories"
                                                                isClearable
                                                                options={this.props.ingCat}
                                                                className="text-capitalize select mt-2"
                                                                classNamePrefix="select"
                                                                value={{ label: ingData.nutritional_cat_id && ingData.nutritional_cat_id.nutritional_meal_id.meal_name + " (" + ingData.nutritional_cat_id.group_name + ")", value: ingData.nutritional_cat_id }}
                                                                onChange={(e, i) => this.handleCategory(e, i)}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label className="form-control-label">Kcal <span className="text-danger">*</span></label>
                                                            <input id="kcal" type="text" name="kcal" required data-msg="Please enter kcal" className="input-material" defaultValue={ingData.Kcal} onChange={this.handleInputChange} />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label className="form-control-label">KJ <span className="text-danger">*</span></label>
                                                            <input id="kj" type="text" name="kj" required data-msg="Please enter kj" className="input-material" defaultValue={ingData.KJ} onChange={this.handleInputChange} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label className="form-control-label">FAT <span className="text-danger">*</span></label>
                                                            <input id="fat" type="text" name="fat" required data-msg="Please enter fat" className="input-material" defaultValue={ingData.FAT} onChange={this.handleInputChange} />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label className="form-control-label">SAT FAT <span className="text-danger">*</span></label>
                                                            <input id="sat_fat" type="text" name="sat_fat" required data-msg="Please enter sat fat" className="input-material" defaultValue={ingData.SAT_FAT} onChange={this.handleInputChange} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label className="form-control-label">CARBS <span className="text-danger">*</span></label>
                                                            <input id="carbs" type="text" name="carbs" required data-msg="Please enter carbs" className="input-material" defaultValue={ingData.CARBS} onChange={this.handleInputChange} />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label className="form-control-label">SUGARS <span className="text-danger">*</span></label>
                                                            <input id="sugar" type="text" name="sugar" required data-msg="Please enter sugar" className="input-material" defaultValue={ingData.SUGARS} onChange={this.handleInputChange} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-md-4">
                                                        <div className="form-group">
                                                            <label className="form-control-label">FIBRE <span className="text-danger">*</span></label>
                                                            <input id="fibre" type="text" name="fibre" required data-msg="Please enter fibre" className="input-material" defaultValue={ingData.FIBRE} onChange={this.handleInputChange} />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-4">
                                                        <div className="form-group">
                                                            <label className="form-control-label">PROTEIN <span className="text-danger">*</span></label>
                                                            <input id="protein" type="text" name="protein" required data-msg="Please enter protein" className="input-material" defaultValue={ingData.PROTEIN} onChange={this.handleInputChange} />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-4">
                                                        <div className="form-group">
                                                            <label className="form-control-label">SALT <span className="text-danger">*</span></label>
                                                            <input id="salt" type="text" name="salt" required data-msg="Please enter salt" className="input-material" defaultValue={ingData.SALT} onChange={this.handleInputChange} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="form-group float-right mt-3">
                                                    <button onClick={() => { this.props.history.push("/ingredients") }} className="btn btn-danger mr-2">Cancel</button>
                                                    <button className='btn btn-primary' disabled={!this.isMealReady()} onClick={this.handleSaveBtnClick}>Save</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <Footer />
                    </div>

                </div>
            </div >
        );
    }
};
const mapStateToProps = (state: any) => {
    return {
        ingData: state.menu.ingData,
        ingCat: state.menu.ingCat
    }
}
const mapDispatchToProps = (dispatch: any) => {
    return {
        logoutUser: function () {
            dispatch(logoutUser());
        },
        editIng: function (id: any, data: any) {
            dispatch(editIng(id, data));
        },
        getIng: function (id: number) {
            dispatch(getIng(id));
        },
        ingCatList: () => {
            dispatch(nutritionalCatList())
        }
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(EditIng);