import React, { Component } from 'react';
import { Redirect } from 'react-router-dom'
import Topbar from '../../components/topbar'
import Sidebar from '../../components/sidebar'
import Footer from '../../components/footer/main'
import jwt from 'jsonwebtoken'
import { secretKey } from '../../secret'
import { connect } from 'react-redux'
import { logoutUser, getTaxValue, saveDiscountValue, countryList, saveDeliveryFee, discountsList, statesList, saveTaxByState } from '../../redux'
import { SettingsProps, SettingsState } from '../../interfaces/settings';
import { getLoyaltyTiers } from '../../redux/actions/loyaltyAction';
import { saveTierLevelMinCartValue } from '../../redux/actions/settingsAction';
import { brandsList } from '../../redux/actions/storeAction';
import Select from 'react-select'

class Settings extends Component<SettingsProps, SettingsState> {
    constructor(readonly props: any) {
        super(props);
        this.state = {
            country: "",
            deliveryCountry: "",
            state: "",
            deliveryState: "",
            tax_value: "",
            delivery_fee: "",
            loyaltyLevel: "",
            min_cart_value: "",
            brand_id: null
            // {
            //     value: 0,
            //     label: "Select Store"
            // }
        }
        this.handleTaxSubmit = this.handleTaxSubmit.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleStateChange = this.handleStateChange.bind(this);
        this.handleDeliveryFeeStateChange = this.handleDeliveryFeeStateChange.bind(this);
    }
    componentWillMount(): void {
        this.props.brandsList()
    }
    componentDidMount() {
        // this.props.getTaxValue();
        this.props.countryList();
        this.props.statesList();
        this.props.discountsList();
        this.props.getLoyaltyTiers();
        if (this.props.brands.length>0) {
            this.setState({ brand_id: { value: this.props.brands[1].value, label: this.props.brands[1].label } })
        }
        
        document.title = "SimplexCMS | Global Settings"
    }
    // componentDidUpdate(prevProps: Readonly<SettingsProps>, prevState: Readonly<SettingsState>, snapshot?: any): void {
    //     if (prevProps != this.props) {
    //         this.setState({ brand_id: { value: this.props.brands[1].value, label: this.props.brands[1].lavel } })
            
    //     }
    // }
    handleInputChange(event: { target: { name: any; value: any; }; }) {
        this.setState({
            [event.target.name]: event.target.value
        });
        if (event.target.name == 'country') {
            if (event.target.value == "") {
                this.setState({ state: "", tax_value: "" });
            } else {
                if (event.target.value !== this.state.country) {
                    this.setState({ state: "", tax_value: "" });
                }
            }
        } else if (event.target.name == 'deliveryCountry') {
            if (event.target.value == "") {
                this.setState({ deliveryState: "", delivery_fee: "" });
            } else {
                if (event.target.value !== this.state.deliveryCountry) {
                    this.setState({ deliveryState: "", delivery_fee: "" });
                }
            }
        }
    }
    handleStateChange(event: { target: { name: any; value: any; }; }) {
        let { states } = this.props;
        if (event.target.value !== "") {
            let data: any = states.find((state: any) => state.state_id == event.target.value);
            this.setState({ state: data.state_id, tax_value: data.tax_percent ? data.tax_percent : "" });
        } else {
            this.setState({ state: "", tax_value: "" });
        }
    }
    
    handleTierInputChange = (event: { target: { name: any; value: any; }; }) => {
        let { loyaltyTiers } = this.props;
        if (event.target.value !== "") {
            let data: any = loyaltyTiers.find((tier: any) => tier.loyalty_level == event.target.value);
            this.setState({ loyaltyLevel: data.loyalty_level, min_cart_value: data.min_cart_value });
        } else {
            this.setState({ loyaltyLevel: "", min_cart_value: 0 });
        }
    }
    handleDeliveryFeeStateChange(event: { target: { name: any; value: any; }; }) {
        let { states } = this.props;
        if (event.target.value !== "") {
            let data: any = states.find((state: any) => state.state_name == event.target.value);
            this.setState({ deliveryState: data.state_name, delivery_fee: data.delivery_fee ? data.delivery_fee : "" });
        } else {
            this.setState({ deliveryState: "", delivery_fee: "" });
        }
    }
    isTaxSubmitReady = () => {
        let { country, state, tax_value } = this.state;
        return (country !== "" && state !== "" && tax_value !== "");
    }
    isCartValueReady = () => {
        let { min_cart_value, loyaltyLevel } = this.state;
        return (min_cart_value !== "" && loyaltyLevel !== "");
    }
    isCountrySubmitReady = () => {
        let { deliveryCountry, deliveryState, delivery_fee } = this.state;
        return (deliveryCountry !== "" && deliveryState !== "" && delivery_fee !== "");
    }
    handleTaxSubmit = (event: any) => {
        let { tax_value, state } = this.state;
        let data = {
            state_id: state,
            tax_percent: tax_value
        }
        this.props.saveTaxByState(data);
        event.preventDefault()
    }
    
    handleTierCartValue = (event: any) => {
        let { min_cart_value, loyaltyLevel } = this.state;
        let data = {
            loyalty_level: loyaltyLevel,
            min_cart_value: min_cart_value
        }
        this.props.saveTierLevelMinCartValue(data);
        event.preventDefault()
    }
    blockInvalidChar = (e: any) =>
        ["e", "E", "+", "-"].includes(e.key) && e.preventDefault();

    handleDeliveryFeeSubmit = (event: any) => {
        let { delivery_fee, deliveryState } = this.state;
        let data = {
            state_name: deliveryState,
            delivery_fee: delivery_fee
        }
        // let { country, delivery_fee } = this.state;
        // let obj = {
        //     country_code: country,
        //     delivery_fee: delivery_fee
        // }
        this.props.saveDeliveryFee(data);
        event.preventDefault()
    }
    handleBrandsInputChange = (e: any, index: any) => {
        this.setState({ brand_id: { value: e.value, label: e.label } })
        // if (e && e.value > 0) {
        //     this.props.statesList(e.value)
        // } else {
        //     this.props.statesList(0)
        // }
    };
    render() {
        let roleId: any = ""
        if (sessionStorage.token) {
            jwt.verify(sessionStorage.token, secretKey, (err: any, decoded: any) => {
                if (err) {
                    this.props.logoutUser();
                }else{
                    roleId = decoded.role_id
                }
            });
        } else {
            return <Redirect to="/" />
        }
        let msg, discmsg;
        let taxcolor, disccolor;
        let deliverymsg, deliverycolor;
        let { country, tax_value, delivery_fee, deliveryCountry,brand_id } = this.state;
        const { isSaved, message, states, countries, deliveryMsg,brands, saveDelivery } = this.props;
        if (!isSaved) { msg = message; taxcolor = 'red' } else { msg = message; taxcolor = 'green' }
        if (!saveDelivery) { deliverymsg = deliveryMsg; deliverycolor = 'red' }        
        return (
            <div className="page">
                <Topbar />
                <div className="page-content d-flex align-items-stretch">
                    <Sidebar />
                    <div className="content-inner">
                        {/* <!-- Page Header--> */}
                        <header className="page-header py-0">
                            <div className="container-fluid">
                                <div className="row py-2">
                                    <div className="d-flex col-lg-8 col-md-8 col-sm-12 justify-content-between py-3">
                                        <h4 className="mt-2">Global Settings</h4>
                                    </div>
                                    {roleId == 7 &&
                                        <div className="col-lg-4 col-md-4 col-12">
                                            <div className="row">
                                                <div className="col-12">
                                                    <Select
                                                        name="brand_id"
                                                        options={this.props.brands}
                                                        value={this.state.brand_id}
                                                        className="text-capitalize select mt-2"
                                                        classNamePrefix="select"
                                                        onChange={(e, i) => this.handleBrandsInputChange(e, i)}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    }
                                </div>
                            </div>
                        </header>
                        <section className="forms">
                            <div className="container-fluid">
                                <div className="card shadow rounded">
                                    <div className="row p-3">
                                        <div className="col-lg-6">
                                            <div className="card shadow rounded">
                                                <div className="card-content">
                                                    <div className="card-header border-bottom-grey">
                                                        <strong className="">Global Tax</strong>
                                                    </div>
                                                    <div className="card-body">
                                                        <strong><p className="text-center font-weight-light mb-4" style={{ color: taxcolor }}>
                                                            {msg}
                                                        </p></strong>
                                                        <form className="form-validate">
                                                            <div className="row">
                                                                <div className="col">
                                                                    <div className="form-group">
                                                                        <label className="form-control-label">Country <span className="text-danger">*</span></label>
                                                                        <select name="country" className="form-control mt-2" required data-msg="Please enter Country" onChange={this.handleInputChange}>
                                                                            <option value="">Select Country</option>
                                                                            {countries &&
                                                                                countries.map((country: any, index: any) => (
                                                                                    <option key={index} value={country.country_id}>{country.country_name}</option>
                                                                                ))
                                                                            }
                                                                        </select>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="row">
                                                                <div className="col">
                                                                    <div className="form-group">
                                                                        <label className="form-control-label">States <span className="text-danger">*</span></label>
                                                                        <select name="state" className="form-control mt-2" required data-msg="Please select state" onChange={this.handleStateChange}>
                                                                            <option value="">Select State</option>
                                                                            {roleId == 7 ? country !== "" && brand_id.value !== "" &&
                                                                                states &&
                                                                                states.map((state: any, index: any) => (
                                                                                    state.country_id == country && state.brand_id == brand_id.value &&
                                                                                    <option key={index} value={state.state_id}>{state.state_name}-({state.payment_method})</option>
                                                                                )): country !== "" && 
                                                                                states &&
                                                                                states.map((state: any, index: any) => (
                                                                                    state.country_id == country &&
                                                                                    <option key={index} value={state.state_id}>{state.state_name}-({state.payment_method})</option>
                                                                                ))
                                                                            }
                                                                        </select>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="row">
                                                                <div className="col">
                                                                    <div className="form-group">
                                                                        <label className="form-control-label">Tax Value <span className="text-danger">*</span></label>
                                                                        <input id="taxValue" type="number" name="tax_value" min={0} onKeyDown={this.blockInvalidChar} value={tax_value} required data-msg="Please enter Tax value" className="input-material" onChange={this.handleInputChange} />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <br />
                                                            <div className="form-group d-flex justify-content-end">
                                                                <button onClick={this.handleTaxSubmit} disabled={!this.isTaxSubmitReady()} className="btn btn-primary">Save</button>
                                                            </div>
                                                        </form>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-6">
                                            <div className="card shadow rounded">
                                                <div className="card-content">
                                                    <div className="card-header border-bottom-grey">
                                                        <strong className="">Delivery Fee</strong>
                                                    </div>
                                                    <div className="card-body">
                                                        <strong><p className="text-center font-weight-light mb-4" style={{ color: deliverycolor }}>
                                                            {deliverymsg}
                                                        </p></strong>
                                                        <form className="form-validate">
                                                            <div className="row">
                                                                <div className="col">
                                                                    <div className="form-group">
                                                                        <label className="form-control-label">Country <span className="text-danger">*</span></label>
                                                                        <select name="deliveryCountry" className="form-control mt-2" required data-msg="Please enter Country" onChange={this.handleInputChange}>
                                                                            <option>Select Country</option>
                                                                            {countries &&
                                                                                countries.map((country: any, index: any) => (
                                                                                    <option key={index} value={country.country_id}>{country.country_name}</option>
                                                                                ))
                                                                            }
                                                                        </select>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="row">
                                                                <div className="col">
                                                                    <div className="form-group">
                                                                        <label className="form-control-label">States <span className="text-danger">*</span></label>
                                                                        <select name="deliveryState" className="form-control mt-2" required data-msg="Please select state" onChange={this.handleDeliveryFeeStateChange}>
                                                                            <option value="">Select State</option>
                                                                            {roleId == 7? deliveryCountry !== "" && brand_id.value !== "" &&
                                                                                states &&
                                                                                states.map((state: any, index: any) => (
                                                                                    (state.country_id == deliveryCountry ) && state.brand_id == brand_id.value &&//&& state.payment_method == "cash"
                                                                                    <option key={index} value={state.state_name}>{state.state_name}</option>
                                                                                )):
                                                                                deliveryCountry !== "" &&
                                                                                states &&
                                                                                states.map((state: any, index: any) => (
                                                                                    (state.country_id == deliveryCountry ) &&//&& state.payment_method == "cash"
                                                                                    <option key={index} value={state.state_name}>{state.state_name}</option>
                                                                                ))
                                                                            }
                                                                        </select>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="row">
                                                                <div className="col">
                                                                    <div className="form-group">
                                                                        <label className="form-control-label">Delivery Fee <span className="text-danger">*</span></label>
                                                                        <input id="deliveryFee" type="number" name="delivery_fee" min={0}
                                                                            onKeyDown={this.blockInvalidChar} value={delivery_fee} required data-msg="Please enter Delivery Fee" className="input-material" onChange={this.handleInputChange} />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <br />
                                                            <div className="form-group d-flex justify-content-end">
                                                                <button onClick={this.handleDeliveryFeeSubmit} disabled={!this.isCountrySubmitReady()} className="btn btn-primary">Save</button>
                                                            </div>
                                                        </form>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/* <div className="col-lg-6">
                                            <div className="card shadow rounded">
                                                <div className="card-content">
                                                    <div className="card-header border-bottom-grey">
                                                        <strong className="">Loyalty Tier Minimum Cart Value</strong>
                                                    </div>
                                                    <div className="card-body">
                                                        <strong><p className="text-center font-weight-light mb-4" style={{ color: taxcolor }}>
                                                            {msg}
                                                        </p></strong>
                                                        <form className="form-validate">
                                                            <div className="row">
                                                                <div className="col">
                                                                    <div className="form-group">
                                                                        <label className="form-control-label">Tier Level<span className="text-danger">*</span></label>
                                                                            <select id="MinOrder" name="LoyaltyLevel" onChange={this.handleTierInputChange} className="form-control text-capitalize mt-2" required data-msg="Please select Menu">
                                                                                <option value="">Select Tier Level</option>
                                                                                <option value='First'>First</option>
                                                                                <option value='Second'>Second</option>
                                                                                <option value='Third'>Third</option>
                                                                            </select>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="row">
                                                                <div className="col">
                                                                    <div className="form-group">
                                                                        <label className="form-control-label">Minimum Cart Value <span className="text-danger">*</span></label>
                                                                        <input id="min_cart_value" type="number" name="min_cart_value" min={0} onKeyDown={this.blockInvalidChar} value={this.state.min_cart_value} required data-msg="Please enter Tax value" className="input-material" onChange={this.handleInputChange} />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <br />
                                                            <div className="form-group d-flex justify-content-end">
                                                                <button onClick={this.handleTierCartValue} disabled={!this.isCartValueReady()} className="btn btn-primary">Save</button>
                                                            </div>
                                                        </form>
                                                    </div>
                                                </div>
                                            </div>
                                        </div> */}
                                    </div>
                                </div>
                            </div>
                        </section>
                        <Footer />
                    </div>
                </div >
            </div>
        );
    }
}
const mapStateToProps = (state: any) => {
    return {
        message: state.setting.message,
        discounts: state.setting.discounts,
        discountMsg: state.setting.discMsg,
        states: state.setting.states,
        countries: state.store.countries,
        isSaved: state.setting.isSaved,
        isInserted: state.setting.isInserted,
        tax: state.setting.taxData,
        deliveryMsg: state.setting.deliveryMsg,
        saveDelivery: state.setting.saveDelivery,
        loyaltyTiers: state.loyalty.loyaltyTiers,
        brands: state.brand.brands
    }
}
const mapDispatchToProps = (dispatch: any) => {
    return {
        logoutUser: function () {
            dispatch(logoutUser());
        },
        countryList: function () {
            dispatch(countryList())
        },
        discountsList: function () {
            dispatch(discountsList(0))
        },
        statesList: function () {
            dispatch(statesList())
        },
        saveTaxByState: function (data: any) {
            dispatch(saveTaxByState(data))
        },
        getTaxValue: function () {
            dispatch(getTaxValue());
        },
        saveDiscountValue: function (data: any) {
            dispatch(saveDiscountValue(data))
        },
        saveDeliveryFee: function (data: any) {
            dispatch(saveDeliveryFee(data))
        },
        getLoyaltyTiers: function () {
            dispatch(getLoyaltyTiers());
        },
        saveTierLevelMinCartValue: function (loyatyObj: any) {
            dispatch(saveTierLevelMinCartValue(loyatyObj))
        },
        brandsList: function () {
            dispatch(brandsList("GET_BY_BRAND"))
        },
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(Settings);