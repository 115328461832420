import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import BeautyStars from "beauty-stars";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import Topbar from "../../components/topbar";
import Sidebar from "../../components/sidebar";
import Footer from "../../components/footer/main";
import jwt from "jsonwebtoken";
import { secretKey } from "../../secret";
import { connect } from "react-redux";
import { logoutUser, deleteFeedback } from "../../redux";
import { CustomerComplaintsProps } from "../../interfaces/customers";
import "jquery/dist/jquery.min.js";
//Datatable Modules
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
// import JSZip from 'jszip';
import Select from 'react-select';
import 'datatables.net-buttons/js/buttons.colVis';
import 'datatables.net-buttons/js/buttons.html5';
import 'datatables.net-buttons/js/buttons.flash';
import 'datatables.net-buttons/js/buttons.print';
import { getComplaints } from "../../redux/actions/customerAction";
import { brandsList } from "../../redux/actions/storeAction";
require('datatables.net-buttons/js/buttons.colVis.js')();
require('datatables.net-buttons/js/buttons.html5.js')();
require('datatables.net-buttons/js/buttons.flash.js')();
require('datatables.net-buttons/js/buttons.print.js')();
const $ = require("jquery");
$.DataTable = require("datatables.net");

function ratingsFormatter(cell: any, row: any) {
  return <BeautyStars value={cell} size="16" inactiveColor="#b7b7b7" />;
}
class ActionFormatter extends Component<{ row: any }, {}> {
  constructor(readonly props: any) {
    super(props);

  }
  handleDelete = (row: any) => {
    console.log("row", row)

    // this.props.delete(id);
  };
  render() {
    const { row } = this.props;
    const dateOrder = new Date(row.order_date).toLocaleDateString();
    // const dateOrder = row.order_date

    return (
      <div>
        <button
          title="View Feedback"
          className="btn btn-outline-primary"
          data-toggle="modal"
          data-target={`#ViewFeedback${row.id}`}
          onClick={() => this.handleDelete(row)}
        >
          <i className="fa fa-eye"></i>
        </button>
        {/* <!-- Modal--> */}
        <div
          id={`ViewFeedback${row.id}`}
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
          className="modal fade text-left"
        >
          <div role="document" className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h4 id="exampleModalLabel" className="modal-title">
                  Customer Feedback
                </h4>
                <button
                  type="button"
                  data-dismiss="modal"
                  aria-label="Close"
                  className="close"
                >
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <div className="modal-body">
                <div className="form-row" >
                  {/* <div className="form-group col-md-12"> <br /></div>
                                            <div className="form-group col-md-12"><h4 id="txt">Feedback Form</h4></div> */}
                  <div className="form-group col-md-6">
                    <h5 id="txt">  Name</h5>
                    <input name="fullname" disabled defaultValue={row.name} style={{ fontSize: "15px" }} type="text" className="form-control bg-white" id="round" required />
                  </div>
                  <div className="form-group col-md-6">
                    <h5 id="txt">Phone</h5>
                    <input name="phone" disabled defaultValue={row.phone_number} style={{ fontSize: "15px" }} type="text" className="form-control bg-white" id="round" required />
                  </div>

                  <div className="form-group col-md-6">
                    <h5 id="txt">Email</h5>
                    <input name="email" disabled defaultValue={row.email_address} style={{ fontSize: "15px" }} type="email" className="form-control bg-white" id="round" required />
                  </div>
                  <div className="form-group col-md-6">
                    <h5 id="txt">Store</h5>
                    <input type="text" name="dateOfOrder" disabled defaultValue={row.store_name} required className="form-control bg-white" id="round" />
                  </div>

                  <div className="form-group col-md-6">
                    <h5 id="txt">Order Date </h5>
                    <input type="text" name="dateOfOrder" disabled defaultValue={dateOrder} required data-msg="Please Enter Order Date" className="form-control bg-white" id="round" />
                  </div>
                  <div className="form-group col-md-12">
                    <h5 id="txt">Comments</h5>
                    <textarea name="feedbackMessage" id="round2" disabled style={{ width: '100%', height: '100px' }}>
                      {row.message}
                    </textarea>
                  </div>

                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  data-dismiss="modal"
                  className="btn btn-danger"
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

class FeedbackFormatter extends Component<{ row: any }, {}> {
  constructor(readonly props: any) {
    super(props);

  }
  // handleDelete = (row: any) => {
  //   console.log("row",row)

  //   // this.props.delete(id);
  // };
  render() {
    const { row } = this.props;
    const dateOrder = new Date(row.order_date).toLocaleDateString();
    // const dateOrder = row.order_date

    return (
      <div>
        {row.message.length > 30 ?
          <button
            title="View Comments"
            className="btn btn-outline-primary"
            data-toggle="modal"
            data-target={`#ViewFeedbackMessage${row.id}`}
          // onClick={() => this.handleDelete(row)}
          >
            view comments
          </button>
          :
          <>
            {row && row.message}
          </>
        }

        {/* <!-- Modal--> */}
        <div
          id={`ViewFeedbackMessage${row.id}`}
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
          className="modal fade text-left"
        >
          <div role="document" className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h4 id="exampleModalLabel" className="modal-title">
                  Customer Comments
                </h4>
                <button
                  type="button"
                  data-dismiss="modal"
                  aria-label="Close"
                  className="close"
                >
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <div className="modal-body">
                <div className="form-row" >
                  <div className="form-group col-md-12">
                    <h5 id="txt">Comments</h5>
                    <textarea name="feedbackMessage" id="round2" disabled style={{ width: '100%', height: '100px' }}>
                      {row.message}
                    </textarea>
                  </div>

                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  data-dismiss="modal"
                  className="btn btn-danger"
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
function actionFormatter(cell: any, row: any, props: any) {
  return <ActionFormatter row={row} delete={props.deleteFeedback} />;
}
function feedbackFormatter(cell: any, row: any, props: any) {
  return <FeedbackFormatter row={row} />;
}
class CustomerComplaint extends Component<CustomerComplaintsProps, {brand_id:any}> {
  constructor(readonly props: any) {
    super(props);
    this.state={
      brand_id: {
          value: 0,
          label: "All"
      }
  }
  }
  componentDidMount() {
    // this.props.getComplaints();
    document.title = "SimplexCMS | Customer Complaints";
    // if(this.props.data.length > 0)
    this.props.brandsList()
    if (sessionStorage.token) {
      jwt.verify(sessionStorage.token, secretKey, (err: any, decoded: any) => {
        if (err) {
          this.props.logoutUser();
        } else {
          this.props.getComplaints(decoded.role_id == 7 ? 0 : decoded.brand_id);
        }
      });
    }
    $('#example').DataTable({
      "dom": 'Bfrtip',
      "buttons": [
        'copy',
        'excel',
        // 'csv',
        'pdf',
        'print'
      ]
    });
  }
  handleBrandsInputChange = (e: any, index: any) => {
    this.setState({ brand_id: { label: e.label, value: e.value } });
    this.props.getComplaints(e.value);
}
  render() {
    let roleId
    const { data } = this.props
    if (sessionStorage.token) {
      jwt.verify(sessionStorage.token, secretKey, (err: any, decoded: any) => {
        if (err) {
          this.props.logoutUser();
        }else {
          roleId = decoded.role_id
      }
      });
    } else {
      return <Redirect to="/" />;
    }
    const options: any = {
      sizePerPage: 10, // which size per page you want to locate as default
      page: 1,
      pageStartIndex: 1, // where to start counting the pages
      paginationSize: 3, // the pagination bar size.
      hideSizePerPage: true, //You can hide the dropdown for sizePerPage
      noDataText: "Customer Feedbacks Not Found"
    };
    // if(this.props.data.length > 0){
    //   $('#example').DataTable({
    //   "buttons": [
    //     'copyHtml5',
    //     'excelHtml5',
    //     'csvHtml5',
    //     'pdfHtml5'
    //   ]
    // });  
    // }

    return (
      <div className="page">
        <Topbar />
        <div className="page-content d-flex align-items-stretch">
          <Sidebar />
          <div className="content-inner">
            {/* <!-- Page Header--> */}
            <header className="page-header py-0">
              <div className="container-fluid">
                <div className="row py-2">
                  <div className="d-flex col-lg-8 col-md-8 col-12 justify-content-between py-3">
                    <h4 className="mt-2">Customer Feedback Management</h4>
                  </div>
                  {roleId == 7 &&
                    <div className="col-lg-4 col-md-4 col-12">
                      <div className="row">
                        <div className="col-12">
                          <Select
                            name="brand_id"
                            options={this.props.brands}
                            value={this.state.brand_id}
                            className="text-capitalize select mt-2"
                            classNamePrefix="select"
                            onChange={(e, i) => this.handleBrandsInputChange(e, i)}
                          />
                        </div>
                      </div>
                    </div>
                  }
                </div>
              </div>
            </header>
            <section className="tables">
              <div className="container-fluid">
                <div className="row">
                  <div className="col">
                    <div className="card">
                      <div className="card-body text-capitalize">


                        {this.props.data &&
                          <div className="card-body">
                            <BootstrapTable version='4' data={this.props.data} search={true} pagination={this.props.data.length > 10 && true} options={options} exportCSV={true} csvFileName='customers.csv' hover>
                              {/* <TableHeaderColumn dataField='customer_id' csvHeader='#' width='70' dataSort={true} isKey>#</TableHeaderColumn> */}
                              <TableHeaderColumn thStyle={{ fontSize: 15, whiteSpace: 'normal' }} tdStyle={{ fontSize: 15, whiteSpace: 'normal' }} dataField='name' csvHeader='Name' width='100' columnTitle dataSort={true} isKey>Name</TableHeaderColumn>
                              <TableHeaderColumn thStyle={{ fontSize: 15, whiteSpace: 'normal' }} tdStyle={{ fontSize: 15, whiteSpace: 'normal' }} dataField='email_address' csvHeader='Email' width='100' columnTitle>Email</TableHeaderColumn>
                              <TableHeaderColumn thStyle={{ fontSize: 15, whiteSpace: 'normal' }} tdStyle={{ fontSize: 15, whiteSpace: 'normal' }} dataField='phone_number' csvHeader='Phone' width='100' columnTitle>Phone</TableHeaderColumn>
                              <TableHeaderColumn thStyle={{ fontSize: 15, whiteSpace: 'normal' }} tdStyle={{ fontSize: 15, whiteSpace: 'normal' }} dataField='store_name' csvHeader='Store' width='100' columnTitle>Store</TableHeaderColumn>
                              <TableHeaderColumn thStyle={{ fontSize: 15, whiteSpace: 'normal' }} tdStyle={{ fontSize: 15, whiteSpace: 'normal' }} dataField='date_created' csvHeader='Complaint Date' width='130' columnTitle>Complaint Date</TableHeaderColumn>
                              {/* <TableHeaderColumn thStyle={{ fontSize: 15, whiteSpace: 'normal' }} tdStyle={{ fontSize: 15, whiteSpace: 'normal' }} dataField='resolved_date' csvHeader='Resolve Date' width='130' columnTitle>Resolve Date</TableHeaderColumn> */}
                              {/* <TableHeaderColumn thStyle={{ fontSize: 15, whiteSpace: 'normal' }} tdStyle={{ fontSize: 15, whiteSpace: 'normal' }} dataField='store_response' csvHeader='Resolution' width='130' columnTitle>Resolution</TableHeaderColumn> */}
                              <TableHeaderColumn thStyle={{ fontSize: 15, whiteSpace: 'normal' }} tdStyle={{ fontSize: 15, whiteSpace: 'normal' }} dataField='message' dataFormat={feedbackFormatter} csvHeader='Comments' width='200' columnTitle>Comments</TableHeaderColumn>
                              <TableHeaderColumn thStyle={{ fontSize: 15, whiteSpace: 'normal' }} tdStyle={{ fontSize: 15, whiteSpace: 'normal' }} dataField='action' width='150' dataFormat={actionFormatter} formatExtraData={this.props} export={false}>Action</TableHeaderColumn>

                            </BootstrapTable>
                          </div>}
                        {/* {this.props.data
                        && <table
                          id=""
                          className="table table-striped table-bordered table-sm row-border hover mb-5"
                        >
                          <thead>
                              <th>Menu</th>
                              <th>Item</th>
                              <th>Name</th>
                              <th>Email</th>
                          </thead>
                          <tbody>
                            {this.props.data && this.props.data.map((data: any) => (
                              <tr>
                                <td>{data.menu_name}</td>
                                <td>{data.item_name}</td>
                                <td>{data.login_name}</td>
                                <td>{data.email_address}</td>
                              </tr>
                            ))}
                          </tbody>
                        </table>} */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <Footer />
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state: any) => {
  console.log("state", state);
  return {
    data: state.customer.complaintData,
    brands: state.brand.brands
  };
};
const mapDispatchToProps = (dispatch: any) => {
  return {
    logoutUser: function () {
      dispatch(logoutUser());
    },
    deleteFeedback: function (id: any) {
      dispatch(deleteFeedback(id));
    },
    getComplaints: function (brand_id:any) {
      dispatch(getComplaints(brand_id));
    },
    brandsList: function () {
      dispatch(brandsList("GET_BY_BRAND"))
  },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(CustomerComplaint);
