import React, { Component } from "react";
import { AddMenuProps, AddMenuState } from "../../interfaces/menu";
import { connect } from "react-redux";
import jwt from 'jsonwebtoken'
import { secretKey } from '../../secret'
import {
  addMenu,
  storeTypeList,
  brandsList,
  logoutUser,
  countryList,
  statesList
} from "../../redux";
import Topbar from '../../components/topbar'
import Sidebar from '../../components/sidebar'
import Footer from '../../components/footer/main'
import Select from 'react-select';
import { Link, Redirect } from "react-router-dom";
import CheckChanges from '../../components/confirmOnLeave'
import { storesList } from "../../redux/actions/reportAction";
import { storesListForMenuStoreRelation } from "../../redux/actions/storeAction";
class AddMenu extends Component<AddMenuProps, AddMenuState> {
  constructor(props: any) {
    super(props);
    this.state = {
      menuname: "",
      storetype: "",
      brand: "",
      country: "",
      state: "",
      storesJson: [],
      is_lsm:0
    };
    this.handleSaveBtnClick = this.handleSaveBtnClick.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
  }
  componentDidMount() {
    this.props.storeTypeList();
    this.props.brandsList();
    // this.props.countryList();
    // this.props.statesList();
    document.title = "SimplexCMS | Menus"
    let tokendata: any = jwt.decode(sessionStorage.token)
    let roleId = tokendata.role_id;
    if (roleId != 7) {
      this.props.storesList(tokendata.brand_id)
    }
  }
  handleInputChange(event: { target: { name: any; value: any } }) {
    if (event.target.name == 'brand') {
      this.setState({
        [event.target.name]: event.target.value,
      });
      this.setState({ storesJson: [] });
      this.props.storesList(event.target.value)
    }else{
      this.setState({
      [event.target.name]: event.target.value,
    });
    }
    
    // if (event.target.name == "country") {
    //   if (event.target.value == "") {
    //     this.setState({ state: "" });
    //   } else {
    //     if (event.target.value !== this.state.country) {
    //       this.setState({ state: "" });
    //     }
    //   }
    // }
  }
  isMenuReady = () => {
    const { menuname, brand, storetype,storesJson } = this.state;
    let tokendata: any = jwt.decode(sessionStorage.token)
    let roleId = tokendata.role_id;
    if (roleId == 7) {
    return (
      menuname !== "" &&
      brand !== "" &&
      storetype !== "" && storesJson.length > 0
    );
    }else{
      return (
        menuname !== "" &&
        storetype !== ""
      );
    }
  };
  handleSaveBtnClick = (event: any) => {
    let { menuname, storetype, brand, storesJson,is_lsm } = this.state;
    let tokendata: any = jwt.decode(sessionStorage.token)
    let roleId = tokendata.role_id;
    let data = {
      menu_name: menuname,
      store_type_id: storetype,
      brand_id: roleId==7?brand:tokendata.brand_id,
      stores_json: JSON.stringify(storesJson),
      is_lsm
    };
    this.props.addMenu(data);
  };
  handleStoresInputChange = (e: any, index: any) => {
    if (e && e.length > 0) {
      let newArr = e.map((item:any) => {
        return {
          label: item.label,
          value: item.value
        }
      });
      this.setState({ storesJson: newArr });
    } else {
      this.setState({ storesJson: [] });
    }
  };
  render() {
    if (sessionStorage.token) {
      jwt.verify(sessionStorage.token, secretKey, (err: any, decoded: any) => {
        if (err) {
          this.props.logoutUser();
        }
      });
    } else {
      return <Redirect to="/" />
    }
    let msg;
    let messcolor;
    // let { country } = this.state;
    const {
      isInserted,
      message,
      storetype,
      brands
    } = this.props;
    if (!isInserted) {
      msg = message;
      messcolor = "red";
    }
    let tokendata: any = jwt.decode(sessionStorage.token)
    let roleId = tokendata.role_id;
    console.log(this.props.stores,'   ******************************');
    
    return (
      <div className="page">
        <CheckChanges path="/add-menu" />
        <Topbar />
        <div className="page-content d-flex align-items-stretch">
          <Sidebar />
          <div className="content-inner">
            {/*  Page Header */}
            <header className="page-header py-0">
              <div className="container-fluid">
                <div className="d-flex justify-content-between py-3">
                  <h4 className="mt-2">Menus Management</h4>
                </div>
              </div>
            </header>
            {/* Breadcrumb */}
            <div className="breadcrumb-holder container-fluid">
              <ul className="breadcrumb">
                <li className="breadcrumb-item"><Link to="/menus" className="text-primary">Menus</Link></li>
                <li className="breadcrumb-item active">Add Menu</li>
              </ul>
            </div>
            <section className="forms">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="card">
                      <div className="card-body">
                        <strong>
                          <p
                            className="text-center font-weight-light mb-4"
                            style={{ color: messcolor }}
                          >
                            {msg}
                          </p>
                        </strong>
                        <div className="row">
                          <div className="col">
                            <div className="form-group">
                              <label className="form-control-label">
                                Menu name <span className="text-danger">*</span>
                              </label>
                              <input
                                id="menuname"
                                type="text"
                                name="menuname"
                                required
                                data-msg="Please enter Menu Name"
                                className="input-material"
                                onChange={this.handleInputChange}
                              />
                            </div>
                          </div>
                          <div className="col">
                            <div className="form-group">
                              <label className="form-control-label">
                                Brand <span className="text-danger">*</span>
                              </label>
                              <select
                                name="brand"
                                className="form-control text-capitalize mt-2"
                                required
                                disabled={roleId == 7?false:true}
                                data-msg="Please select brand"
                                onChange={this.handleInputChange}
                              >
                                <option value="">Select Brand</option>
                                {brands &&
                                  brands.map(
                                    (brand, index) =>
                                      brand.is_active == 1 && (
                                        <option key={index} value={brand.brand_id} {...tokendata.brand_id === brand.brand_id && { selected: true }}>
                                          {brand.brand_name}
                                        </option>
                                      )
                                  )}
                              </select>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col" >
                            <div className="form-group">
                              <label className="form-control-label">Select Store <span className="text-danger">*</span></label>
                              <Select
                                isMulti
                                name="stores"
                                options={this.props.menuStoresOptions}
                                value={this.state.storesJson}
                                className="text-capitalize basic-multi-select mt-2"
                                classNamePrefix="select"
                                onChange={(e, i) => this.handleStoresInputChange(e, i)}
                              />
                            </div>
                          </div>
                          <div className="col">
                            <div className="form-group">
                              <label className="form-control-label">
                                Store Type <span className="text-danger">*</span>
                              </label>
                              <select
                                name="storetype"
                                className="form-control text-capitalize mt-2"
                                required
                                data-msg="Please select type"
                                onChange={this.handleInputChange}
                              >
                                <option>Select Store Type</option>
                                {storetype &&
                                  storetype.map((type, index) => (
                                    <option key={index} value={type.store_type_id}>
                                      {type.store_type_name}
                                    </option>
                                  ))}
                              </select>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-2">
                          <div style={{ marginTop: '20px' }} onClick={() => this.setState({ is_lsm: this.state.is_lsm == 1 ? 0 : 1 })}>
                              <input id="checkboxCustom1" type="checkbox" checked={this.state.is_lsm} className="checkbox-template" />
                              <label style={{ marginLeft: '10px' }} className="form-control-label">LSM
                                <span className="tooltip-btn" data-toggle="tooltip" data-placement="top" title="Check the checkbox to enable lsm">
                                  i
                                </span>
                              </label>
                            </div>
                          </div>
                        </div>
                        {/* <div className="row">
                          <div className="col">
                            <div className="form-group">
                              <label className="form-control-label">
                                Country <span className="text-danger">*</span>
                              </label>
                              <select
                                name="country"
                                className="form-control mt-2"
                                required
                                data-msg="Please enter Country"
                                onChange={this.handleInputChange}
                              >
                                <option value="">Select Country</option>
                                {countries &&
                                  countries.map((country: any, index: any) => (
                                    <option key={index} value={country.country_id}>
                                      {country.country_name}
                                    </option>
                                  ))}
                              </select>
                            </div>
                          </div>
                          <div className="col">
                            <div className="form-group">
                              <label className="form-control-label">
                                States <span className="text-danger">*</span>
                              </label>
                              <select
                                name="state"
                                className="form-control mt-2"
                                required
                                data-msg="Please select state"
                                onChange={this.handleInputChange}
                              >
                                <option value="">Select State</option>
                                {country !== "" &&
                                  states &&
                                  states.map(
                                    (state: any, index: any) =>
                                      state.country_id == country && (
                                        <option key={index} value={state.state_id}>
                                          {state.state_name}
                                        </option>
                                      )
                                  )}
                              </select>
                            </div>
                          </div>
                        </div> */}
                        <div className="form-group d-flex justify-content-end mt-4">
                          {/* <input type="reset" value="cancel" className="btn btn-danger mr-2" /> */}
                          <button onClick={this.handleSaveBtnClick} disabled={!this.isMenuReady()} className="btn btn-primary">Save</button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <Footer />
          </div>

        </div>
      </div >
    );
  }
}
const mapStateToProps = (state: any) => {
  return {
    storetype: state.store.types,
    brands: state.store.brands,
    countries: state.store.countries,
    states: state.setting.states,
    message: state.menu.message,
    isInserted: state.menu.isInserted,
    stores: state.store.stores,
    menuStoresOptions:state.store.menuStoresOptions
  };
};
const mapDispatchToProps = (dispatch: any) => {
  return {
    logoutUser: function () {
      dispatch(logoutUser());
    },
    addMenu: function (data: any) {
      dispatch(addMenu(data));
    },
    brandsList: function () {
      dispatch(brandsList());
    },
    storeTypeList: function () {
      dispatch(storeTypeList());
    },
    storesList: (brand:any) => {
      dispatch(storesListForMenuStoreRelation(brand))
    },
    countryList: function () {
      dispatch(countryList());
    },
    statesList: function () {
      dispatch(statesList());
    }
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(AddMenu);
