import React, { Component } from 'react';
import { Redirect, Link } from 'react-router-dom'
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import Topbar from '../../../components/topbar'
import Sidebar from '../../../components/sidebar'
import Footer from '../../../components/footer/main'
import jwt from 'jsonwebtoken'
import { secretKey } from '../../../secret'
import { connect } from 'react-redux'
import { itemsList, blockunblockitem, logoutUser } from '../../../redux'
import { MenuItemProps } from '../../../interfaces/menu';
import moment from 'moment';
import {currency} from '../../../client-config'
import Select from 'react-select'
import { brandsList } from '../../../redux/actions/storeAction';
function priceFormatter(cell: any) {
    return `${currency} ${cell}`;
}
class ActionFormatter extends Component<{ row: any }, {}> {
    constructor(readonly props: any) {
        super(props);
    }
    handleBlockUnblock = (id: any, is_active: any) => {
        this.props.data.blockunblockitem(id, is_active,this.props.row.item_name);
    };
    render() {
        const { row } = this.props
        return (
            <div>
                <button title={row.is_active === 0 ? "UnBlock" : "Block"} data-toggle="modal" data-target={`#blockunblock${row.menu_item_id}`} className={row.is_active === 0 ? "btn btn-outline-success mr-2" : "btn btn-outline-danger mr-2"}><i className={row.is_active === 0 ? "fa fa-unlock" : "fa fa-lock"}></i></button>
                {/* <button title="View Details" data-toggle="modal" data-target={`#itemdetails${row.menu_item_id}`} className="btn btn-outline-info"><i className="fa fa-list"></i></button> */}
                <Link title="Edit Item" className="btn btn-outline-primary ml-2" to={`/edit-item/${row.menu_item_id}`}><i className="fa fa-edit"></i></Link>
                {/* <!-- Modal--> */}
                <div id={`itemdetails${row.menu_item_id}`} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" className="modal fade text-left">
                    <div role="document" className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 id="exampleModalLabel" className="modal-title">Item Details</h4>
                                <button type="button" data-dismiss="modal" aria-label="Close" className="close"><span aria-hidden="true">×</span></button>
                            </div>
                            <div className="modal-body">
                                <div className="row mb-2">
                                    <div className="col-6">
                                        <h4 className="d-inline">Item Name:</h4>
                                        <p>{row.item_name}</p>
                                    </div>
                                    {(row.image_url) &&
                                        <div className="col-6">
                                            <img className="rounded-circle" src={row.image_url} alt="menu_item" width="100%" height="150px" />
                                        </div>
                                    }
                                </div>
                                <div className="row">
                                    <div className="col-12">
                                        <div className="form-group text-capitalize">
                                            <h4>Variant</h4>
                                            <BootstrapTable data={row.item_size && JSON.parse(row.item_size)} hover>
                                                <TableHeaderColumn dataField='size' columnTitle isKey>Size</TableHeaderColumn>
                                                <TableHeaderColumn dataField='cost' dataFormat={priceFormatter} columnTitle>Item Cost Price</TableHeaderColumn>
                                                {/* <TableHeaderColumn dataField='price' dataFormat={priceFormatter} columnTitle>Item Sale Price</TableHeaderColumn> */}
                                                <TableHeaderColumn dataField='mrp' dataFormat={priceFormatter} columnTitle>Max Retail Price</TableHeaderColumn>
                                            </BootstrapTable>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" data-dismiss="modal" className="btn btn-danger">Close</button>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <!-- Block/Unblock Modal--> */}
                <div id={`blockunblock${row.menu_item_id}`} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" className="modal fade text-left">
                    <div role="document" className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 id="exampleModalLabel" className="modal-title">{row.is_active === 0 ? "UnBlock" : "Block"} Menu Item</h4>
                                <button type="button" data-dismiss="modal" aria-label="Close" className="close"><span aria-hidden="true">×</span></button>
                            </div>
                            <div className="modal-body">
                                <p>Are you sure,you want to {row.is_active === 0 ? "UnBlock" : "Block"} this item?</p>
                            </div>
                            <div className="modal-footer">
                                <button type="button" data-dismiss="modal" className="btn btn-secondary">Close</button>
                                <button onClick={() => this.handleBlockUnblock(row.menu_item_id, row.is_active)} className="btn btn-primary">Confirm</button>
                            </div>
                        </div>
                    </div>
                </div>

            </div >
        )
    }
}
function actionFormatter(cell: any, row: any, props: any) {
    console.log("row",row)
    return (
        <ActionFormatter row={row} data={props} />
    );
}
function orderModesFormatter(cell: any, row: any, props: any) {
    return (
        <OrderModesFormatter row={row} data={props} />
    );
}
class OrderModesFormatter extends Component<{ row: any }, {}> {
    constructor(readonly props: any) {
        super(props);
    }
    IsJsonString = (str: any) => {
        try {
            JSON.parse(str);
        } catch (e) {
            return false;
        }
        return true;
    }
    render() {
        const { row } = this.props;
        return (
            <div>
                {row.item_mode &&
                    <button title="View Modes" data-toggle="modal" data-target={`#itemmodes${row.menu_item_id}`} className="btn btn-outline-info"><i className="fa fa-list"></i></button>
                }
                {/* <!-- Modal--> */}
                <div id={`itemmodes${row.menu_item_id}`} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" className="modal fade text-left">
                    <div role="document" className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 id="exampleModalLabel" className="modal-title">Order Modes</h4>
                                <button type="button" data-dismiss="modal" aria-label="Close" className="close"><span aria-hidden="true">×</span></button>
                            </div>
                            <div className="modal-body">
                                <div className="row">
                                    <div className="col-12">
                                        <div className="form-group text-capitalize">
                                            <BootstrapTable version='4' data={(row.item_mode && this.IsJsonString(row.item_mode)) ? JSON.parse(row.item_mode) : ""} hover>
                                                <TableHeaderColumn dataField='label' columnTitle isKey>Order Mode</TableHeaderColumn>
                                            </BootstrapTable>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" data-dismiss="modal" className="btn btn-danger">Close</button>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        )
    }
}
class MenuItem extends Component<MenuItemProps, { brand_id: any }> {
    constructor(readonly props: any) {
        super(props);
        this.state = {
            brand_id: {
                value: 0,
                label: "All"
            }
        }
    }
    componentDidMount() {
        this.props.brandsList();
        this.props.itemsList(this.state.brand_id.value)
        document.title = "SimplexCMS | Menu Items"
    }
    handleBrandsInputChange = (e: any, index: any) => {
        this.setState({ brand_id: { value: e.value, label: e.label } })
        if (e && e.value > 0) {
            this.props.itemsList(e.value)
        } else {
            this.props.itemsList(0)
        }
    };
    render() {
        let roleId: any = "";
        if (sessionStorage.token) {
            jwt.verify(sessionStorage.token, secretKey, (err: any, decoded: any) => {
                if (err) {
                    this.props.logoutUser();
                } else {
                    roleId = decoded.role_id
                }
            });
        } else {
            return <Redirect to="/" />
        }
        const options: any = {
            // sizePerPageList: [5,10,15],
            sizePerPage: 10, // which size per page you want to locate as default
            page: 1,
            pageStartIndex: 1, // where to start counting the pages
            paginationSize: 3,  // the pagination bar size.
            hideSizePerPage: true, //You can hide the dropdown for sizePerPage
            insertModal: () => { return <Redirect to="/add-item" /> },
            noDataText: 'Menu Items Not Found'
        };
        return (
            <div className="page">
                <Topbar />
                <div className="page-content d-flex align-items-stretch">
                    <Sidebar />
                    <div className="content-inner">
                        {/* <!-- Page Header--> */}
                        <header className="page-header py-0">
                            <div className="container-fluid">
                            <div className="row py-2">
                                    <div className="col-lg-8 col-md-8 col-12">
                                        <h4 className="mt-2">Items Management</h4>
                                    </div>
                                    {roleId == 7 &&
                                        <div className="col-lg-4 col-md-4 col-12">
                                            <div className="row">
                                                <div className="col-12">
                                                    <Select
                                                        name="brand_id"
                                                        options={this.props.brands}
                                                        value={this.state.brand_id}
                                                        className="text-capitalize select mt-2"
                                                        classNamePrefix="select"
                                                        onChange={(e, i) => this.handleBrandsInputChange(e, i)}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    }
                                </div>
                            </div>
                        </header>
                        <section className="tables">
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col">
                                        <div className="card">
                                            <div className="card-body">
                                                <BootstrapTable version="4" data={this.props.data} search={true} pagination={this.props.data.length > 10 && true} options={options} exportCSV={true} insertRow csvFileName='menus.csv' hover>
                                                    <TableHeaderColumn thStyle={{ fontSize: 15, whiteSpace: 'normal' }} tdStyle={{ fontSize: 15, whiteSpace: 'normal' }} dataField='menu_item_id' csvHeader='#' width='70' dataSort={true} isKey>#</TableHeaderColumn>
                                                    {/* <TableHeaderColumn dataField='erp_id' csvHeader='ERP ID' width='100' columnTitle>ERP ID</TableHeaderColumn>
                                                    <TableHeaderColumn dataField='pos_code' csvHeader='POS Code' width='100' columnTitle>POS Code</TableHeaderColumn> */}
                                                    <TableHeaderColumn thStyle={{ fontSize: 15, whiteSpace: 'normal' }} tdStyle={{ fontSize: 15, whiteSpace: 'normal' }} dataField='item_name' csvHeader='Item Name' width='200' columnTitle>Item name</TableHeaderColumn>
                                                    <TableHeaderColumn thStyle={{ fontSize: 15, whiteSpace: 'normal' }} tdStyle={{ fontSize: 15, whiteSpace: 'normal' }} dataField='group_name' csvHeader='Group Name' width='150' columnTitle>Group Name</TableHeaderColumn>
                                                    <TableHeaderColumn thStyle={{ fontSize: 15, whiteSpace: 'normal' }} tdStyle={{ fontSize: 15, whiteSpace: 'normal' }} dataField='menu_name' csvHeader='Menu Name' width='130' columnTitle>Menu</TableHeaderColumn>
                                                    <TableHeaderColumn thStyle={{ fontSize: 15, whiteSpace: 'normal' }} tdStyle={{ fontSize: 15, whiteSpace: 'normal' }} dataField='item_mode' csvHeader='Mode' width='100' dataFormat={orderModesFormatter} columnTitle>Mode</TableHeaderColumn>
                                                    <TableHeaderColumn thStyle={{ fontSize: 15, whiteSpace: 'normal' }} tdStyle={{ fontSize: 15, whiteSpace: 'normal' }} dataField='priority' csvHeader='Priority' width='100' columnTitle>Priority</TableHeaderColumn>
                                                    <TableHeaderColumn thStyle={{ fontSize: 15, whiteSpace: 'normal' }} tdStyle={{ fontSize: 15, whiteSpace: 'normal' }} dataField='item_start_time' csvHeader='Item Start Time' dataFormat={timeFormatter} width='150'>Item Start Time</TableHeaderColumn>
                                                    <TableHeaderColumn thStyle={{ fontSize: 15, whiteSpace: 'normal' }} tdStyle={{ fontSize: 15, whiteSpace: 'normal' }} dataField='item_close_time' csvHeader='Item Close Time' dataFormat={timeFormatter} width='150'>Item Close Time</TableHeaderColumn>
                                                    <TableHeaderColumn thStyle={{ fontSize: 15, whiteSpace: 'normal' }} tdStyle={{ fontSize: 15, whiteSpace: 'normal' }} dataField='hero_item' width='100' dataFormat={heroItemFormatter} export={false}>HeroItem</TableHeaderColumn>
                                                    <TableHeaderColumn thStyle={{ fontSize: 15, whiteSpace: 'normal' }} tdStyle={{ fontSize: 15, whiteSpace: 'normal' }} dataField='action' width='180' dataFormat={actionFormatter} formatExtraData={this.props} export={false}>Action</TableHeaderColumn>
                                                </BootstrapTable>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <Footer />
                    </div>
                </div>
            </div>
        );
    }
}
;
function heroItemFormatter(cell: any) {
    return (
        <div className="text-capitalize">
            <span {...(cell == 1 && { className: "badge badge-success p-2" })}>{cell == 1 && <i className="fa fa-check-square-o"></i>}</span>
        </div>
    )
}
function timeFormatter(cell: any) {
    return (
        <div>
            <span {...((cell !== "" && cell !== null) && { title: moment(cell).local().format('YYYY-MM-DD HH:mm') })}> {(cell !== "" && cell !== null) && moment(cell).local().format('YYYY-MM-DD HH:mm')} </span>
        </div>
    )
}
const mapStateToProps = (state: any) => {
    return {
        data: state.menu.items,
        brands: state.brand.brands
    }
}
const mapDispatchToProps = (dispatch: any) => {
    return {
        logoutUser: function () {
            dispatch(logoutUser());
        },
        itemsList: function (brand: any) {
            dispatch(itemsList(brand))
        },
        blockunblockitem: function (id: any, is_active: any,itemName:any) {
            dispatch(blockunblockitem(id, is_active,itemName))
        },
        brandsList: function () {
            dispatch(brandsList("GET_BY_BRAND"))
        },
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(MenuItem);