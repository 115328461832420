import React, { Component } from 'react';
import { EditCouponProps, EditCouponState } from '../../interfaces/coupon'
import { connect } from 'react-redux'
import { editCoupon, getCoupon, getallActiveMenus, getAllActiveGroups, logoutUser,brandsList, itemsListOfActiveMenu, searchCustomerByPhone } from '../../redux'
import { Link, Redirect } from 'react-router-dom'
import jwt from 'jsonwebtoken'
import { secretKey } from '../../secret'
import Topbar from '../../components/topbar'
import Sidebar from '../../components/sidebar'
import Footer from '../../components/footer/main'
import CheckChanges from '../../components/confirmOnLeave'
import moment from 'moment';
import Select from 'react-select';
import { storesList } from '../../redux/actions/reportAction';
var voucher_codes = require('voucher-code-generator');
class EditCoupon extends Component<EditCouponProps, EditCouponState> {
    selectInputRef:any
    selectInputRef2:any
    selectInputRef3:any
    selectInputRef4:any
    constructor(props: any) {
        super(props);
        this.state = {
            couponname: "",
            coupondesc: "",
            couponCode: "",
            couponvalue: "",
            expiryDate: "",
            startDate: "",
            percent: "",
            mode: "",
            channel: "",
            type: "",
            discountType: "",
            limit: "",
            type_id: "",
            multiJson: [],
            multiMenuJson: [],
            multiGroupJson: [],
            multiStoreJson: [],
            freeDelivery: false,
            minamount: "",
            totalusagebycus: "",
            minorders: "",
            specificCustomer: false,
            phone: null,
            multiCustomerJson: [],
            brand_id:null,
            brand_name:''
        }
        this.selectInputRef = React.createRef()
        this.selectInputRef2 = React.createRef()
        this.selectInputRef3 = React.createRef()
        this.selectInputRef4 = React.createRef()
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleCouponType = this.handleCouponType.bind(this);
        this.handleDiscountType = this.handleDiscountType.bind(this);
        this.validatePhone = this.validatePhone.bind(this);
    }
    componentDidMount() {
        let id = this.props.match.params.id;
        this.props.getCoupon(id);
        this.props.brandsList()
        // this.props.getCoupon(id);
        // this.props.storesList();
        // this.props.itemsListOfActiveMenu();
        // this.props.menusList();
        // this.props.groupsList();
        document.title = "SimplexCMS | Coupons"
    }
    UNSAFE_componentWillReceiveProps(nextProps: any, nextState: any) {
        if (!this.state.phone && this.state.brand_id == null) {
            this.setState({
                couponname: nextProps.couponData.coupon_name,
                coupondesc: nextProps.couponData.coupon_description,
                couponCode: nextProps.couponData.coupon_code,
                startDate: nextProps.couponData.start_date !== "0000-00-00 00:00:00.000000" ? moment(nextProps.couponData.start_date).local().format('YYYY-MM-DDTHH:mm') : "",
                expiryDate: moment(nextProps.couponData.expire_date).local().format('YYYY-MM-DDTHH:mm'),
                type: nextProps.couponData.type ? nextProps.couponData.type : 'menu',
                channel: (nextProps.couponData.channel && nextProps.couponData.channel !== "") ? nextProps.couponData.channel : "delivery",
                type_id: nextProps.couponData.type_id,
                limit: nextProps.couponData.limit,
                mode: nextProps.couponData.mode,
                freeDelivery: nextProps.couponData.free_delivery === 0 ? false : true,
                specificCustomer: nextProps.couponData.for_customer == 0 ? false : true,
                multiJson: nextProps.couponData.items_json && JSON.parse(nextProps.couponData.items_json),
                multiMenuJson: nextProps.couponData.multiMenuJson && JSON.parse(nextProps.couponData.multiMenuJson),
                multiGroupJson: nextProps.couponData.multiGroupJson && JSON.parse(nextProps.couponData.multiGroupJson),
                multiStoreJson: nextProps.couponData.stores_json ? JSON.parse(nextProps.couponData.stores_json) : [],
                multiCustomerJson: nextProps.couponData.customers_json ? JSON.parse(nextProps.couponData.customers_json) : [],
                discountType: (nextProps && nextProps.couponData.coupon_value === 0 ? 'percentage' : 'value'),
                minamount: nextProps.couponData.min_amount,
                minorders: nextProps.couponData.min_orders,
                totalusagebycus: nextProps.couponData.total_usage_customer
            })
            if (nextProps.couponData.coupon_value > 0) {
                this.setState({ couponvalue: nextProps.couponData.coupon_value })
            } else if (nextProps.couponData.percent && nextProps.couponData.percent > 0) {
                this.setState({ percent: nextProps.couponData.percent })
            }
        }
        if(nextProps.couponData.brand_id) {
            this.setState({
                brand_id:nextProps.couponData.brand_id.brand_id,
                brand_name:nextProps.couponData.brand_id.brand_name
            })
        }
        if (nextProps.couponData.coupon_value > 0) {
            this.setState({ couponvalue: nextProps.couponData.coupon_value })
        } else if (nextProps.couponData.percent && nextProps.couponData.percent > 0) {
            this.setState({ percent: nextProps.couponData.percent })
        }
    }
    handleInputChange(event: { target: { name: any; value: any; }; }) {
        if (event.target.value == ' ') {
            event.target.value = event.target.value.replace(/\s/g, "");
        }
        this.setState({
            [event.target.name]: event.target.value
        });
    }
    handleCouponType(event: { target: { name: any; value: any; }; }) {
        this.setState({
            type_id: "",
            [event.target.name]: event.target.value,
        });
    }
    handleDiscountType(event: { target: { name: any; value: any; }; }) {
        this.setState({
            percent: "",
            couponvalue: "",
            [event.target.name]: event.target.value,
        });
    }
    handleMenusInputChange = (e: any, index: any) => {
        if (e && e.length > 0) {
            this.setState({ multiMenuJson: e });
        } else {
            this.setState({ multiMenuJson: [] });
        }
    }
    handleGroupsInputChange = (e: any, index: any) => {
        if (e && e.length > 0) {
            this.setState({ multiGroupJson: e });
        } else {
            this.setState({ multiGroupJson: [] });
        }
    }
    handleItemsInputChange = (e: any, index: any) => {
        if (e && e.length > 0) {
            this.setState({ multiJson: e });
        } else {
            this.setState({ multiJson: [] });
        }
    }
    handleStoresInputChange = (e: any, index: any) => {
        if (e && e.length > 0) {
            this.setState({ multiStoreJson: e });
        } else {
            this.setState({ multiStoreJson: [] });
        }
    }
    handleCustomersInputChange = (e: any, index: any) => {
        if (e && e.length > 0) {
            this.setState({ multiCustomerJson: e });
        } else {
            this.setState({ multiCustomerJson: [] });
        }
    }
    isCouponReady = () => {
        const { specificCustomer, multiCustomerJson, freeDelivery, couponname, couponvalue, couponCode, startDate, expiryDate, multiJson, multiStoreJson, multiGroupJson,multiMenuJson, mode, type, discountType, limit, type_id, percent } = this.state
        if (type == "menu") {
            return (couponname !== "" && startDate !== "" && expiryDate !== "" && mode !== "" && type !== "" && discountType !== ""
                && limit !== "" && (multiMenuJson && multiMenuJson.length > 0) && (percent !== "" || couponvalue !== "") && couponCode !== "" && (specificCustomer ? multiCustomerJson.length > 0 : true));
        } else if (type == "group") {
            return (couponname !== "" && startDate !== "" && expiryDate !== "" && mode !== "" && type !== "" && discountType !== ""
                && limit !== "" && (multiGroupJson && multiGroupJson.length > 0) && (percent !== "" || couponvalue !== "") && couponCode !== "" && (specificCustomer ? multiCustomerJson.length > 0 : true));
        } else if (type == "item") {
            return (couponname !== "" && startDate !== "" && expiryDate !== "" && mode !== "" && type !== "" && discountType !== ""
                && limit !== "" && (multiJson && multiJson.length > 0) && (percent !== "" || couponvalue !== "") && couponCode !== "" && (specificCustomer ? multiCustomerJson.length > 0 : true));
        } else if (type == "store") {
            return (couponname !== "" && startDate !== "" && expiryDate !== "" && mode !== "" && type !== "" && discountType !== ""
                && limit !== "" && (multiStoreJson && multiStoreJson.length > 0) && (percent !== "" || couponvalue !== "") && couponCode !== "" && (specificCustomer ? multiCustomerJson.length > 0 : true));
        } else if (freeDelivery) {
            return (couponname !== "" && startDate !== "" && expiryDate !== "" && mode !== "" && discountType !== ""
                && limit !== "" && (percent !== "" || couponvalue !== "") && couponCode !== "" && (specificCustomer ? multiCustomerJson.length > 0 : true));
        }
    }
    blockInvalidChar = (e: any) =>
        ["e", "E", "+", "-"].includes(e.key) && e.preventDefault();
    generateCouponCode = () => {
        let code: any[] = voucher_codes.generate({
            length: 8,
            count: 1,
            prefix: "promo-",
            postfix: `-${moment().format('YYYY')}`,
            charset: voucher_codes.charset("alphanumeric")
        });
        this.setState({ couponCode: code[0] });
    }
    handleFreeDelivery = (event: { target: { name: any; value: any; }; }) => {
        const { freeDelivery } = this.state;
        if (freeDelivery) {
            this.setState({ type: "menu", })
        } else {
            this.setState({ type: "" })
        }
        this.setState({ freeDelivery: !freeDelivery, type_id: "" })
    }
    handleForSpecificCus = (event: { target: { name: any; value: any; }; }) => {
        const { specificCustomer } = this.state;
        this.setState({ specificCustomer: !specificCustomer })
    }
    validatePhone(event: { target: { name: any; value: any; }; }) {
        if (event.target.value.length < 11) {
            this.setState({ phone: event.target.value })
        } else {
            if (event.target.value.length === 11) {
                this.setState({ phone: event.target.value });
            }
        }
        if (event.target.value.length >= 4) {
            this.props.searchCustomerByPhone(event.target.value)
        }
    };
    handleBrandsInputChange = (e: any) => {
        let doc = document as any
        let v
        doc.getElementById("menu") ? doc.getElementById("menu").value = "Select Menu" : v=''
        this.selectInputRef.current ? this.selectInputRef.current.select.clearValue() : v =''
        this.selectInputRef2.current ? this.selectInputRef2.current.select.clearValue() : v =''
        this.selectInputRef3.current ? this.selectInputRef3.current.select.clearValue() : v =''
        this.selectInputRef4.current ? this.selectInputRef4.current.select.clearValue() : v =''
        this.setState({brand_id:e.target.value,brand_name:e.target.label,multiMenuJson:[],multiGroupJson:[],multiCustomerJson:[],multiStoreJson:[]});
        this.props.menusList(e.target.value);
        this.props.groupsList(e.target.value);
        this.props.itemsListOfActiveMenu(e.target.value);
        this.props.storesList(e.target.value);
    }
    handleSubmit = (event: any) => {
        let { minorders, totalusagebycus,brand_id, minamount, couponname, couponvalue, coupondesc, couponCode, channel, multiJson, multiStoreJson, startDate, expiryDate, mode, type, limit, type_id, percent, freeDelivery, specificCustomer, multiCustomerJson,multiMenuJson,multiGroupJson } = this.state;
        let id = this.props.match.params.id;
        let data: any = {
            coupon_name: couponname,
            coupon_code: couponCode,
            coupon_description: coupondesc,
            coupon_value: couponvalue,
            start_date: moment(startDate).utc(false).format('YYYY-MM-DD HH:mm:ss'),
            expire_date: moment(expiryDate).utc(false).format('YYYY-MM-DD HH:mm:ss'),
            channel: channel,
            mode: mode,
            limit: limit,
            percent: percent,
            min_amount: minamount,
            min_orders: minorders,
            brand_id,
            total_usage_customer: (totalusagebycus !== "" && totalusagebycus > 0) ? totalusagebycus : 0
        }
        if (limit !== this.props.couponData.limit) {
            data.total_usage = limit;
        }
        if (type == "menu") {
            data.type_id = null;
            data.items_json = null;
            data.stores_json = null;
            data.multiGroupJson = null;
            data.multiMenuJson = JSON.stringify(multiMenuJson);
        }else if (type == "group") {
            data.type_id = null;
            data.items_json = null;
            data.stores_json = null;
            data.multiMenuJson = null;
            data.multiGroupJson = JSON.stringify(multiGroupJson);
        } else if (type == "item") {
            data.type_id = null;
            data.multiGroupJson = null;
            data.stores_json = null;
            data.multiMenuJson = null;
            data.items_json = JSON.stringify(multiJson);
        } else if (type == "store") {
            data.type_id = null;
            data.items_json = null;
            data.stores_json = null;
            data.multiMenuJson = null;
            data.stores_json = JSON.stringify(multiStoreJson);
        }
        if (specificCustomer) {
            data.customers_json = JSON.stringify(multiCustomerJson);
            data.for_customer = 1;
        } else {
            data.for_customer = 0;
        }
        if (freeDelivery) {
            data.type_id = null;
            data.type = type;
            data.stores_json = null;
            data.items_json = null;
            data.free_delivery = 1
        } else {
            data.type = type;
            data.free_delivery = 0
        }
        this.props.editCoupon(id, data)
        event.preventDefault();
    }
    render() {
        let roleId
        if (sessionStorage.token) {
            jwt.verify(sessionStorage.token, secretKey, (err: any, decoded: any) => {
                if (err) {
                    this.props.logoutUser();
                }else {
                    roleId = decoded.role_id
                }
            });
        } else {
            return <Redirect to="/" />
        }
        const { couponData, items, menus, groups, stores, searchCustomers } = this.props;
        const { expiryDate, startDate } = this.state
        return (
            <div className="page">
                <CheckChanges path="/edit-coupon" />
                <Topbar />
                <div className="page-content d-flex align-items-stretch">
                    <Sidebar />
                    <div className="content-inner">
                        {/* <!-- Page Header--> */}
                        <header className="page-header py-0">
                            <div className="container-fluid">
                                <div className="d-flex justify-content-between py-3">
                                    <h4 className="mt-2">Compaigns Management</h4>
                                </div>
                            </div>
                        </header>
                        {/* Breadcrumb */}
                        <div className="breadcrumb-holder container-fluid">
                            <ul className="breadcrumb">
                                <li className="breadcrumb-item"><Link to="/marketing/0" className="text-primary">Compaigns</Link></li>
                                <li className="breadcrumb-item active">Edit Coupon</li>
                            </ul>
                        </div>
                        <section className="forms">
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="card">
                                            <div className="card-body">
                                                <div className="row">
                                                    <div className="col">
                                                        <div className="form-group">
                                                            <label className="form-control-label">Coupon name <span className="text-danger">*</span></label>
                                                            <input id="couponname" type="text" defaultValue={couponData.coupon_name} name="couponname" required data-msg="Please enter Coupon Name" className="input-material" onChange={this.handleInputChange} />
                                                        </div>
                                                    </div>
                                                    <div className="col">
                                                        <div className="form-group">
                                                            <label className="form-control-label">Description</label>
                                                            <input id="coupondesc" type="text" name="coupondesc" defaultValue={couponData.coupon_description} required className="input-material" onChange={this.handleInputChange} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row my-3 d-flex justify-content-between align-items-center">
                                                    <div className="col-lg-7 col-md-6 col-12">
                                                        <div className="form-group">
                                                            <label className="form-control-label">Coupon Code <span className="text-danger">*</span></label>
                                                            <input id="couponCode" type="text" name="couponCode" value={this.state.couponCode} required data-msg="Please enter Coupon Code" className="input-material" onChange={this.handleInputChange} />
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-2 col-md-2 col-6">
                                                        <button className="btn btn-sm btn-primary ml-2" title='Generate Coupon Code' onClick={() => this.generateCouponCode()}><i className="fa fa-refresh"></i></button>
                                                    </div>
                                                    <div className="col-lg-3 col-md-4 col-6 mt-3">
                                                        <div className="">
                                                            <input id="checkboxCustom2" type="checkbox" name="freeDelivery" checked={this.state.freeDelivery} onChange={this.handleFreeDelivery} className="checkbox-template" />
                                                            <label htmlFor="checkboxCustom2">Free Delivery</label>
                                                        </div>
                                                    </div>
                                                </div>
                                                {this.state.freeDelivery == false &&
                                                    <div className="row">
                                                        <div className="col">
                                                            <div className="form-group">
                                                                <label className="form-control-label">Coupon Type <span className="text-danger">*</span></label>
                                                                <select name="type" className="form-control text-capitalize mt-2" required data-msg="Please select Type" onChange={this.handleInputChange}>
                                                                    <option {...couponData.type === 'menu' && { selected: true }} value='menu' >Menu</option>
                                                                    <option {...couponData.type === 'group' && { selected: true }} value='group' >Group</option>
                                                                    <option {...couponData.type === 'item' && { selected: true }} value='item' >Item</option>
                                                                    <option {...couponData.type === 'store' && { selected: true }} value='store'>Store</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                        {roleId == 7 && <div className="col">
                                                            <div className="col">
                                                                <div className="form-group">
                                                                    <label className="form-control-label">Brand <span className="text-danger">*</span></label>
                                                                    <select disabled={roleId == 7 ? false : true} name="brand" className="form-control text-capitalize mt-2" required data-msg="Please select brand" onChange={(e) => this.handleBrandsInputChange(e)}>
                                                                        <option value="">Select Brand</option>
                                                                        {this.props.brands &&
                                                                            this.props.brands.map((brand: any, index: any) => (
                                                                                brand.is_active == 1 &&
                                                                                <option key={index} value={brand.brand_id} {...couponData.brand_id.brand_id === brand.brand_id && { selected: true }}>{brand.brand_name}</option>
                                                                            ))
                                                                        }
                                                                    </select>
                                                                </div>
                                                            </div>
                                                        </div>}
                                                        {this.state.type === "menu" &&
                                                            <div className="col">
                                                                <div className="form-group">
                                                                    <label className="form-control-label">Select Menu <span className="text-danger">*</span></label>
                                                                    <Select
                                                                        ref={this.selectInputRef}
                                                                        isMulti
                                                                        name="menus"
                                                                        options={menus}
                                                                        value={this.state.multiMenuJson}
                                                                        className="text-capitalize basic-multi-select mt-2"
                                                                        classNamePrefix="select"
                                                                        onChange={(e, i) => this.handleMenusInputChange(e, i)}
                                                                    />
                                                                    {/* <select name="type_id" className="form-control text-capitalize mt-2" required data-msg="Please select Group" onChange={this.handleInputChange}>
                                                                        <option value="">Select Menu</option>
                                                                        {menus &&
                                                                            menus.map((menu: any, index: any) => (
                                                                                <option key={index} value={menu.menu_id} {...couponData.type_id === menu.menu_id && { selected: true }}>{menu.menu_name}</option>
                                                                            ))
                                                                        }
                                                                    </select> */}
                                                                </div>
                                                            </div>
                                                        }

                                                        {this.state.type === "group" &&
                                                            <div className="col">
                                                                <div className="form-group">
                                                                    <label className="form-control-label">Select Group <span className="text-danger">*</span></label>
                                                                    <Select
                                                                        ref={this.selectInputRef2}
                                                                        isMulti
                                                                        name="groups"
                                                                        options={groups}
                                                                        defaultValue={(couponData && couponData.multiGroupJson) && JSON.parse(couponData.multiGroupJson)}
                                                                        className="text-capitalize basic-multi-select mt-2"
                                                                        classNamePrefix="select"
                                                                        onChange={(e, i) => this.handleGroupsInputChange(e, i)}
                                                                    />
                                                                    {/* <select name="type_id" className="form-control text-capitalize mt-2" required data-msg="Please select Group" onChange={this.handleInputChange}>
                                                                        <option value="">Select Group</option>
                                                                        {groups &&
                                                                            groups.map((group: any, index: any) => (
                                                                                <option key={index} value={group.group_id} {...couponData.type_id === group.group_id && { selected: true }}>{group.group_name} ({group.menu_name})</option>
                                                                            ))
                                                                        }
                                                                    </select> */}
                                                                </div>
                                                            </div>
                                                        }
                                                        {this.state.type === "item" &&
                                                            <div className="col-md-6">
                                                                <div className="form-group">
                                                                    <label className="form-control-label">Select Items <span className="text-danger">*</span></label>
                                                                    <Select
                                                                        ref={this.selectInputRef3}
                                                                        isMulti
                                                                        name="items"
                                                                        options={items}
                                                                        defaultValue={(couponData && couponData.items_json) && JSON.parse(couponData.items_json)}
                                                                        className="text-capitalize basic-multi-select mt-2"
                                                                        classNamePrefix="select"
                                                                        onChange={(e, i) => this.handleItemsInputChange(e, i)}
                                                                    />
                                                                </div>
                                                            </div>
                                                        }
                                                        {this.state.type === "store" &&
                                                            <div className="col-md-6">
                                                                <div className="form-group">
                                                                    <label className="form-control-label">Select Stores <span className="text-danger">*</span></label>
                                                                    <Select
                                                                        ref={this.selectInputRef3}
                                                                        isMulti
                                                                        name="stores"
                                                                        options={stores}
                                                                        defaultValue={(couponData && couponData.stores_json) && JSON.parse(couponData.stores_json)}
                                                                        className="text-capitalize basic-multi-select mt-2"
                                                                        classNamePrefix="select"
                                                                        onChange={(e, i) => this.handleStoresInputChange(e, i)}
                                                                    />
                                                                </div>
                                                            </div>
                                                        }
                                                    </div>
                                                }
                                                <div className="row">
                                                    <div className="col-lg-4 col-md-4 col-6">
                                                        <div className="form-group">
                                                            <label className="form-control-label">Ordering Channel <span className="text-danger">*</span></label>
                                                            <select name="channel" className="form-control text-capitalize mt-2" required data-msg="Please select Type" onChange={this.handleInputChange}>
                                                                <option {...couponData.channel == 'all' && { selected: true }} value='all'>All</option>
                                                                <option {...couponData.channel == 'delivery' && { selected: true }} value='delivery'>Delivery</option>
                                                                <option {...couponData.channel == 'pickup' && { selected: true }} value='pickup' >Pick Up</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-4 col-md-4 col-6">
                                                        <div className="form-group">
                                                            <label className="form-control-label">Ordering Mode <span className="text-danger">*</span></label>
                                                            <select name="mode" className="form-control text-capitalize mt-2" required data-msg="Please select Type" onChange={this.handleInputChange}>
                                                                <option {...couponData.mode == 'all' && { selected: true }} value='all' >All</option>
                                                                <option {...couponData.mode == 'online' && { selected: true }} value='online'>Online</option>
                                                                <option {...couponData.mode == 'callcenter' && { selected: true }} value='callcenter' >Call Center</option>
                                                                <option {...couponData.mode == 'mobile' && { selected: true }} value='mobile' >Mobile</option>
                                                                <option {...couponData.mode == 'qr' && { selected: true }} value='qr' >QR</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-4 col-md-4 col-12">
                                                        <div className="form-group">
                                                            <label className="form-control-label">Limit <span className="text-danger">*</span></label>
                                                            <input id="limit" type="number" name="limit" min="1" defaultValue={couponData.limit} required data-msg="Please enter Coupon Value" className="input-material" onChange={this.handleInputChange} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col">
                                                        <div className="form-group">
                                                            <label className="form-control-label">Coupon Type <span className="text-danger">*</span></label>
                                                            <select name="discountType" className="form-control text-capitalize mt-2" required data-msg="Please select Type" onChange={this.handleDiscountType}>
                                                                <option {...this.state.discountType === 'value' && { selected: true }} value='value'>Value</option>
                                                                <option {...this.state.discountType === 'percentage' && { selected: true }} value='percentage' >Percentage</option>
                                                            </select>
                                                        </div>
                                                    </div>

                                                    {this.state.discountType === "percentage" &&
                                                        <div className="col">
                                                            <div className="form-group">
                                                                <label className="form-control-label">Coupon Percentage <span className="text-danger">*</span></label>
                                                                <input id="percent" defaultValue={couponData.percent} min="1" onKeyDown={this.blockInvalidChar} type="number" name="percent" required data-msg="Please enter Coupon Percentage" className="input-material" onChange={this.handleInputChange} />
                                                            </div>
                                                        </div>
                                                    }
                                                    {this.state.discountType === "value" &&
                                                        <div className="col">
                                                            <div className="form-group">
                                                                <label className="form-control-label">Coupon value <span className="text-danger">*</span></label>
                                                                <input id="couponvalue" defaultValue={couponData.coupon_value} min="1" onKeyDown={this.blockInvalidChar} type="number" name="couponvalue" required data-msg="Please enter Coupon Value" className="input-material" onChange={this.handleInputChange} />
                                                            </div>
                                                        </div>
                                                    }
                                                </div>
                                                <div className="row">
                                                    <div className="col">
                                                        <div className="form-group">
                                                            <label className="form-control-label">Min total</label>
                                                            <input id="minamount" type="number" name="minamount" defaultValue={couponData.min_amount} min="0" onKeyDown={this.blockInvalidChar} className="input-material" onChange={this.handleInputChange} />
                                                        </div>
                                                    </div>
                                                    <div className="col">
                                                        <div className="form-group">
                                                            <label className="form-control-label">Coupon usage/customer</label>
                                                            <input id="totalusagebycus" type="number" name="totalusagebycus" defaultValue={couponData.total_usage_customer} min="0" onKeyDown={this.blockInvalidChar} className="input-material" onChange={this.handleInputChange} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col">
                                                        <div className="form-group">
                                                            <label className="form-control-label">Min Orders</label>
                                                            <input id="minorders" type="number" name="minorders" min="0" defaultValue={couponData.min_orders} onKeyDown={this.blockInvalidChar} className="input-material" onChange={this.handleInputChange} />
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-3 col-md-4 col-6 mt-3">
                                                        <div className="">
                                                            <input id="checkboxCustom3" type="checkbox" name="specificCustomer" checked={this.state.specificCustomer} onChange={this.handleForSpecificCus} className="checkbox-template" />
                                                            <label htmlFor="checkboxCustom3">For Specific Customer</label>
                                                        </div>
                                                    </div>
                                                </div>
                                                {this.state.specificCustomer &&
                                                    <div className="row">
                                                        <div className="col-md-6">
                                                            <div className="form-group">
                                                                <label className="form-control-lable">Phone</label>
                                                                <input id="phone" type="number" value={this.state.phone} onKeyDown={this.blockInvalidChar} name="phone" required data-msg="Please enter Phone" className="input-material" onChange={this.validatePhone} />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <div className="form-group">
                                                                <label className="form-control-label">Select Customers <span className="text-danger">*</span></label>
                                                                <Select
                                                                    isMulti
                                                                    name="customers"
                                                                    options={searchCustomers}
                                                                    value={this.state.multiCustomerJson}
                                                                    className="text-capitalize basic-multi-select mt-2"
                                                                    classNamePrefix="select"
                                                                    onChange={(e, i) => this.handleCustomersInputChange(e, i)}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                }
                                                <div className="row">
                                                    <div className="col">
                                                        <div className="form-group">
                                                            <label className="form-control-label">Valid from <span className="text-danger">*</span></label>
                                                            <input id="startDate" type="datetime-local" name="startDate" value={startDate} required data-msg="Please enter Start Date" className="input-material" onChange={this.handleInputChange} />
                                                        </div>
                                                    </div>
                                                    <div className="col">
                                                        <div className="form-group">
                                                            <label className="form-control-label">Valid till <span className="text-danger">*</span></label>
                                                            <input id="expiryDate" type="datetime-local" name="expiryDate" value={expiryDate} required data-msg="Please enter Expire Date" className="input-material" onChange={this.handleInputChange} />
                                                        </div>
                                                    </div>
                                                </div>
                                                {/* <div className="row">
                                                        <div className="col py-4">
                                                            <div className="">
                                                                <input id="checkboxCustom1" type="checkbox" name="status" {...(couponData.is_archive === 0 && { defaultChecked: true }) || (couponData.is_archive === 1 && { defaultChecked: false })} onChange={this.handleChangeChk} className="checkbox-template" />
                                                                <label htmlFor="checkboxCustom1">Active</label>
                                                            </div>
                                                        </div>
                                                    </div> */}
                                                <div className="form-group float-right">
                                                    <button onClick={this.handleSubmit} disabled={!this.isCouponReady()} className="btn btn-primary">Update Coupon</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <Footer />
                    </div>
                </div>
            </div>
        );
    }
}
const mapStateToProps = (state: any) => {
    return {
        couponData: state.coupon.couponData,
        brands: state.store.brands,
        menus: state.menu.allActiveMenus,
        groups: state.menu.allActiveGroups,
        items: state.menu.allItemsOfActiveMenu,
        message: state.coupon.message,
        isUpdated: state.coupon.isUpdated,
        stores: state.report.stores,
        searchCustomers: state.customer.searchCustomers
    }
}
const mapDispatchToProps = (dispatch: any) => {
    return {
        logoutUser: function () {
            dispatch(logoutUser());
        },
        brandsList: function () {
            dispatch(brandsList())
        },
        editCoupon: function (id: any, data: any) {
            dispatch(editCoupon(id, data));
        },
        getCoupon: function (id: number) {
            dispatch(getCoupon(id));
        },
        menusList: function (brand:any) {
            dispatch(getallActiveMenus(brand))
        },
        groupsList: function (brand:any) {
            dispatch(getAllActiveGroups(brand))
        },
        itemsListOfActiveMenu: function (brand:any) {
            dispatch(itemsListOfActiveMenu(brand))
        },
        storesList: (brand:any) => {
            dispatch(storesList(brand))
        },
        searchCustomerByPhone: (phone: any) => {
            dispatch(searchCustomerByPhone(phone))
        }
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(EditCoupon);