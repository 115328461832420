export const ORDERS_LIST = "ORDERS_LIST"
export const ORDER_ITEMS_LIST = "ORDER_ITEMS_LIST"
export const ORDER_STATUS_LIST = "ORDER_STATUS_LIST"
export const DELIVERY_BOYS = "DELIVERY_BOYS"
export const UPDATE_ORDER = "UPDATE_ORDER"
export const OUTBOUND_CONTACTS = "OUTBOUND_CONTACTS"
export const CANCEL_ORDER_REASONS = "CANCEL_ORDER_REASONS"
export const FILTERED_ORDERS = "FILTERED_ORDERS"
export const TIMER_STATUS = "TIMER_STATUS"
export const ORDERS_BY_STORE = "ORDERS_BY_STORE"
export const UPDATE_LOADER = "UPDATE_LOADER"
export const UPDATE_TIMER="UPDATE_TIMER"