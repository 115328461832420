import React, { Component } from 'react';
import { Link } from 'react-router-dom'

export default class Footer extends Component<{}, {}> {
    constructor(readonly props: any) {
        super(props);
    }
    render() {
        return (
            <footer className="main-footer">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-sm-6">
                            <p>&copy; SimpleX All rights reserved 2020 - {(new Date().getFullYear())}</p>
                        </div>
                        <div className="col-sm-6 text-right">
                            <p>Powered By <Link to="#" className="external">SimpleX Technology Solutions</Link> </p>
                        </div>
                    </div>
                </div>
            </footer >
        );
    }
};