import React, { Component } from 'react';
import Sidebar from '../../components/sidebar';
import Topbar from '../../components/topbar'
import Footer from '../../components/footer/main'
import { connect } from 'react-redux';
import Select from 'react-select';
import { toast } from 'react-toastify';
import { createAudienceList, topCustomerInsightsFilteration } from '../../redux/actions/marketing';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import moment from 'moment';
import jwt from 'jsonwebtoken'
import { secretKey } from '../../secret'
import { brandsList } from '../../redux/actions/storeAction';
import Loader from "react-loader-spinner";
import { currency } from '../../client-config'
import _ from 'lodash';
import { Redirect } from 'react-router-dom';
import { logoutUser } from '../../redux';

class CustomersTabularView extends Component<{ data: any[], audienceList: any[], loading: any, topCustomerInsightsFilteration: any, createAudienceList: any, brands: any[], brandsList: () => {}, logoutUser: () => {} }, { [x: number]: any, audience_name: any, audience_desc: any, filterType: any,limit: any, brand_id: any }> {
    constructor(props: any) {
        super(props);
        this.state = {
            filterType: 'recent_30_days_customers',
            audience_name: "",
            audience_desc: "",
            limit: "",
            brand_id: {
                value: 0,
                label: "All"
            }
        }
    }
    componentDidMount(): void {
        let obj = {
            filter_type: 'recent_30_days_customers'
        }
        this.props.brandsList()
        this.props.topCustomerInsightsFilteration(obj, this.state.brand_id.value)
    }
    handleBrandsInputChange = (e: any, index: any) => {
        this.setState({ brand_id: { label: e.label, value: e.value } });
    }
    filterDataReady = () => {
        let { filterType } = this.state;
        return (filterType !== "");
    }

    isAudienceReady = () => {
        const { audience_name, audience_desc } = this.state;
        return (audience_name !== '' && audience_desc !== '')
    }


    handleInputChange = (event: { target: { name: any; value: any; }; }) => {
        this.setState({
            [event.target.name]: event.target.value,
        });
    }
    submitData = () => {
        let { filterType,limit } = this.state;
        if (filterType == 'recent_30_days_customers') {
            let obj:any = {
                filter_type: 'recent_30_days_customers'
            }
            if(limit!==""){
                obj.limit=limit
            }
            this.props.topCustomerInsightsFilteration(obj, this.state.brand_id.value)
        } else if (filterType == 'total_spend') {
            let obj:any = {
                filter_type: 'total_spend'
            }
            if(limit!==""){
                obj.limit=limit
            }
            this.props.topCustomerInsightsFilteration(obj, this.state.brand_id.value)
        } else if (filterType === 'total_orders') {
            let obj:any = {
                filter_type: 'total_orders'
            }
            if(limit!==""){
                obj.limit=limit
            }
            this.props.topCustomerInsightsFilteration(obj, this.state.brand_id.value)
        } else if (filterType === 'single_order') {
            let obj:any = {
                filter_type: 'single_order'
            }
            if(limit!==""){
                obj.limit=limit
            }
            this.props.topCustomerInsightsFilteration(obj, this.state.brand_id.value)
        }

    }

    createAudience = () => {
        const { audienceList } = this.props;
        const { audience_name, audience_desc, brand_id } = this.state;
        let objData = {
            name: audience_name,
            desc: audience_desc,
            customerArr: audienceList,
            brand_id: brand_id.value
        }
        this.props.createAudienceList(objData);
    }

    render() {
        let roleId
        if (sessionStorage.token) {
            jwt.verify(sessionStorage.token, secretKey, (err: any, decoded: any) => {
                if (err) {
                    this.props.logoutUser();
                } else {
                    roleId = decoded.role_id
                }
            });
        } else {
            return <Redirect to="/" />
        }
        const options: any = {
            // sizePerPageList: [5,10,15],
            sizePerPage: 10, // which size per page you want to locate as default
            page: 1,
            pageStartIndex: 1, // where to start counting the pages
            paginationSize: 3,  // the pagination bar size.
            hideSizePerPage: true, //You can hide the dropdown for sizePerPage
            noDataText: 'Customers Not Found'
        };
        return (
            <div className="page">
                <Topbar />
                <div className="page-content d-flex align-items-stretch">
                    <Sidebar />
                    <div className="content-inner">
                        <header className="page-header py-0">
                            <div className="container-fluid">
                                <div className="row py-2">
                                    <div className="d-flex col-lg-8 col-md-8 col-12 justify-content-between py-3">
                                        <h4 className="mt-2">Top Customers Insights</h4>
                                    </div>
                                </div>
                            </div>
                        </header>
                        <div className="container-fluid mt-3">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="card">
                                        <div className="card-body">
                                            <div className="row">
                                                {roleId == 7 &&
                                                    <div className="col-lg-6 col-12">
                                                        <label className="form-control-label">
                                                            Brands <span className="text-danger">*</span>
                                                        </label>
                                                        <Select
                                                            name="brand_id"
                                                            options={this.props.brands}
                                                            value={this.state.brand_id}
                                                            className="text-capitalize select"
                                                            classNamePrefix="select"
                                                            onChange={(e, i) => this.handleBrandsInputChange(e, i)}
                                                        />
                                                    </div>
                                                }
                                                <div className="col-lg-6 col-12">
                                                    <div className="form-group d-flex flex-column ">
                                                        <label className="form-control-label">
                                                            Data filter by  <span className="text-danger">*</span>
                                                        </label>
                                                        <select onChange={this.handleInputChange} name="filterType" value={this.state.filterType} className="form-control text-capitalize" required data-msg="Please select filter type">
                                                            <option value="">
                                                                Select Type
                                                            </option>
                                                            <option value="recent_30_days_customers">
                                                                Past 30 days Customers
                                                            </option>
                                                            <option value="total_spend">
                                                                Top Order Value
                                                            </option>
                                                            <option value="total_orders">
                                                                Top Transactions
                                                            </option>
                                                            <option value="single_order">
                                                                Single Transaction
                                                            </option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className="col-lg-6 col-12">
                                                    <div className="form-group">
                                                        <label className="form-control-label">
                                                            Limit Data
                                                        </label>
                                                        <select name="limit" onChange={this.handleInputChange} className="form-control text-capitalize">
                                                            <option value="">
                                                                none
                                                            </option>
                                                            <option value="1">
                                                                upto 1
                                                            </option>
                                                            <option value="5">
                                                                upto 5
                                                            </option>
                                                            <option value="100">
                                                                upto 100
                                                            </option>
                                                            <option value="1000">
                                                                upto 1000
                                                            </option>
                                                            <option value="10000">
                                                                upto 10000
                                                            </option>
                                                            <option value="50000">
                                                                upto 50000
                                                            </option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='row'>
                                                <div className="col-12">
                                                    <div className="form-group d-flex justify-content-end mt-4">
                                                        <button disabled={!this.filterDataReady()} onClick={this.submitData} className='btn btn-primary'>Submit</button>
                                                        {this.props.data.length >= 0 &&
                                                            <button data-toggle="modal" disabled={this.props.data.length == 0} className='btn btn-dark ml-4' data-target={`#createAudienceModal`} >Create Audience</button>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {this.props.loading ?
                                <Loader style={{ display: "block", position: "fixed", top: "60%", left: "60%", transform: "translate(-50%, -50%)" }} type="ThreeDots" color="#555555" height={70} width={70} />
                                :
                                <section className="tables no-padding">
                                    <div className="row">
                                        <div className="col">
                                            <div className="card">
                                                <div className="card-body">
                                                    <BootstrapTable version='4' data={this.props.data} search={true} pagination={this.props.data.length > 10 && true} options={options} exportCSV={false} hover>
                                                        <TableHeaderColumn thStyle={{ fontSize: 10, fontWeight: 'bold', whiteSpace: 'normal' }} tdStyle={{ fontSize: 10, whiteSpace: 'normal' }} dataField="customer_id" csvHeader='#' width='50' dataSort={true} isKey>#</TableHeaderColumn>
                                                        <TableHeaderColumn thStyle={{ fontSize: 10, fontWeight: 'bold', whiteSpace: 'normal' }} tdStyle={{ fontSize: 10, whiteSpace: 'normal' }} dataField='login_name' csvHeader='Name' width='100' columnTitle>Name</TableHeaderColumn>
                                                        <TableHeaderColumn thStyle={{ fontSize: 10, fontWeight: 'bold', whiteSpace: 'normal' }} tdStyle={{ fontSize: 10, whiteSpace: 'normal' }} dataField='phone_number' csvHeader='Phone' width='100' columnTitle>Mobile Number</TableHeaderColumn>
                                                        <TableHeaderColumn thStyle={{ fontSize: 10, fontWeight: 'bold', whiteSpace: 'normal' }} tdStyle={{ fontSize: 10, whiteSpace: 'normal' }} dataField='email_address' csvHeader='Email' width='100' columnTitle>Email</TableHeaderColumn>
                                                        {/* <TableHeaderColumn thStyle={{ fontSize: 10, fontWeight: 'bold', whiteSpace: 'normal' }} tdStyle={{ fontSize: 10, whiteSpace: 'normal' }} dataField='address' csvHeader='Address' width='100' columnTitle>Address</TableHeaderColumn> */}
                                                        <TableHeaderColumn thStyle={{ fontSize: 10, fontWeight: 'bold', whiteSpace: 'normal' }} tdStyle={{ fontSize: 10, whiteSpace: 'normal' }} dataField='member_since' csvHeader='Member Since' dataFormat={dateFormatter} width='100' columnTitle>Member Since</TableHeaderColumn>
                                                        <TableHeaderColumn thStyle={{ fontSize: 10, fontWeight: 'bold', whiteSpace: 'normal' }} tdStyle={{ fontSize: 10, whiteSpace: 'normal' }} dataField='total_spend' csvHeader='Total Spend' dataFormat={priceFormatter} width='70' columnTitle>Total Spend</TableHeaderColumn>
                                                        <TableHeaderColumn thStyle={{ fontSize: 10, fontWeight: 'bold', whiteSpace: 'normal' }} tdStyle={{ fontSize: 10, whiteSpace: 'normal' }} dataField='total_orders' csvHeader='Total Transactions' width='70' columnTitle>Total Transactions</TableHeaderColumn>
                                                        <TableHeaderColumn thStyle={{ fontSize: 10, fontWeight: 'bold', whiteSpace: 'normal' }} tdStyle={{ fontSize: 10, whiteSpace: 'normal' }} dataField='order_channel' csvHeader='Ordering Channel' width='70' columnTitle>Ordering Channel</TableHeaderColumn>
                                                        <TableHeaderColumn thStyle={{ fontSize: 10, fontWeight: 'bold', whiteSpace: 'normal' }} tdStyle={{ fontSize: 10, whiteSpace: 'normal' }} dataField='payment_method' csvHeader='Payment Method' width='70' columnTitle>Payment Method</TableHeaderColumn>
                                                    </BootstrapTable>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </section>
                            }
                            <section>
                                <div id={`createAudienceModal`} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" className="modal fade text-left">
                                    <div role="document" className="modal-dialog">
                                        <div className="modal-content">
                                            <div className="modal-header">
                                                <h4 id="exampleModalLabel" className="modal-title">Create Audience List</h4>
                                                <button type="button" data-dismiss="modal" aria-label="Close" className="close"><span aria-hidden="true">×</span></button>
                                            </div>
                                            <div className="modal-body">
                                                <div className="row">
                                                    <div className="col-12">
                                                        <div className="form-group">
                                                            <label className="form-control-label">
                                                                Audience Name <span className="text-danger">*</span>
                                                            </label>
                                                            <input id="percent" type="text" name="audience_name" required data-msg="Please enter audience name" className="input-material" onChange={this.handleInputChange} />
                                                        </div>
                                                    </div>
                                                    <div className="col-12">
                                                        <div className="form-group">
                                                            <label className="form-control-label">
                                                                Audience Description <span className="text-danger">*</span>
                                                            </label>
                                                            <input id="percent" type="text" name="audience_desc" required data-msg="Please enter audience description" className="input-material" onChange={this.handleInputChange} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="modal-footer">
                                                <button type="button" data-dismiss="modal" className="btn btn-secondary">Close</button>
                                                <button disabled={!this.isAudienceReady()} onClick={this.createAudience} className="btn btn-primary">Confirm</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                            <Footer />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
function priceFormatter(cell: any, row: any) {
    if (row.total_spend) {
        return `${currency} ${cell}`
    }
    else {
        return 'N/A'
    }
}
function dateFormatter(cell: any, row: any) {
    if (row.member_since) {
        return moment(row.member_since).local().format('YYYY-MM-DD HH:mm')
    }
    else {
        return 'N/A'
    }
}

const mapStateToProps = (state: any) => {
    return {
        data: state.marketing.topCustomerInsightData,
        audienceList: state.marketing.audienceList,
        brands: state.brand.brands,
        loading: state.marketing.loading
    }
}
const mapDispatchToProps = (dispatch: any) => {
    return {
        logoutUser: function () {
            dispatch(logoutUser());
        },
        topCustomerInsightsFilteration: function (data: any, brand: any) {
            dispatch(topCustomerInsightsFilteration(data, brand))
        },
        createAudienceList: function (data: any) {
            dispatch(createAudienceList(data))
        },
        brandsList: function () {
            dispatch(brandsList("GET_BY_BRAND"))
        },
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(CustomersTabularView);