import React, { Component } from 'react';
import { Redirect, Link } from 'react-router-dom'
// import DataTable from 'react-data-table-component'
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import Topbar from '../../components/topbar'
import Sidebar from '../../components/sidebar'
import Footer from '../../components/footer/main'
import jwt from 'jsonwebtoken'
import { secretKey } from '../../secret'
import { connect } from 'react-redux'
import { logoutUser, customersList, blockunblock, orderHistoryByCusId, favoritesList } from '../../redux'
import { CustomerProps } from '../../interfaces/customers';

function statusFormatter(cell: any, row: any) {
    return (
        <div className="text-capitalize">
            <span {...(row.order_status_code === 1 && { className: "badge badge-primary p-1" }) || (row.order_status_code === 2 && { className: "badge badge-info p-1" }) || (row.order_status_code === 3 && { className: "badge badge-secondary p-1" }) || (row.order_status_code === 4 && { className: "badge badge-success p-1" }) || (row.order_status_code === 5 && { className: "badge badge-danger p-1" })}>{row.order_status_description}</span>
        </div>
    )
}
class ActionFormatter extends Component<{ row: any }, {}> {
    constructor(readonly props: any) {
        super(props);
    }
    handleBlockUnblock = (id: any, is_active: any) => {
        this.props.data.blockunblock(id, is_active);
    };
    render() {
        const { row } = this.props
        return (
            <div>
                <button title={row.is_active === 0 ? "UnBlock" : "Block"} data-toggle="modal" data-target={`#blockunblock${row.customer_id}`} className={row.is_active === 0 ? "btn btn-outline-success" : "btn btn-outline-danger"}><i className={row.is_active === 0 ? "fa fa-unlock" : "fa fa-lock"}></i></button>
                {/* <Link title="Edit Customer" className="btn btn-outline-primary ml-2" to={`/edit-customer/${row.customer_id}`}><i className="fa fa-edit"></i></Link> */}
                {/* <!-- Block/Unblock Modal--> */}
                <div id={`blockunblock${row.customer_id}`} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" className="modal fade text-left">
                    <div role="document" className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 id="exampleModalLabel" className="modal-title">{row.is_active === 0 ? "UnBlock" : "Block"} Customer</h4>
                                <button type="button" data-dismiss="modal" aria-label="Close" className="close"><span aria-hidden="true">×</span></button>
                            </div>
                            <div className="modal-body">
                                <p>Are you sure,you want to {row.is_active === 0 ? "UnBlock" : "Block"} this customer?</p>
                            </div>
                            <div className="modal-footer">
                                <button type="button" data-dismiss="modal" className="btn btn-secondary">Close</button>
                                <button onClick={() => this.handleBlockUnblock(row.customer_id, row.is_active)} className="btn btn-primary">Confirm</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        )
    }
}
function actionFormatter(cell: any, row: any, props: any) {
    return (
        <ActionFormatter row={row} data={props} />
    );
}
class OrdersFormatter extends Component<{ row: any }, {}> {
    constructor(readonly props: any) {
        super(props);
    }
    showitems = (id: any) => {
        this.props.data.orderHistoryByCusId(id)
    }
    render() {
        const { row, data } = this.props
        return (
            <div>
                <button title="View Orders" className="btn btn-outline-primary mr-2" data-toggle="modal" data-target={`#vieworders${row.customer_id}`} onClick={() => this.showitems(row.customer_id)}><i className="fa fa-list"></i></button>
                {/* <!--Orders Modal--> */}
                <div id={`vieworders${row.customer_id}`} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" className="modal fade text-left">
                    <div role="document" className="modal-dialog modal-lg">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 id="exampleModalLabel" className="modal-title">Orders History</h4>
                                <button type="button" data-dismiss="modal" aria-label="Close" className="close"><span aria-hidden="true">×</span></button>
                            </div>
                            <div className="modal-body">
                                <div className="row">
                                    <div className="col-12">
                                        <div className="form-group">
                                            {(data.orders.length > 0) ?
                                                <BootstrapTable version='4' data={data.orders} hover>
                                                    <TableHeaderColumn dataField="order_id" columnTitle isKey>#</TableHeaderColumn>
                                                    <TableHeaderColumn dataField='login_name' columnTitle>Name</TableHeaderColumn>
                                                    {/* <TableHeaderColumn dataField='payment_method' columnTitle>Payment Method</TableHeaderColumn> */}
                                                    <TableHeaderColumn dataField='order_grossprice' columnTitle>Price</TableHeaderColumn>
                                                    <TableHeaderColumn dataField='action' dataFormat={statusFormatter}>Status</TableHeaderColumn>
                                                    <TableHeaderColumn dataField='date_created' columnTitle>Order Received</TableHeaderColumn>
                                                </BootstrapTable> :
                                                <p className="text-center">Orders Not Found</p>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" data-dismiss="modal" className="btn btn-danger">Close</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        )
    }
}
function ordersHistory(cell: any, row: any, props: any) {
    return (
        <OrdersFormatter row={row} data={props} />
    );
}
class FavMenuItems extends Component<{ row: any }, {}> {
    constructor(readonly props: any) {
        super(props);
    }
    showitems = (id: any) => {
        this.props.data.favoritesList(id)
    }
    render() {
        const { row, data } = this.props
        return (
            <div>
                <button title="View Favorite Items" className="btn btn-outline-primary mr-2" data-toggle="modal" data-target={`#viewitems${row.customer_id}`} onClick={() => this.showitems(row.customer_id)}><i className="fa fa-list"></i></button>
                {/* <!--Orders Modal--> */}
                <div id={`viewitems${row.customer_id}`} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" className="modal fade text-left">
                    <div role="document" className="modal-dialog modal-lg">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 id="exampleModalLabel" className="modal-title">Favorite Menu Items</h4>
                                <button type="button" data-dismiss="modal" aria-label="Close" className="close"><span aria-hidden="true">×</span></button>
                            </div>
                            <div className="modal-body">
                                <div className="row">
                                    <div className="col-12">
                                        <div className="form-group">
                                            {(data.favItems.length > 0) ?
                                                <BootstrapTable version='4' data={data.favItems} hover>
                                                    <TableHeaderColumn dataField="wish_id" columnTitle isKey>#</TableHeaderColumn>
                                                    <TableHeaderColumn dataField='login_name' columnTitle>Name</TableHeaderColumn>
                                                    <TableHeaderColumn dataField='email_address' columnTitle>Email</TableHeaderColumn>
                                                    <TableHeaderColumn dataField='menu_name' columnTitle>Menu</TableHeaderColumn>
                                                    <TableHeaderColumn dataField='item_name' columnTitle>Menu Item</TableHeaderColumn>
                                                </BootstrapTable> :
                                                <p className="text-center">Favorite Menu Items Not Found</p>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" data-dismiss="modal" className="btn btn-danger">Close</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        )
    }
}
function favMenuItem(cell: any, row: any, props: any) {
    return (
        <FavMenuItems row={row} data={props} />
    );
}
function birthday(cell: any, row: any) {
    return (
        <p>{cell.search("/") !== -1 && cell}</p>
    )

}
class Customers extends Component<CustomerProps, {}> {
    constructor(readonly props: any) {
        super(props);
    }
    componentDidMount() {
        this.props.customersList();
        document.title = "SimplexCMS | Customers"
    }
    render() {
        if (sessionStorage.token) {
            jwt.verify(sessionStorage.token, secretKey, (err: any, decoded: any) => {
                if (err) {
                    this.props.logoutUser();
                }
            });
        } else {
            return <Redirect to="/" />
        }
        console.log(this.props.data)
        const options: any = {
            sizePerPage: 10, // which size per page you want to locate as default
            page: 1,
            pageStartIndex: 1, // where to start counting the pages
            paginationSize: 3,  // the pagination bar size.
            hideSizePerPage: true, //You can hide the dropdown for sizePerPage
            noDataText: 'Customers Not Found'
        };
        return (
            <div className="page">
                <Topbar />
                <div className="page-content d-flex align-items-stretch">
                    <Sidebar />
                    <div className="content-inner">
                        {/* <!-- Page Header--> */}
                        <header className="page-header py-0">
                            <div className="container-fluid">
                                <div className="d-flex justify-content-between py-3">
                                    <h4 className="mt-2">Customer Management</h4>
                                </div>
                            </div>
                        </header>
                        <section className="tables">
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col">
                                        <div className="card">
                                            <div className="card-body">
                                                <BootstrapTable version='4' data={this.props.data} search={true} pagination={this.props.data.length > 10 && true} options={options} exportCSV={true} csvFileName='blockcustomers.csv' hover>
                                                    <TableHeaderColumn dataField='customer_id' csvHeader='#' width='50' dataSort={true} isKey>#</TableHeaderColumn>
                                                    <TableHeaderColumn dataField='login_name' csvHeader='Name' width='100' columnTitle>Name</TableHeaderColumn>
                                                    <TableHeaderColumn dataField='email_address' csvHeader='Email' width='100' columnTitle>Email</TableHeaderColumn>
                                                    <TableHeaderColumn dataField='phone_number' csvHeader='Phone' width='100' columnTitle>Phone</TableHeaderColumn>
                                                    <TableHeaderColumn dataField='gender' csvHeader='Gender' width='100' columnTitle>Gender</TableHeaderColumn>
                                                    <TableHeaderColumn dataField='customer_birthday' dataFormat={birthday} csvHeader='Birthday' width='100' columnTitle>Birthday</TableHeaderColumn>
                                                    <TableHeaderColumn dataField='town_city' csvHeader='City' width='100' columnTitle>City</TableHeaderColumn>
                                                    <TableHeaderColumn dataField='country' csvHeader='Country' width='100' columnTitle>Country</TableHeaderColumn>
                                                    {/* <TableHeaderColumn dataField='post_zip_code' csvHeader='Zip' width='80' columnTitle>Zip Code</TableHeaderColumn> */}
                                                    <TableHeaderColumn dataField='action' width='70' dataFormat={favMenuItem} formatExtraData={this.props} export={false}>Favorite Menu Items</TableHeaderColumn>
                                                    <TableHeaderColumn dataField='action' width='70' dataFormat={ordersHistory} formatExtraData={this.props} export={false}>Orders</TableHeaderColumn>
                                                    <TableHeaderColumn dataField='action' width='140' dataFormat={actionFormatter} formatExtraData={this.props} export={false}>Action</TableHeaderColumn>
                                                </BootstrapTable>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section >
                        <Footer />
                    </div>
                    {/* <Content data={this.props.data} blockunblock={this.props.blockunblock} /> */}
                </div>
            </div>
        );
    }
}
;
const mapStateToProps = (state: any) => {
    return {
        data: state.customer.blockedCustomer,
        orders: state.customer.ordersdata,
        favItems: state.customer.favItemData
    }
}
const mapDispatchToProps = (dispatch: any) => {
    return {
        logoutUser: function () {
            dispatch(logoutUser());
        },
        customersList: function (page: any,brand:any) {
            dispatch(customersList(page,brand));
        },
        blockunblock: function (id: any,phone:any, is_active: any) {
            dispatch(blockunblock(id,phone, is_active))
        },
        orderHistoryByCusId: function (id: any) {
            dispatch(orderHistoryByCusId(id))
        },
        favoritesList: function (id: any) {
            dispatch(favoritesList(id))
        }
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(Customers);