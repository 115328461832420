import {
    MENUS_LIST, ADD_MENU, GET_MENU, EDIT_MENU, GROUPS_LIST, ADD_GROUP, GET_GROUP, EDIT_GROUP,
    ITEMS_LIST, ADD_ITEMS, GET_ITEM, EDIT_ITEM, COMBOS_LIST, ADD_COMBO, COMBO_OPTIONS, GET_COMBO, EDIT_COMBO, MOD_GROUP_LIST,
    ADD_MOD_GROUP, MOD_LIST, ADD_MOD, ING_LIST, ING_CAT_LIST, ING_UOM_LIST, GET_ING, MEALS_LIST, GET_MEAL, COMBO_CHOICE_ITEMS,
    GET_MOD, EDIT_MOD, GET_MOD_GROUP, EDIT_MOD_GROUP, GROUP_OPTIONS, STORES_OPTIONS, CHECK_DUP_POS, CHECK_DUP_ERP, ACTIVE_ITEMS_OPTIONS, STATUS_CHANGE_REASONS, GET_BRANCH_MENU, SEARCH_MENU_ITEM, SEARCH_GROUPS, SEARCH_COMBOS, SEARCH_MODIFIERS, ACTIVE_MENUS_LIST, ACTIVE_GROUPS_LIST, ITEMS_FORACTIVE_MENU,
} from '../actions/menuType'
const initialState: any = {
    menus: [] || "",
    groups: [] || "",
    items: [] || "",
    combos: [] || "",
    modgroups: [] || "",
    modifiers: [] || "",
    combooptions: [] || "",
    groupsptions: [] || "",
    allActiveItems: [] || "",
    allActiveMenus: [] || "",
    allActiveGroups:[]||"",
    allItemsOfActiveMenu:[]||"",
    storesoptions: [] || "",
    ingredients: [] || "",
    ingData:{},
    ingCat: [] || "",
    ingUom: [] || "",
    meals: [] || "",
    mealData: {},
    menuData: {},
    groupData: {},
    itemData: {},
    comboData: {},
    modData: {},
    modGroupData: {},
    sizejson: [
        {
            id: "",
            pos_code: "",
            erp_id: "",
            size: "",
            cost: "",
            mrp: "",
            extra_price: "",
            description: "",
            image_url: null
        }
    ],
    combojson: [
        {
            id: "",
            itemName: "",
            poscode: "",
            choice_group_name: "",
            groupname: "",
            groupposcode: "",
            size: "",
            quantity: "",
            limit: "",
            itemsize: []
        }
    ],
    comboChoices: [
        {
            group_id: "",
            group_erp_id: "",
            group_name: "",
            menu_item_id: "",
            choicesItemOptions: [],
            size: "",
            itemsize: []
        }
    ],
    comboChoiceItems: [] || "",
    choiceIndex: "",
    isInserted: "",
    isUpdated: "",
    message: "",
    duplicateItemPos: false,
    duplicateItemErp: false,
    groupsData: [] || "",
    itemsData: [] || "",
    combosData: [] || "",
    modifiersData: [] || "",
    searchGroupData: [] || "",
    searchItemData: [] || "",
    searchComboData: [] || "",
    searchModData: [] || "",
    statusChangeReasons: [] || ""
}

const menuReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case MENUS_LIST: return {
            ...state,
            menus: action.payload
        }
        case ADD_MENU: return {
            ...state,
            isInserted: action.isInserted,
            message: action.payload
        }
        case GET_MENU: return {
            ...state,
            menuData: action.payload
        }
        case EDIT_MENU: return {
            ...state,
            isUpdated: action.isUpdated,
            message: action.payload
        }
        case GROUPS_LIST: return {
            ...state,
            groups: action.payload
        }
        case GROUP_OPTIONS: return {
            ...state,
            groupsptions: action.payload
        }
        case ACTIVE_ITEMS_OPTIONS: return {
            ...state,
            allActiveItems: action.payload
        }
        case STORES_OPTIONS: return {
            ...state,
            storesoptions: action.payload
        }
        case ADD_GROUP: return {
            ...state,
            isInserted: action.isInserted,
            message: action.payload
        }
        case GET_GROUP: return {
            ...state,
            groupData: action.payload
        }
        case EDIT_GROUP: return {
            ...state,
            isUpdated: action.isUpdated,
            message: action.payload
        }
        case GET_ITEM: return {
            ...state,
            sizejson: action.sizejson,
            itemData: action.payload
        }
        case EDIT_ITEM: return {
            ...state,
            isUpdated: action.isUpdated,
            message: action.payload
        }
        case ITEMS_LIST: return {
            ...state,
            items: action.payload
        }
        case ADD_ITEMS: return {
            ...state,
            isInserted: action.isInserted,
            message: action.payload
        }
        case COMBOS_LIST: return {
            ...state,
            combos: action.payload
        }
        case ADD_COMBO: return {
            ...state,
            isInserted: action.isInserted,
            message: action.payload
        }
        case COMBO_OPTIONS: return {
            ...state,
            combooptions: action.payload
        }
        case GET_COMBO: return {
            ...state,
            combojson: action.combojson,
            comboChoices: action.comboChoices,
            comboData: action.payload
        }
        case EDIT_COMBO: return {
            ...state,
            isUpdated: action.isUpdated,
            message: action.payload
        }
        case MOD_GROUP_LIST: return {
            ...state,
            modgroups: action.payload
        }
        case ADD_MOD_GROUP: return {
            ...state,
            isInserted: action.isInserted,
            message: action.payload
        }
        case GET_MOD_GROUP: return {
            ...state,
            modGroupData: action.payload
        }
        case EDIT_MOD_GROUP: return {
            ...state,
            isUpdated: action.isUpdated,
            message: action.payload
        }
        case MOD_LIST: return {
            ...state,
            modifiers: action.payload
        }
        case ADD_MOD: return {
            ...state,
            isInserted: action.isInserted,
            message: action.payload
        }
        case GET_MOD: return {
            ...state,
            modData: action.payload
        }
        case EDIT_MOD: return {
            ...state,
            isUpdated: action.isUpdated,
            message: action.payload
        }
        case ING_LIST: return {
            ...state,
            ingredients: action.payload
        }
        case GET_ING: return {
            ...state,
            ingData: action.payload
        }
        case ING_CAT_LIST: return {
            ...state,
            ingCat: action.payload
        }
        case ING_UOM_LIST: return {
            ...state,
            ingUom: action.payload
        }
        case MEALS_LIST: return {
            ...state,
            meals: action.payload
        }
        case GET_MEAL: return {
            ...state,
            mealData: action.payload
        }
        case CHECK_DUP_POS: return {
            ...state,
            duplicateItemPos: action.duplicateItemPos
        }
        case CHECK_DUP_ERP: return {
            ...state,
            duplicateItemErp: action.duplicateItemErp
        }
        case COMBO_CHOICE_ITEMS: return {
            ...state,
            choiceIndex: action.choiceIndex,
            comboChoiceItems: action.payload
        }
        case GET_BRANCH_MENU: return {
            ...state,
            groupsData: action.groupsData,
            itemsData: action.itemsData,
            combosData: action.combosData,
            modifiersData: action.modData
        }
        case STATUS_CHANGE_REASONS: return {
            ...state,
            statusChangeReasons: action.payload
        }
        case SEARCH_MENU_ITEM: return {
            ...state,
            searchItemData: action.searchItemData
        }
        case SEARCH_GROUPS: return {
            ...state,
            searchGroupData: action.searchGroupData
        }
        case SEARCH_COMBOS: return {
            ...state,
            searchComboData: action.searchComboData
        }
        case SEARCH_MODIFIERS: return {
            ...state,
            searchModData: action.searchModData
        }
        case ACTIVE_MENUS_LIST:return {
            ...state,
            allActiveMenus:action.payload
        }
        case ACTIVE_GROUPS_LIST:return {
            ...state,
            allActiveGroups:action.payload
        }
        case ITEMS_FORACTIVE_MENU:return{
            ...state,
            allItemsOfActiveMenu:action.payload
        }
        default: return state;
    }
}
export default menuReducer;