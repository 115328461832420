import React, { Component } from 'react';
import { connect } from 'react-redux'
import { editNotification, getNotification, logoutUser } from '../../redux'
import { Link, Redirect } from 'react-router-dom';
import jwt from 'jsonwebtoken';
import { secretKey } from '../../secret';
import Topbar from '../../components/topbar'
import Sidebar from '../../components/sidebar'
import Footer from '../../components/footer/main'
import CheckChanges from '../../components/confirmOnLeave'
import { EditPushProps, EditPushState } from '../../interfaces/notifications';
import { brandsList } from '../../redux/actions/storeAction';
import Select from 'react-select';
class EditNotification extends Component<EditPushProps, EditPushState> {
    constructor(props: any) {
        super(props);
        this.state = {
            title: "",
            description: "",
            expiryDate: "",
            error: "",
            brand_id:null,
            brand_name:''
        }
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
    }
    componentDidMount() {
        let id = this.props.match.params.id;
        this.props.getNotification(id);
        this.props.brandsList()
        document.title = "SimplexCMS | Push Notifications"
    }
    UNSAFE_componentWillReceiveProps(nextProps: any, nextState: any) {
        this.setState({
            title: nextProps.notificationData.title,
            description: nextProps.notificationData.description,
            expiryDate: nextProps.notificationData.expire_date,
        })
        if(nextProps.notificationData.brand_id) {
            this.setState({
                brand_id:nextProps.notificationData.brand_id.brand_id,
                brand_name:nextProps.notificationData.brand_id.brand_name
            })
        }
    }
    handleInputChange(event: { target: { name: any; value: any; }; }) {
        this.setState({
            [event.target.name]: event.target.value
        });
        if (event.target.name == "description" && event.target.value.length > 150) {
            this.setState({
                error: "Description must be 150 characters"
            });
        } else {
            this.setState({
                error: ""
            });
        }
    }
    isNotificationReady = () => {
        const { title, description, expiryDate } = this.state
        return (title !== "" && (description && description.length <= 150) && expiryDate !== "");
    }
    handleSubmit = (event: any) => {
        let { title, description, expiryDate,brand_id } = this.state;
        let id = this.props.match.params.id;
        let data = {
            title: title,
            body: description,
            expire_date: expiryDate,
            brand_id
        }
        this.props.editNotification(id, data)
    }
    handleBrandsInputChange = (e: any, index: any) => {
        this.setState({brand_id:e.value});
    }
    handleBrandChange(event:any) {
        event.preventDefault();
        this.setState({brand_id:event.target.value});
    }
    render() {
        let roleId
        if (sessionStorage.token) {
            jwt.verify(sessionStorage.token, secretKey, (err: any, decoded: any) => {
                if (err) {
                    this.props.logoutUser();
                }else {
                    roleId = decoded.role_id
                }
            });
        } else {
            return <Redirect to="/" />
        }
        const {
            brands
          } = this.props;
          let tokendata: any = jwt.decode(sessionStorage.token)
        const { notificationData } = this.props;
        return (
            <div className="page">
                <CheckChanges path="/edit-promo" />
                <Topbar />
                <div className="page-content d-flex align-items-stretch">
                    <Sidebar />
                    <div className="content-inner">
                        {/* <!-- Page Header--> */}
                        <header className="page-header pb-1">
                            <div className="container-fluid">
                                <div className="d-flex align-items-center justify-content-between">
                                    <h4>Compaigns Management</h4>
                                </div>
                            </div>
                        </header>
                        {/*  Breadcrumb */}
                        <div className="breadcrumb-holder container-fluid">
                            <ul className="breadcrumb">
                                <li className="breadcrumb-item"><Link to="/marketing/3" className="text-primary">Compaigns</Link></li>
                                <li className="breadcrumb-item active">Edit Push Notification</li>
                            </ul>
                        </div>
                        <section className="forms">
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="card">
                                            <div className="card-body">
                                                <div className="row">
                                                    <div className="col">
                                                        <div className="form-group">
                                                            <label className="form-control-label">Title <span className="text-danger">*</span></label>
                                                            <input id="title" type="text" defaultValue={notificationData.title} name="title" required data-msg="Please enter title" className="input-material" onChange={this.handleInputChange} />
                                                        </div>
                                                    </div>
                                                    <div className="col">
                                                        <div className="form-group">
                                                            <label className="form-control-label">
                                                                Brand <span className="text-danger">*</span>
                                                            </label>
                                                            <select
                                                                name="brand"
                                                                className="form-control text-capitalize mt-2"
                                                                required
                                                                disabled={roleId == 7 ? false : true}
                                                                data-msg="Please select brand"
                                                                onChange={(e)=>this.handleBrandChange(e)}
                                                            >
                                                                <option value="">Select Brand</option>
                                                                {brands &&
                                                                    brands.map(
                                                                        (brand:any, index:any) =>
                                                                            brand.is_active == 1 && (
                                                                                <option key={index} value={brand.brand_id} {...this.props.notificationData.brand_id === brand.brand_id && { selected: true }}>
                                                                                    {brand.brand_name}
                                                                                </option>
                                                                            )
                                                                    )}
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col">
                                                        <div className="form-group">
                                                            <label className="form-control-label">Description <span className="text-danger">*</span></label>
                                                            <input id="description" type="text" name="description" defaultValue={notificationData.description} required data-msg="Please enter Description" className="input-material" onChange={this.handleInputChange} />
                                                            <small className="form-text text-danger">{this.state.error}</small>
                                                        </div>
                                                    </div>
                                                    <div className="col">
                                                        <div className="form-group">
                                                            <label className="form-control-label">Valid till <span className="text-danger">*</span></label>
                                                            <input id="expiryDate" type="date" name="expiryDate" defaultValue={notificationData.expire_date} required data-msg="Please enter Expire Date" className="input-material" onChange={this.handleInputChange} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="form-group float-right">
                                                    <button onClick={this.handleSubmit} disabled={!this.isNotificationReady()} className="btn btn-primary">Update Notification</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <Footer />
                    </div>
                </div>
            </div>
        );
    }
}
const mapStateToProps = (state: any) => {
    return {
        brands: state.store.brands,
        notificationData: state.notification.notificationData
    }
}
const mapDispatchToProps = (dispatch: any) => {
    return {
        logoutUser: function () {
            dispatch(logoutUser());
        },
        editNotification: function (id: any, data: any) {
            dispatch(editNotification(id, data));
        },
        getNotification: function (id: number) {
            dispatch(getNotification(id));
        },
        brandsList: function () {
            dispatch(brandsList())
        },
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(EditNotification);