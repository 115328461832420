import React, { Component } from 'react';
import { withGoogleMap, GoogleMap, withScriptjs, InfoWindow, Marker, Circle, KmlLayer } from "react-google-maps";
import Geocode from "react-geocode";
import Autocomplete from 'react-google-autocomplete';
import { GoogleMapsAPI } from '../../client-config';
// import * as geolib from 'geolib';
Geocode.setApiKey(GoogleMapsAPI);
Geocode.enableDebug();
interface Props {
    onSetData: any, center?: any, onSetRadius: any, google: any, zoom: any, height: any, data: any, radius: any, kml?: any
}
interface State { [x: number]: any, kml: any, radius: any, address: any, city: any, area: any, state: any, country: any, country_code: any, lat: any, lng: any }
class Map extends Component<Props, State>{
    map: any;
    constructor(props: any) {
        super(props);
        this.state = {
            address: '',
            city: '',
            area: '',
            state: '',
            country: '',
            country_code: '',
            lat: 0,
            lng: 0,
            radius: 0,
            kml: "",
        }
        this.generateRandom = this.generateRandom.bind(this)
    }
    /**
     * Get the current address from the default map position and set those values in the state
     */
    componentDidMount() {
        if (this.props.data === "") {
            if ("geolocation" in navigator) {
                navigator.geolocation.getCurrentPosition((position: any) => {
                    this.setState({ lat: position.coords.latitude, lng: position.coords.longitude })
                    Geocode.fromLatLng(position.coords.latitude, position.coords.longitude).then(
                        response => {
                            const address = response.results[0].formatted_address,
                                addressArray = response.results[0].address_components,
                                city = this.getCity(addressArray),
                                area = this.getArea(addressArray),
                                state = this.getState(addressArray),
                                country_code = this.getCountryCode(addressArray),
                                country = this.getCountry(addressArray);

                            this.setState({
                                address: (address) ? address : '',
                                area: (area) ? area : '',
                                city: (city) ? city : '',
                                state: (state) ? state : '',
                                country: (country) ? country : '',
                                country_code: (country_code) ? country_code : ''
                            })
                            this.props.onSetData(this.state);
                        },
                        error => {
                            console.error(error);
                        }
                    );
                })
            }
        }
    };
    UNSAFE_componentWillReceiveProps(nextProps: any) {
        if (nextProps.data !== "") {
            if (nextProps.center.lat == 0 && nextProps.center.lng == 0) {
                if (this.state.lat !== nextProps.data.lat) {
                    this.setState({ lat: nextProps.data.lat, lng: nextProps.data.lng })
                    Geocode.fromLatLng(nextProps.data.lat, nextProps.data.lng).then(
                        response => {
                            const address = response.results[0].formatted_address,
                                addressArray = response.results[0].address_components,
                                city = this.getCity(addressArray),
                                area = this.getArea(addressArray),
                                state = this.getState(addressArray),
                                country_code = this.getCountryCode(addressArray),
                                country = this.getCountry(addressArray);

                            this.setState({
                                address: (address) ? address : '',
                                area: (area) ? area : '',
                                city: (city) ? city : '',
                                state: (state) ? state : '',
                                country: (country) ? country : '',
                                country_code: (country_code) ? country_code : ''
                            })
                            this.props.onSetData(this.state);
                        },
                        error => {
                            console.error(error);
                        }
                    );
                }
            } else {
                // console.log(this.props.center.lat,nextProps.center.lat," ",this.props.center.lng,nextProps.center.lng)
                if (this.props.center.lat !== nextProps.center.lat || this.props.center.lng !== nextProps.center.lng) {
                    // console.log(this.props.center, nextProps.center)
                    this.setState({ lat: parseFloat(nextProps.center.lat), lng: parseFloat(nextProps.center.lng) });
                    Geocode.fromLatLng(nextProps.center.lat, nextProps.center.lng).then(
                        response => {
                            const address = response.results[0].formatted_address,
                                addressArray = response.results[0].address_components,
                                city = this.getCity(addressArray),
                                area = this.getArea(addressArray),
                                state = this.getState(addressArray),
                                country_code = this.getCountryCode(addressArray),
                                country = this.getCountry(addressArray);

                            this.setState({
                                address: (address) ? address : '',
                                area: (area) ? area : '',
                                city: (city) ? city : '',
                                state: (state) ? state : '',
                                country: (country) ? country : '',
                                country_code: (country_code) ? country_code : ''
                            })
                            this.props.onSetData(this.state);
                        },
                        error => {
                            console.error(error);
                        }
                    );
                    this.setState({ radius: nextProps.radius, kml: nextProps.kml })
                }
            }
            this.setState({ radius: nextProps.radius, kml: nextProps.kml })
        } else {
            // console.log(this.props.center.lat,nextProps.center.lat," ",this.props.center.lng,nextProps.center.lng)
            if (this.props.center.lat !== nextProps.center.lat || this.props.center.lng !== nextProps.center.lng) {
                // console.log(this.props.center, nextProps.center)
                this.setState({ lat: parseFloat(nextProps.center.lat), lng: parseFloat(nextProps.center.lng) });
                Geocode.fromLatLng(nextProps.center.lat, nextProps.center.lng).then(
                    response => {
                        const address = response.results[0].formatted_address,
                            addressArray = response.results[0].address_components,
                            city = this.getCity(addressArray),
                            area = this.getArea(addressArray),
                            state = this.getState(addressArray),
                            country_code = this.getCountryCode(addressArray),
                            country = this.getCountry(addressArray);

                        this.setState({
                            address: (address) ? address : '',
                            area: (area) ? area : '',
                            city: (city) ? city : '',
                            state: (state) ? state : '',
                            country: (country) ? country : '',
                            country_code: (country_code) ? country_code : ''
                        })
                        this.props.onSetData(this.state);
                    },
                    error => {
                        console.error(error);
                    }
                );
            }
            this.setState({ radius: nextProps.radius, kml: nextProps.kml })
        }
    }
    /**
     * Component should only update ( meaning re-render ), when the user selects the address, or drags the pin
     *
     * @param nextProps
     * @param nextState
     * @return {boolean}
     */
    shouldComponentUpdate(nextProps: any, nextState: any): boolean | any {
        if (
            this.state.lat !== nextState.lat ||
            // this.props.center.lat !== nextProps.center.lat ||
            // this.props.center.lng !== nextProps.center.lng ||
            this.state.address !== nextState.address ||
            this.state.city !== nextState.city ||
            this.state.area !== nextState.area ||
            this.state.state !== nextState.state ||
            this.state.radius !== nextState.radius ||
            this.state.kml !== nextState.kml
        ) {
            return true
        } else {
            return false
        }
    }
    /**
     * Get the city and set the city input value to the one selected
     *
     * @param addressArray
     * @return {string}
     */
    getCity = (addressArray: any) => {
        let city = '';
        if (addressArray) {
            for (let i = 0; i < addressArray.length; i++) {
                if (addressArray[i].types[0] && 'administrative_area_level_2' === addressArray[i].types[0]) {
                    city = addressArray[i].long_name;
                    return city;
                }
            }
        }
    };
    /**
     * Get the city and set the city input value to the one selected
     *
     * @param addressArray
     * @return {string}
     */
    getCountry = (addressArray: any) => {
        let country = '';
        if (addressArray) {
            for (let i = 0; i < addressArray.length; i++) {
                if (addressArray[i].types[0] && 'country' === addressArray[i].types[0]) {
                    country = addressArray[i].long_name;
                    return country;
                }
            }
        }
    };
    /**
    * Get the city and set the city input value to the one selected
    *
    * @param addressArray
    * @return {string}
    */
    getCountryCode = (addressArray: any) => {
        let country_code = '';
        if (addressArray) {
            for (let i = 0; i < addressArray.length; i++) {
                if (addressArray[i].types[0] && 'country' === addressArray[i].types[0]) {
                    country_code = addressArray[i].short_name;
                    return country_code;
                }
            }
        }
    };
    /**
     * Get the area and set the area input value to the one selected
     *
     * @param addressArray
     * @return {string}
     */
    getArea = (addressArray: any) => {
        let area = '';
        if (addressArray) {
            for (let i = 0; i < addressArray.length; i++) {
                if (addressArray[i].types[0]) {
                    for (let j = 0; j < addressArray[i].types.length; j++) {
                        if ('sublocality_level_1' === addressArray[i].types[j] || 'locality' === addressArray[i].types[j]) {
                            area = addressArray[i].long_name;
                            return area;
                        }
                    }
                }
            }
        }
    };
    /**
     * Get the address and set the address input value to the one selected
     *
     * @param addressArray
     * @return {string}
     */
    getState = (addressArray: any) => {
        let state = '';
        if (addressArray) {
            for (let i = 0; i < addressArray.length; i++) {
                for (let i = 0; i < addressArray.length; i++) {
                    if (addressArray[i].types[0] && 'administrative_area_level_1' === addressArray[i].types[0]) {
                        state = addressArray[i].long_name;
                        return state;
                    }
                }
            }
        }
    };
    /**
     * This Event triggers when the marker window is closed
     *
     * @param event
     */
    onInfoWindowClose = (event: any) => {

    };

    /**
     * When the marker is dragged you get the lat and long using the functions available from event object.
     * Use geocode to get the address, city, area and state from the lat and lng positions.
     * And then set those values in the state.
     *
     * @param event
     */
    onMarkerDragEnd = (event: any) => {
        let newLat = event.latLng.lat(),
            newLng = event.latLng.lng();

        Geocode.fromLatLng(newLat, newLng).then(
            response => {
                const address = response.results[0].formatted_address,
                    addressArray = response.results[0].address_components,
                    city = this.getCity(addressArray),
                    area = this.getArea(addressArray),
                    state = this.getState(addressArray),
                    country_code = this.getCountryCode(addressArray),
                    country = this.getCountry(addressArray);
                this.setState({
                    address: (address) ? address : '',
                    area: (area) ? area : '',
                    city: (city) ? city : '',
                    state: (state) ? state : '',
                    country: (country) ? country : '',
                    country_code: (country_code) ? country_code : '',
                    lat: newLat,
                    lng: newLng
                })
                this.props.onSetData(this.state);
            },
            error => {
                console.error(error);
            }
        );
    };

    /**
     * When the user types an address in the search box
     * @param place
     */
    onPlaceSelected = (place: any) => {
        if (place.formatted_address) {
            const address = place.formatted_address,
                addressArray = place.address_components,
                city = this.getCity(addressArray),
                area = this.getArea(addressArray),
                state = this.getState(addressArray),
                country = this.getCountry(addressArray),
                country_code = this.getCountryCode(addressArray),
                latValue = place.geometry.location.lat(),
                lngValue = place.geometry.location.lng();
            // let response: any = geolib.isPointInPolygon({ latitude: latValue, longitude: lngValue }, this.state.tradeZone);
            // alert(response)
            // Set these values in the state.
            this.setState({
                address: (address) ? address : '',
                area: (area) ? area : '',
                city: (city) ? city : '',
                state: (state) ? state : '',
                country: (country) ? country : '',
                country_code: (country_code) ? country_code : '',
                lat: latValue,
                lng: lngValue
            })
            this.props.onSetData(this.state);
        }
    };
    mapMounted = (ref: any) => {
        this.map = ref;
    };
    getValues = (e: any) => {
        const radius = this.map.getRadius();
        // const center = this.map.getCenter();  center.lat(),center.lng(),
        this.setState({ radius: radius });
        this.props.onSetRadius(this.state.radius)
    };
    generateRandom() {
        return Math.random() * 10000000000000000
    }
    render() {
        const AsyncMap: any = withScriptjs(
            withGoogleMap(
                (props: any) => (
                    <GoogleMap
                        google={this.props.google}
                        defaultZoom={this.props.zoom}
                        defaultCenter={{ lat: this.state.lat, lng: this.state.lng }}
                        mapTypeId={"terrain"}
                    >
                        {/* InfoWindow on top of marker */}
                        <InfoWindow
                            onClose={this.onInfoWindowClose}
                            position={{ lat: (this.state.lat + 0.0018), lng: this.state.lng }}
                        >
                            <div>
                                <span style={{ padding: 0, margin: 0 }}>{this.state.address}</span>
                            </div>
                        </InfoWindow>
                        {/*Marker*/}
                        <Marker
                            google={this.props.google}
                            name={'Dolores park'}
                            draggable={true}
                            onDragEnd={this.onMarkerDragEnd}
                            position={{ lat: this.state.lat, lng: this.state.lng }}
                        />
                        {/* <Marker /> */}
                        {/* For Auto complete Search Box */}
                        <Autocomplete
                            style={{
                                width: '100%',
                                height: '40px',
                                paddingLeft: '16px',
                                marginTop: '2px'
                            }}
                            onPlaceSelected={this.onPlaceSelected}
                            types={['address']}   //types={['(regions)']}
                            componentRestrictions={{ country: [ "ae" ] }}
                        />
                        <KmlLayer
                            url={this.props.kml + "&ver=" + this.generateRandom()}
                            options={{ preserveViewport: true }}
                        />
                        {/* <Circle center={{ lat: this.state.lat, lng: this.state.lng }}
                            ref={this.mapMounted.bind(this)}
                            options={{
                                strokeColor: '#FF0000',
                                strokeOpacity: 0.8,
                                strokeWeight: 0,
                                fillColor: '#FF0000',
                                fillOpacity: 0.35,
                                editable: true,
                                visible: true,
                                radius: this.state.radius,
                                zIndex: 1
                            }}
                            onRadiusChanged={props.getVal} /> */}
                    </GoogleMap>
                )
            )
        );
        let map;
        if (this.state.lat !== undefined) {
            map = <div>
                <div className="row">
                    <div className="col">
                        <div className="form-group">
                            <label htmlFor="">Address</label>
                            <input type="text" name="address" className="form-control" readOnly value={this.state.address} />
                        </div>
                    </div>
                    {/* <div className="col">
                        <div className="form-group">
                            <label htmlFor="">State</label>
                            <input type="text" name="state" className="form-control" readOnly value={this.state.state} />
                        </div>
                    </div> */}
                    {/* <div className="col">
                        <div className="form-group">
                            <label htmlFor="">Area</label>
                            <input type="text" name="area" className="form-control" readOnly value={this.state.area} />
                        </div>
                    </div> */}
                </div>
                <div className="row">
                    <div className="col">
                        <div className="form-group">
                            <label htmlFor="">City</label>
                            <input type="text" name="city" className="form-control" readOnly value={this.state.city} />
                        </div>
                    </div>
                    <div className="col">
                        <div className="form-group">
                            <label htmlFor="">Country</label>
                            <input type="text" name="state" className="form-control" readOnly value={this.state.country} />
                        </div>
                    </div>
                </div>
                <AsyncMap
                    googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${GoogleMapsAPI}&libraries=geometry,drawing,places`}
                    loadingElement={
                        <div style={{ height: '350px', width: `100%` }} />
                    }
                    containerElement={
                        <div style={{ height: this.props.height }} />
                    }
                    mapElement={
                        <div style={{ height: '350px', width: `100%` }} />
                    }
                    getVal={this.getValues.bind(this)}
                />
            </div >
        } else {
            map = <div style={{ height: this.props.height }} />
        }
        return (map)
    }
}
export default Map
