import React, { Component } from 'react';
import { connect } from 'react-redux'
import { handleBrandInput, addBrand, logoutUser } from '../../redux'
import { AddBrandProps } from '../../interfaces/brands';
import { Link, Redirect } from 'react-router-dom'
import jwt from 'jsonwebtoken'
import { secretKey } from '../../secret'
import Topbar from '../../components/topbar'
import Sidebar from '../../components/sidebar'
import Footer from '../../components/footer/main'
import CheckChanges from '../../components/confirmOnLeave'
class AddMenu extends Component<AddBrandProps, {image:any}> {
    constructor(props: any) {
        super(props);
        this.state = {
            image:''
        } 
        this.handleSaveBtnClick = this.handleSaveBtnClick.bind(this);
    }
    componentDidMount() {
        document.title = "SimplexCMS | Brands"
    }
    isBrandReady = () => {
        const { brandname } = this.props;
        return (brandname !== "" && this.state.image != '');
    }
    blockInvalidChar = (e: any) => {
        var regex = new RegExp("^[a-zA-Z0-9]");
        let specialkey: any = e.keyCode;
        var key: any = String.fromCharCode(!e.charCode ? e.which : e.charCode);
        if (specialkey == 8 || specialkey == 9) {
            return true;
        } else {
            if (/\S/.test(e.target.value)) {
                // string is not empty and not just whitespace
                if (!regex.test(key)) {
                    if (specialkey == 32) {
                    } else {
                        e.preventDefault()
                    }
                }
            } else {
                ((!regex.test(key) || specialkey == 32)) && e.preventDefault()
            }
        }
    }
    handleSaveBtnClick = () => {
        let { brandname, branddetails, brandtype,website_link} = this.props;
        const data: any = new FormData();
        data.append("brand_name",brandname)
        data.append("brand_details",branddetails)
        data.append("brand_type",brandtype)
        data.append("file",this.state.image[0])
        data.append("website_link", website_link)
        this.props.addBrand(data);
    }
    fileSelectedHandler = (e: any) => {
        var size = 1048576;
        if (e.target.files[0].size > size) {
            alert("Image size too large. Upload image upto 1mb")
        }
        else {
            this.setState({ image: e.target.files })
        }
    }
    render() {
        if (sessionStorage.token) {
            jwt.verify(sessionStorage.token, secretKey, (err: any, decoded: any) => {
                if (err) {
                    this.props.logoutUser();
                }
            });
        } else {
            return <Redirect to="/" />
        }
        let msg;
        let messcolor;
        const { isInserted, message } = this.props;
        if (!isInserted) {
            msg = message;
            messcolor = 'red'
        }
        return (
            <div className="page">
                <CheckChanges path="/add-brand" />
                <Topbar />
                <div className="page-content d-flex align-items-stretch">
                    <Sidebar />
                    <div className="content-inner">
                        {/*  Page Header */}
                        <header className="page-header py-0">
                            <div className="container-fluid">
                                <div className="d-flex justify-content-between py-3">
                                    <h4 className="mt-2">Brands Management</h4>
                                </div>
                            </div>
                        </header>
                        {/* Breadcrumb */}
                        <div className="breadcrumb-holder container-fluid">
                            <ul className="breadcrumb">
                                <li className="breadcrumb-item"><Link to="/brands" className="text-primary">Brands</Link></li>
                                <li className="breadcrumb-item active">Add Brand</li>
                            </ul>
                        </div>
                        <section className="forms">
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="card">
                                            <div className="card-body">
                                                <strong><p className="text-center font-weight-light mb-4" style={{ color: messcolor }}>
                                                    {msg}
                                                </p></strong>
                                                <div className="row">
                                                    <div className="col">
                                                        <div className="form-group">
                                                            <label className="form-control-label">Brand name <span className="text-danger">*</span></label>
                                                            <input id="brandname" type="text" name="brandname" onKeyDown={this.blockInvalidChar} required data-msg="Please enter Brand Name" className="input-material" onChange={this.props.handleBrandInput} />
                                                        </div>
                                                    </div>
                                                    <div className="col">
                                                        <div className="form-group">
                                                            <label className="form-control-label">Brand type</label>
                                                            <input id="brandtype" type="text" name="brandtype" onKeyDown={this.blockInvalidChar} className="input-material" onChange={this.props.handleBrandInput} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col">
                                                        <div className="form-group">
                                                            <label className="form-control-label">Brand details</label>
                                                            <input id="branddetails" type="text" name="branddetails" className="input-material" onChange={this.props.handleBrandInput} />
                                                        </div>
                                                    </div>
                                                    <div className="col">
                                                          <div className="form-group">
                                                            <label className="form-control-label mb-3">Brand Image <span className="text-danger">*</span></label>
                                                            <input id="files" type="file" name="files" accept="image/*" className="form-control-file" onChange={(e) => this.fileSelectedHandler(e)} />
                                                        </div>
                                                    </div>
                                                    <div className="col">
                                                        <div className="form-group">
                                                            <label className="form-control-label">Website Link</label>
                                                            <input id="website_link" type="text" name="website_link" className="input-material" onChange={this.props.handleBrandInput} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="form-group d-flex justify-content-end mt-4">
                                                    <button className='btn btn-primary' disabled={!this.isBrandReady()} onClick={this.handleSaveBtnClick} >Save</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <Footer />
                    </div>
                </div>
            </div >
        );
    }
}
const mapStateToProps = (state: any) => {
    return {
        brandname: state.brand.brandname,
        branddetails: state.brand.branddetails,
        brandtype: state.brand.brandtype,
        website_link: state.brand.website_link,
        message: state.brand.message,
        isInserted: state.brand.isInserted
    }
}
const mapDispatchToProps = (dispatch: any) => {
    return {
        handleBrandInput: (event: any) => { dispatch(handleBrandInput(event)) },
        logoutUser: () => { dispatch(logoutUser()); },
        addBrand: (data: any) => { dispatch(addBrand(data)); }
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(AddMenu);