import React, { Component } from 'react';
import { connect } from 'react-redux'
import { AddTradeZoneProps } from '../../interfaces/tradeZone';
import { Link, Redirect } from 'react-router-dom'
import jwt from 'jsonwebtoken'
import { secretKey } from '../../secret'
import Topbar from '../../components/topbar'
import Sidebar from '../../components/sidebar'
import Footer from '../../components/footer/main'
import CheckChanges from '../../components/confirmOnLeave'
import { addTradeZone, citiesList, handleZoneInput, logoutUser, storesList } from '../../redux';
class AddTradeZone extends Component<AddTradeZoneProps, {}> {
    constructor(props: any) {
        super(props);
        this.handleSaveBtnClick = this.handleSaveBtnClick.bind(this);
    }
    componentDidMount() {
        document.title = "SimplexYum | TradeZones";
        this.props.citiesList();
    }
    isZoneReady = () => {
        const { tradezonename, city } = this.props;
        return (tradezonename !== "" && city !== "");
    }
    handleSaveBtnClick = () => {
        let { tradezonename, city } = this.props;
        let data = {
            delivery_zone_name: tradezonename,
            city_id: city,
            is_active: 1
        }
        this.props.addTradeZone(data);
    }
    render() {
        if (sessionStorage.token) {
            jwt.verify(sessionStorage.token, secretKey, (err: any, decoded: any) => {
                if (err) {
                    this.props.logoutUser();
                }
            });
        } else {
            return <Redirect to="/" />
        }
        let { cities } = this.props;
        return (
            <div className="page">
                <CheckChanges path="/add-brand" />
                <Topbar />
                <div className="page-content d-flex align-items-stretch">
                    <Sidebar />
                    <div className="content-inner">
                        {/*  Page Header */}
                        <header className="page-header py-0">
                            <div className="container-fluid">
                                <div className="d-flex justify-content-between py-3">
                                    <h4 className="mt-2">Trade Zones Management</h4>
                                </div>
                            </div>
                        </header>
                        {/* Breadcrumb */}
                        <div className="breadcrumb-holder container-fluid">
                            <ul className="breadcrumb">
                                <li className="breadcrumb-item"><Link to="/tradezone" className="text-primary">Trade Zone</Link></li>
                                <li className="breadcrumb-item active">Add Trade Zones</li>
                            </ul>
                        </div>
                        <section className="forms">
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="card">
                                            <div className="card-body">
                                                <div className="row">
                                                    <div className="col">
                                                        <div className="form-group">
                                                            <label className="form-control-label">Trade Zone name <span className="text-danger">*</span></label>
                                                            <input id="tradezonename" type="text" name="tradezonename" required data-msg="Please enter TradeZoneName" className="input-material" onChange={this.props.handleZoneInput} />
                                                        </div>
                                                    </div>
                                                    <div className="col">
                                                        <div className="form-group">
                                                            <label className="form-control-label">City</label>
                                                            <select name="city" className="form-control mt-2" required data-msg="Please Select City" onChange={this.props.handleZoneInput}>
                                                                <option value="">Select City</option>
                                                                {cities &&
                                                                    cities.map((city, index) => (
                                                                        <option key={index} value={city.id}>{city.name}</option>
                                                                    ))
                                                                }
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="form-group d-flex justify-content-end mt-4">
                                                    <button className='btn btn-primary' disabled={!this.isZoneReady()} onClick={this.handleSaveBtnClick} >Save</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <Footer />
                    </div>
                </div>
            </div >
        );
    }
}
const mapStateToProps = (state: any) => {
    return {
        tradezonename: state.tradezone.tradezonename,
        city: state.tradezone.city,
        cities: state.store.cities
    }
}
const mapDispatchToProps = (dispatch: any) => {
    return {
        handleZoneInput: (event: any) => { dispatch(handleZoneInput(event)) },
        logoutUser: () => { dispatch(logoutUser()); },
        citiesList: () => { dispatch(citiesList()) },
        addTradeZone: (data: any) => { dispatch(addTradeZone(data)); }
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(AddTradeZone);