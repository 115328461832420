import React from "react"
import { Prompt } from "react-router-dom"

const prompt: any = (props: any) => {
    return (
        <Prompt
            message={(location, action) => {
                if (action === 'POP') {
                    console.log("Backing up...")
                    return true
                } else {
                    return location.pathname.startsWith(props.path)
                        ? true
                        : `You have unsaved changes; are you sure you want to leave this page?`
                }

            }} />
    )
}
export default prompt;